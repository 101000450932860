import _ from 'lodash';
import User from './auth';

export const userEmail = () => User.getData().then((u) => (u ? u.email : ''));

export const checkMaterialUnit = (currentUnit, selectedUnit) =>
  currentUnit.toLowerCase() === selectedUnit.toLowerCase();

export const padArray = (e) => (_.isArray(e) ? e : [...e]);
export const transformRawMaterial = (rawMaterial) => {
  const { name, units, temp, E, Tc, T0, C0, F, rc, crystal, owner } = rawMaterial;

  const data = {
    name,
    owner,
    units,
    refTemp: padArray(temp),
    youngsModulus: padArray(E),
    criticalTearingEnergy: padArray(Tc),
    intrinsicStrength: padArray(T0),
    precursorSize: padArray(C0),
    fcgRateLawSlope: padArray(F),
    fcgRateLawConstant: padArray(rc),
    crystallizationStrength: padArray(crystal),
  };

  return data;
};

/**
 * convert number array property values to string array
 * @param {Object} material
 */
export function numberArrayToStringArray(material) {
  return Object.entries(material).reduce(
    (acc, [key, val]) => ({
      ...acc,
      [key]: Array.isArray(val) ? val.map((e) => `${e}`) : val,
    }),
    {}
  );
}

/**
 * convert string array property values to number array
 * @param {Object} material
 */
export function stringArrayToNumberArray(material) {
  return Object.entries(material).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]: Array.isArray(value) ? value.map((e) => Number(e)) : value,
    }),
    {}
  );
}

/**
 * convert property string to number that is included in
 * keys list
 * @param {Object} data
 * @param {String[]} keys
 */
export function stringToNumber(data, keys) {
  return Object.entries(data).reduce((acc, [key, value]) => {
    if (keys.includes(key)) {
      return {
        ...acc,
        [key]: Number(value),
      };
    }
    return acc;
  }, data);
}
