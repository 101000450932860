import React from "react";
import { Button } from "reactstrap";
import { Flex, Box } from "rebass";
import nextImg from "assets/svg/next.svg";
import prevImg from "assets/svg/prev.svg";
import { MessageModal } from "./Modals";
import NextLineText from "../controls/NextLineText";
import User from "../../utils/auth";
import PasteClipboard from "../controls/PasteClipboard";

export const PreviewModalElements = () => {
  const [visible, setVisible] = React.useState(false);
  const [displayIndex, setDisplayIndex] = React.useState(0);

  return {
    onToggle,
    visible,
    onPrev,
    onNext,
    displayIndex,
  };

  function onToggle(e) {
    if (e) e.stopPropagation();
    setVisible((prev) => !prev);
  }

  function onPrev() {
    setDisplayIndex((prev) => prev - 1);
  }

  function onNext() {
    setDisplayIndex((prev) => prev + 1);
  }
};

export const PreviewModal = (props) => {
  const { onToggle, visible, displayIndex, onPrev, onNext } =
    PreviewModalElements();
  const { permission, display, title, label } = props;

  if (User.checkPermission(permission) && display.length) {
    return (
      <div onClick={(e) => e.stopPropagation()}>
        <Button className="btn-info" onClick={onToggle}>
          {label}
        </Button>
        <MessageModal
          title={title}
          color="primary"
          modal={visible}
          setModal={onToggle}
          buttons={[]}
          actions={[]}
        >
          <Flex justifyContent="flex-end">
            <PasteClipboard text={display[displayIndex]} />
          </Flex>
          <Flex>
            <Box onClick={onPrev} p={3} width={1 / 12}>
              {display.length > 1 && displayIndex > 0 && (
                <img className="m-0" src={prevImg} alt="Next graph set" />
              )}
            </Box>
            <Box width={10 / 12}>
              <NextLineText text={display[displayIndex]} />
            </Box>
            <Box onClick={onNext} p={3} width={1 / 12}>
              {displayIndex < display.length - 1 && (
                <img className="m-0" src={nextImg} alt="Next graph set" />
              )}
            </Box>
          </Flex>
        </MessageModal>
      </div>
    );
  }
  return null;
};

export default PreviewModal;
