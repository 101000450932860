import React, { useContext, useState, useEffect } from 'react';
import { Link, navigate } from 'gatsby';
import { globalHistory } from '@reach/router';
import omit from 'lodash/omit';
import { Flex } from 'rebass';
import { MessageModal } from './Modals';
import NextLineText from '../controls/NextLineText';
import { GlobalStateContext } from '../../context/GlobalContextProvider';
import { canSimulate, calculateResult, generateHfi } from '../../utils/simulate';
import LoadingSpinner from '../LoadingSpinner';
import User from '../../utils/auth';
import { storeLoadCase, storeMaterials, storeOptions } from '../../utils/local-storage';
import PasteClipboard from '../controls/PasteClipboard';
import LoggerUtils from 'utils/logger';

const SIMFAIL_TITLE = 'Simulation not successful';
const SIMFAIL_MSG = 'Please double check the values you have submitted and retry.';
const VERIFY_TITLE = 'Simulation could not be submitted';
const VERIFY_MSG = 'A problem occurred while preparing the simulation..';
const RUN_TITLE = 'Simulation In Process...';
const RUN_MSG = <LoadingSpinner />;
const WARN_TITLE = 'Simulation Warnings...';
const WARN_MSG = 'Your simulation is ready to run, however there were some warnings preparing for it.';
const WARN_MSGPER = ' will be excluded:';
const WARN_BTN = 'Run Simulation';
const ERR_TITLE = 'Incomplete Information';
const ERR_MSG = 'Not enough information was provided to perform the simulation.';
const ERR_MSGPER = '';
const STYLE_DANGER = 'danger';
const STYLE_WARN = 'warning';
const STYLE_PRIMARY = 'primary';

export const stdMessage = (notice, msgPer = '', items = []) => (
  <div data-test="std-message-component">
    {notice}
    {!items.length ? (
      ''
    ) : (
      <div data-test="std-message-component-messages">
        <div className="shadow p-2 small text-left">
          {items.map(({ name, type, errors, warnings }, idx) =>
            !warnings.length && !errors.length ? (
              ''
            ) : (
              <div data-test="std-message-component-messages-item" key={idx}>
                <i>{type}</i> <b>{name ? ` - ${name}` : ''}</b> {errors.length ? msgPer : ''}
                {!errors.length ? (
                  ''
                ) : (
                  <>
                    <h3>Errors</h3>
                    <ul className="pl-3">
                      {errors.map((m, i) => (
                        <li style={{ marginLeft: '8px' }} key={i}>
                          {m}
                        </li>
                      ))}
                    </ul>
                  </>
                )}
                {!warnings.length ? (
                  ''
                ) : (
                  <>
                    <h3>Warnings - values of the following field(s) will be excluded</h3>
                    <ul className="pl-3">
                      {warnings.map((m, i) => (
                        <li style={{ marginLeft: '8px' }} key={i}>
                          {m}
                        </li>
                      ))}
                    </ul>
                  </>
                )}
                <br />
              </div>
            )
          )}
        </div>
      </div>
    )}
    <div>
      <small>
        If the problem persists, please contact{' '}
        <Link className="text-primary" to="/app/support">
          Support
        </Link>
      </small>
    </div>
  </div>
);

export const SimulationModalElements = ({
  visible,
  setVisible = () => {},
  defaultMsg = {},
  defaultDebug = false,
  defaultDone = false,
  authChecker = User.isLoggedIn,
  onSuccessPathname = '/app/results/materials',
}) => {
  const { loadingsData, selectedUnit, options, setSimResults, setPreviews, materialsData } =
    useContext(GlobalStateContext);

  const [msg, setMsg] = useState(defaultMsg);
  const [debug, setDebug] = useState(defaultDebug);
  const [done, setDone] = useState(defaultDone);
  const toggleDebug = () => setDebug(!debug);
  const toggle = () => setVisible(!visible);

  const showWarnings = async (check, simData, defaultStdMessage = null) => {
    defaultStdMessage ||= stdMessage;

    setMsg({
      class: STYLE_WARN,
      title: WARN_TITLE,
      message: defaultStdMessage(WARN_MSG, WARN_MSGPER, [...check.materials, ...check.loadcases]),
      buttons: [WARN_BTN],
      actions: [() => doRun(simData)],
    });
  };

  const showErrors = async (check, defaultStdMessage = null) => {
    defaultStdMessage ||= stdMessage;

    setMsg({
      class: STYLE_WARN,
      title: ERR_TITLE,
      message: defaultStdMessage(ERR_MSG, ERR_MSGPER, [...check.materials, ...check.loadcases]),
    });
  };

  const getHfi = async (materials, loadings) => {
    try {
      const { hfi } = await generateHfi(selectedUnit, materials, loadings, options);
      setMsg({
        class: STYLE_PRIMARY,
        title: 'Generated HFi',
        message: (
          <div>
            <Flex justifyContent="flex-end">
              <PasteClipboard text={hfi} />
            </Flex>
            <NextLineText text={hfi} />
          </div>
        ),
        devMsg: null,
      });
    } catch (err) {
      console.error(err);
    }
  };

  const doRun = async (simData = null, defaultStdMessage = null) => {
    defaultStdMessage ||= stdMessage;
    const simulationOptions =
      User.getRole() === 'ADMIN' ? options : omit(options, ['showHfi', 'showHfo', 'showHfm', 'onlyHfi']);

    setMsg({
      class: STYLE_PRIMARY,
      title: RUN_TITLE,
      message: RUN_MSG,
      buttons: [],
    });
    try {
      if (!done && simData) {
        const { materials, loadings } = simData;

        storeMaterials(materialsData);
        storeOptions(options);
        storeLoadCase(loadingsData);
        // store laod case

        if (simulationOptions.onlyHfi) {
          getHfi(materials, loadings);
          return;
        }

        const { graphs, previews } = await calculateResult(selectedUnit, materials, loadings, simulationOptions);

        setDone(true);
        setSimResults(graphs);
        setPreviews(previews);
        setVisible(false);

        const specsPage = '/app/specs';
        const isOnSpecsPage = new RegExp(specsPage).test(globalHistory.location.pathname);
        if (isOnSpecsPage && graphs) {
          navigate(loadings.length ? '/app/results/life' : onSuccessPathname);
        }
      }
    } catch (e) {
      LoggerUtils.warn(SIMFAIL_TITLE, e);
      setMsg({
        class: STYLE_DANGER,
        title: SIMFAIL_TITLE,
        message: defaultStdMessage(SIMFAIL_MSG),
        devMsg: String(e),
      });
    }
  };

  // Use first material/loadcase only if without permission.
  const checkCollectionPermission = (collection, permission) => {
    collection = collection.filter(Boolean);
    if (!User.checkPermission(permission) && collection.length) {
      collection = [collection[0]];
    }

    return collection;
  };

  // Return collection with no errors
  const cleanCollection = (collection) => collection.filter((item) => !item.errors.length).map((item) => item.data);

  const doVerify = async (defaultDoRun = null, defaultShowWarnings = null, defaultShowErrors = null) => {
    defaultDoRun ||= doRun;
    defaultShowWarnings ||= showWarnings;
    defaultShowErrors ||= showErrors;
    setMsg({ message: <LoadingSpinner />, buttons: [] });
    const result = await authChecker();
    if (!result) {
      navigate('/app/signin');
      return;
    }
    setDebug(false);
    try {
      const materials = checkCollectionPermission(materialsData, 'MATERIAL_2');
      if (!materials.length) {
        throw new Error('No Materials found.');
      }

      const loadings = checkCollectionPermission(loadingsData, 'LOAD_2');
      const check = canSimulate(selectedUnit, materials, loadings, options);

      if (check.ok) {
        const simData = {
          materials: cleanCollection(check.materials),
          loadings: cleanCollection(check.loadcases),
        };

        if (check.warn) {
          defaultShowWarnings(check, simData);
          return;
        }

        defaultDoRun(simData);
        return;
      }

      defaultShowErrors(check);
      return;
    } catch (e) {
      const errorMessage = e.message || VERIFY_MSG;
      setMsg({
        class: STYLE_DANGER,
        title: VERIFY_TITLE,
        message: stdMessage(errorMessage),
        devMsg: String(e),
      });
    }
  };

  useEffect(() => {
    if (visible) doVerify();
  }, [visible]);

  msg.attr = {
    backdrop: 'static',
  };

  return {
    msg,
    toggle,
    toggleDebug,
    debug,
    visible,
    setVisible,
    showWarnings,
    showErrors,
    doRun,
    doVerify,
  };
};

export const SimulationModal = (props) => {
  const { msg, toggle, toggleDebug, debug, visible } = SimulationModalElements(props);

  return (
    <MessageModal
      data-test="simulation-modal-component"
      title={msg.title}
      color={msg.class}
      modal={visible}
      setModal={toggle}
      buttons={msg.buttons}
      actions={msg.actions}
      attr={msg.attr}
    >
      <div className="text-center">
        {msg.message ? <div data-test="simulation-modal-component-message">{msg.message}</div> : ''}
        <div
          onClick={toggleDebug}
          onKeyPress={toggleDebug}
          role="button"
          tabIndex={-20}
          style={{ width: '20px', height: '20px' }}
        />
        {debug ? (
          <span data-test="simulation-modal-component-dev-message" style={{ color: 'red', fontSize: '9px' }}>
            {msg.devMsg}
          </span>
        ) : (
          ''
        )}
      </div>
    </MessageModal>
  );
};

export default SimulationModal;
