import React from 'react';
import { Media } from 'reactstrap';
import { format } from 'd3-format';
import ChartJSGraph, { UNITS, makeScale, defaultConfig } from './ChartJSGraph';
import PlaceholderImg from '../../assets/images/ced_pro.png';

const OPEN_STYLE = 'circle';
const CLOSE_STYLE = 'cross';

const OPEN_COLOR = '#00ff00';
const CLOSE_COLOR = '#ff0000';

const CEDGraph = ({ graphKey, graph, height, width, interactive = false }) => {
  const { disabled } = graph;
  const config = defaultConfig('line', height, width);
  const pntStatus = graph.datasets[1].values.map((p) => p === 1);

  config.data = {
    datasets: [
      {
        data: graph.datasets[0].values,
        label: '',
        backgroundColor: 'blue',
        color: 'blue',
        fill: '',
        pointRadius: 5,
        borderWidth: 3,
        rotation: 45,
        pointStyle: pntStatus.map((p) => (p ? OPEN_STYLE : CLOSE_STYLE)),
        pointBackgroundColor: pntStatus.map((p) => (p ? OPEN_COLOR : CLOSE_COLOR)),
        borderColor: pntStatus.map((p) => (p ? OPEN_COLOR : CLOSE_COLOR)),
      },
    ],
    labels: graph.labels,
  };

  if (interactive) {
    config.options = {
      ...config.options,
      tooltips: {
        callbacks: {
          label: (tooltipItem) => (pntStatus[tooltipItem.index] ? 'Open' : 'Closed'),
        },
      },
      legend: { display: false },
      animation: false,
      hover: { animationDuration: 0 },
      responsiveAnimationDuration: 0,
      scales: {
        xAxes: [
          {
            stacked: true,
            ticks: {
              callback: (value) => format('.2')(value),
            },
            scaleLabel: {
              display: true,
              labelString: 'Time Step',
            },
          },
        ],
        yAxes: [
          {
            ...makeScale(graph.yFmt),
            scaleLabel: {
              display: true,
              labelString: `Cracking Energy Density ( ${UNITS[graph.measurementUnits].energyDensity} )`,
            },
          },
        ],
      },
    };
  } else {
    config.options = {
      ...config.options,
      legend: { display: false },
      events: [],
      scales: {
        xAxes: [makeScale('', interactive)],
        yAxes: [makeScale('', interactive)],
      },
    };
  }

  return (
    <>
      {!disabled ? (
        <ChartJSGraph options={config} canvasKey={graphKey} data-test="ced-graph-component" />
      ) : (
        <Media object src={PlaceholderImg} width="100%;" alt="You do not have permission." />
      )}
    </>
  );
};

export default CEDGraph;
