import React, { useEffect } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { navigate } from '@reach/router';
import User from '../../utils/auth';

import Layout from '../layout/Layout';
import CardHeading from '../layout/CardHeading';

export const checkUserIfLoggedIn = async () => {
  const userIsLoggedIn = await User.isLoggedIn();
  if (!userIsLoggedIn) {
    navigate('/app/about-us');
  }
};

const AboutUs = () => {
  const data = useStaticQuery(graphql`
    query About {
      wpPage(id: { eq: "cG9zdDo0Nw==" }) {
        title
        content
      }
    }
  `);

  const aboutData = data.wpPage;
  const heading = {
    title: `${aboutData.title}`,
    desc: `${aboutData.content}`,
    // desc: 'Endurica serves customers in industry sectors including defense, transportation, heavy equipment, offshore, medical devices and consumer products. We offer a full range of solutions to fit your objectives and work flow. Let Endurica bring your patented fatigue live analysis technologgy and services to your project.',
    btnName: 'Contact Us',
    btnPath: '/app/contact-us/',
  };

  useEffect(() => {
    checkUserIfLoggedIn();
  }, []);

  return (
    <Layout data-test="about-us-component">
      <div className="about">
        <CardHeading heading={heading} />
        <div className="video-holder">
          <iframe className="embed-responsive-item" src="https://www.youtube.com/embed/K2MS1P1eEZI" title="About" />
        </div>
      </div>
    </Layout>
  );
};

export default AboutUs;
