import React from 'react';
import _ from 'lodash';
import User from '../../utils/auth';

const ModeOfDeformationOptionsElements = () => {
  const options = [
    {
      acronym: 'ST',
      value: '1CNESI',
      label: '(ST) Simple Tension',
      permission: true,
    },
    {
      acronym: 'PT',
      value: '1CNEPL',
      label: '(PT) Planar Tension',
      permission: User.checkPermission('DEFORM_PLANAR'),
    },
    {
      acronym: 'EB',
      value: '1CNEEQ',
      label: '(BT) Biax Tension',
      permission: User.checkPermission('DEFORM_BIAX'),
    },
    {
      acronym: 'SS',
      value: '1CNESS',
      label: '(SS) Simple Shear',
      permission: User.checkPermission('DEFORM_SHEAR'),
    },
  ];

  const getOptionByValue = (value) => _.find(options, ['value', value]);

  return {
    options,
    getOptionByValue,
  };
};

const ModeOfDeformationOptions = () => {
  const { options } = ModeOfDeformationOptionsElements();
  return (
    <>
      {options.map((option, i) => (
        <option
          data-test="mode-of-deformation-option"
          key={i}
          value={option.permission ? option.value : ''}
          disabled={!option.permission}
        >
          {option.label + (!option.permission ? '- Pro Only' : '')}
        </option>
      ))}
    </>
  );
};

export { ModeOfDeformationOptionsElements };
export default ModeOfDeformationOptions;
