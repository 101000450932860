import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";
import gql from "graphql-tag";
import _ from "lodash";
import { navigate } from "gatsby";
import { useImperativeQuery } from "../../hooks/useQuery";
import { getPaymentMethodList } from "../../graphql/queries";

const GET_PAYMENT_METHODS = gql(getPaymentMethodList);

const CheckoutButtonElements = ({
  selectedPrice = {},
  currentPriceId = null,
  workflow = {},
}) => {
  const queryGetPaymentMethodList = useImperativeQuery(GET_PAYMENT_METHODS);
  const [isSamePlan, setIsSamePlan] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const selectedPriceId = _.get(selectedPrice, "id", null);

  useEffect(() => {
    setIsSamePlan(selectedPriceId === currentPriceId);
  }, [selectedPriceId, currentPriceId]);

  const handleCheckout = async () => {
    try {
      if (selectedPriceId === currentPriceId) {
        navigate("/app/settings");
        return;
      }

      setIsLoading(true);
      const isPlanFree = _.get(selectedPrice, "amount", 0) === 0;

      if (!isPlanFree) {
        const response = await queryGetPaymentMethodList();
        const result = _.get(
          response,
          "data.getPaymentMethodList.paymentMethods",
          "{}"
        );
        const hasPaymentMethod =
          _.get(JSON.parse(result), "data", []).length > 0;

        // Create subscription
        if (!hasPaymentMethod) {
          await workflow.redirect(selectedPriceId);
        }
        // Update subscription
        else {
          await workflow.update(selectedPriceId);
        }
      } else {
        await workflow.cancel(selectedPriceId);
      }
    } catch (e) {
      console.error("Error: ", e);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    handleCheckout,
    isSamePlan,
    isLoading,
  };
};
const CheckoutButton = (props) => {
  const { handleCheckout, isSamePlan, isLoading } =
    CheckoutButtonElements(props);
  let buttonText = !isSamePlan ? "Change Subscription" : "Keep Current Plan";
  buttonText = isLoading ? "Please wait..." : buttonText;

  return (
    <Button
      data-test="checkout-button-component"
      onClick={handleCheckout}
      className="link-button"
      color="primary"
      disabled={isLoading}
      block
    >
      {buttonText}
    </Button>
  );
};

export default CheckoutButton;
