import React, { useState } from 'react';
import { Link } from 'gatsby';
import { TabContent, TabPane, Nav, NavItem } from 'reactstrap';
import classnames from 'classnames';

const Tab = ({ children = [], titles = [], state = null }) => {
  if (
    state !== null &&
    (!Array.isArray(state) || state.length !== 2 || isNaN(state[0]) || typeof state[1] !== 'function')
  )
    throw Error(
      'If the state property is provided, it must be an array of two elements containing first the current active tab state, and second the single parameter function to change the active tab state'
    );

  if (titles.length !== children.length)
    throw Error(
      `${titles.length} titles have been provided for ${children.length} tabbed children. The number of each must match`
    );

  const [tab, setTab] = useState('0');

  const activeTab = state !== null ? state[0] : tab;
  const setActiveTab = state !== null ? state[1] : setTab;
  const toggle = (t) => {
    if (activeTab !== t) {
      setActiveTab(t);
    }
  };

  const handleToggle = (i) => () => {
    toggle(i.toString());
  };

  return (
    <>
      <div data-test="tab-component" className="tab-wrapper">
        <Nav tabs>
          {titles.map((nav, i) => (
            <NavItem key={i}>
              <Link
                to={nav.to}
                data-test={`tab-component-nav-${i}`}
                className={classnames({ active: activeTab.toString() === i.toString() }, 'nav-link')}
                data-testid={`navlink-${i}`}
                onClick={handleToggle(i)}
              >
                {nav.content}
              </Link>
            </NavItem>
          ))}
        </Nav>

        <TabContent activeTab={activeTab.toString()}>
          {React.Children.map(children, (el, idx) => (
            <TabPane key={idx} tabId={idx.toString()}>
              {React.cloneElement(el)}
            </TabPane>
          ))}
        </TabContent>
      </div>
    </>
  );
};

export default Tab;
