import React, { useState, useEffect } from 'react';
import { Flex } from 'rebass';
import { Form, Input, Label } from 'reactstrap';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import _ from 'lodash';
import { loadStripe } from '@stripe/stripe-js/pure';
import ManageSubscriptionButton from '../controls/ManageSubscriptionButton';

import appConfig from '../../appConfig';
import User from '../../utils/auth';
import { MessageModal } from '../modals/Modals';
import CheckoutButton from '../controls/CheckoutButton';
import LoadingSpinner from '../LoadingSpinner';
import DecodeObj from '../layout/DecodeObj';

import {
  createCheckoutSession,
  createSubscription,
  updateSubscription,
  deleteSubscription,
} from '../../graphql/mutations';
import { listProducts, getProration } from '../../graphql/queries';
import { useImperativeQuery } from '../../hooks/useQuery';
import LoggerUtils from '../../utils/logger';

const STRIPE_TEST_PUBLIC_KEY =
  'pk_test_51HXcg6AjNnaaPnVVSGlBKDKAAMJBYl1bq4m3upnFQv0XMhyT6bom6cpBwb8UVn7vJWdNGdqMSIW19uPunDM2g4e300VIpAsjbS';

const LIST_PRODUCTS = gql(listProducts);
const GET_PRORATION = gql(getProration);
const CREATE_CHECKOUT_SESSION = gql(createCheckoutSession);
const UPDATE_SUBSCRIPTION = gql(updateSubscription);
const UPGRADE_SUBSCRIPTION = gql(createSubscription);
const CANCEL_SUBSCRIPTION = gql(deleteSubscription);

const noop = () => {};

const UNKNOWN_EXCEPTION_MESSAGE = 'An error occured! Please try again or contact the support if the problem persists.';
const DEFAULT_ERROR_TITLE = "There's something wrong...";

const PlansElements = () => {
  const data = useStaticQuery(graphql`
    query ProductPrices {
      wpPage(id: { eq: "cG9zdDo4NzQ=" }) {
        content
      }

      plansData: allWpPage(filter: { categories: { nodes: { elemMatch: { name: { eq: "Plans" } } } } }) {
        nodes {
          content
          id
          slug
          status
          title
          __typename
          categories {
            nodes {
              name
            }
          }
          pricing {
            annualPrice
            monthlyPrice
          }
        }
      }

      planDowngradeContent: allWpPage(filter: { slug: { eq: "plan-downgrade" } }) {
        nodes {
          content
          id
          slug
          title
          categories {
            nodes {
              name
            }
          }
        }
      }

      planUpgradeContent: allWpPage(filter: { slug: { eq: "upgrade-plan" } }) {
        nodes {
          content
          id
          slug
          title
          categories {
            nodes {
              name
            }
          }
        }
      }
    }
  `);

  const planNote = _.get(data, 'wpPage.content', '');
  const getChangeDetails = (plan) => {
    const details = _.get(data, `${plan}.nodes[0]`, {});
    const { slug, title, content } = details;
    return { slug, title, content };
  };
  const changeDetails = [
    getChangeDetails('planDowngradeContent'),
    getChangeDetails('planUpgradeContent'),
    {
      slug: 'plan-update',
      title: 'Update',
      content: '',
    },
  ];

  // Message Modal state
  const [showMsg, setShowMsg] = useState(false);
  const toggle = () => setShowMsg(!showMsg);
  const [msg, setMsgRaw] = useState({});
  const setMsg = (params) => {
    setMsgRaw({
      class: 'primary',
      title: '',
      message: '',
      devMsg: '',
      buttons: ['Ok'],
      ...params,
    });

    toggle();
  };

  const [isLoading, setIsLoading] = useState(true);
  const [isAnnualPlan, setIsAnnualPlan] = useState(true);
  const toggleAnnual = () => setIsAnnualPlan(!isAnnualPlan);
  const [userData, setUserData] = useState({});
  const [stripe, setStripe] = useState({});
  const [products, setProducts] = useState([]);
  const [selectedPriceId, setSelectedPriceId] = useState(null);
  const [currentPriceId, setCurrentPriceId] = useState(null);
  const queryListProducts = useImperativeQuery(LIST_PRODUCTS);
  const queryGetProration = useImperativeQuery(GET_PRORATION);
  const [mutateRedirectToCheckout, { loading: loadingRedirectToCheckout }] = useMutation(CREATE_CHECKOUT_SESSION, {
    onError,
  });
  const [mutateUpdateUserSubscription, { loading: loadingUpdateUserSubscription }] = useMutation(UPDATE_SUBSCRIPTION, {
    onError,
  });
  const [mutateUpgradeSubscription, { loading: loadingUpgradeSubscription }] = useMutation(UPGRADE_SUBSCRIPTION, {
    onError,
  });
  const [mutateCancelSubscription, { loading: loadingCancelSubscription }] = useMutation(CANCEL_SUBSCRIPTION, {
    onError,
  });

  useEffect(() => {
    let title;

    if (loadingRedirectToCheckout) title = 'Redirecting to checkout page.';
    if (loadingUpdateUserSubscription) title = 'Updating Plan';
    if (loadingUpgradeSubscription) title = 'Upgrading Plan';
    if (loadingCancelSubscription) title = 'Cancelling Plan';

    if (title) {
      setMsg({ title, message: <LoadingSpinner /> });
    }
  }, [loadingRedirectToCheckout, loadingUpdateUserSubscription, loadingUpgradeSubscription, loadingCancelSubscription]);

  const setProductList = async () => {
    const response = await queryListProducts();
    const results = _.get(response, 'data.listProducts.products', '{}');
    const productsResults = JSON.parse(results);
    const productsData = _.get(productsResults, 'data', []);
    const productsList = _.map(productsData, (product) => {
      const { name, id } = product;
      const slug = _.get(product, 'metadata.slug', '');
      const plansData = _.get(data, 'plansData.nodes', []);
      const productMatch = _.find(plansData, ['slug', slug]);
      const featureList = _.get(productMatch, 'content', null);

      const prices = _.get(product, 'prices.data', []);
      const priceIntervals = ['annual', 'monthly'];
      const priceList = _.map(priceIntervals, (interval) => {
        const price = _.find(prices, ['nickname', interval]);
        const name = _.get(product, 'name', null);
        const id = _.get(price, 'id', null);
        const amount = _.get(price, 'unit_amount', 0) / 100;

        return { id, name, amount, interval };
      });

      return {
        id,
        name,
        priceList,
        featureList,
        raw: product,
      };
    });

    setProducts(productsList);
  };

  const Init = async () => {
    try {
      const user = await User.getData();
      const plan = _.get(user, 'subscriptionData.plan', null);
      const userPriceId = _.get(plan, 'id', null);
      const planInterval = _.get(plan, 'interval', null);
      const isAnnual = planInterval === 'year';

      setUserData(user);
      setCurrentPriceId(userPriceId);
      setSelectedPriceId(userPriceId);
      setIsAnnualPlan(isAnnual);
      await setProductList();

      const stripeObj = await loadStripe(appConfig.stripePublicKey || STRIPE_TEST_PUBLIC_KEY);
      setStripe(stripeObj);

      console.log(stripeObj);

      setIsLoading(false);
    } catch (e) {
      onError(e);
    }
  };

  // Without existing payment method
  // Free to Pro
  const processRedirect = (priceId) => async () => {
    const baseUrl = `${window.location.origin}/app/settings`;
    const successUrl = `${baseUrl}?status=success`;
    const cancelUrl = `${baseUrl}?status=cancel`;
    const input = {
      priceId,
      successUrl,
      cancelUrl,
    };
    const result = await mutateRedirectToCheckout({ variables: { input } });
    const session = _.get(result, 'data.createCheckoutSession.session', null);
    const sessionId = _.get(JSON.parse(session), 'id', null);
    stripe.redirectToCheckout({ sessionId });
  };
  const redirect = async (priceId) => {
    const slug = 'upgrade-plan';
    await confirmChange(slug, processRedirect(priceId));
  };

  // With existing payment method
  // Free to Pro
  const processUpgrade = (priceId) => async () => {
    const input = { priceId };
    const response = await mutateUpgradeSubscription({ variables: { input } });
    const results = _.get(response, 'data.createSubscription.paymentIntent', '{}');
    const paymentIntent = JSON.parse(results);
    await handleResults(paymentIntent);
  };
  // Pro to Pro
  const processUpdate = (priceId) => async () => {
    const subscriptionId = _.get(userData, 'subscriptionData.id', null);
    const input = {
      subscriptionId,
      priceId,
    };
    const response = await mutateUpdateUserSubscription({ variables: { input } });
    const results = _.get(response, 'data.updateSubscription.paymentIntent', '{}');
    const paymentIntent = JSON.parse(results);
    await handleResults(paymentIntent);
  };
  const update = async (priceId) => {
    let handler;
    let slug;

    if (currentPriceId) {
      handler = processUpdate;
      slug = 'plan-update';
    } else {
      handler = processUpgrade;
      slug = 'upgrade-plan';
    }

    await confirmChange(slug, handler(priceId));
  };

  const handleResults = async (paymentIntent) => {
    const paymentIntentStatus = _.get(paymentIntent, 'status', null);

    if (paymentIntent.error) {
      const { message } = paymentIntent.error;
      throw new Error(message);
    } else if (paymentIntentStatus === 'requires_payment_method') {
      const message = _.get(paymentIntent, 'last_payment_error.message', 'There was an error using the card.');
      throw new Error(message);
    } else if (paymentIntentStatus === 'requires_action') {
      const client_secret = _.get(paymentIntent, 'client_secret', null);
      const result = await stripe.confirmCardPayment(client_secret);
      await handleResults(result);
    } else {
      success();
    }
  };

  const processCancel = async () => {
    const user = userData;
    const subscriptionId = _.get(user, 'subscriptionData.id', null);
    const input = { subscriptionId };
    await mutateCancelSubscription({ variables: { input } });
    navigate('/app/settings?status=downgrade');
  };
  const cancel = async () => {
    const slug = 'plan-downgrade';
    await confirmChange(slug, processCancel);
  };

  const success = () => {
    navigate('/app/settings?status=success');
  };

  const onError = (error) => {
    const message = error.message || UNKNOWN_EXCEPTION_MESSAGE;
    LoggerUtils.error(error);
    const msgObj = {
      title: DEFAULT_ERROR_TITLE,
      message: <div>{message}</div>,
      buttons: ['Ok'],
    };

    if (currentPriceId) {
      msgObj.buttonComponents = [<ManageSubscriptionButton text="Manage Payment Methods" />];
    }

    setMsg(msgObj);
  };

  const workflow = {
    redirect,
    confirmChange,
    update,
    cancel,
  };

  const getPriceDetails = (priceId) => {
    const priceList = _.map(products, (product) => product.priceList);
    const prices = _.flatten(priceList);
    const price = _.find(prices, ['id', priceId]);
    return price;
  };

  const formatCurrency = (amount) => {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    return formatter.format(amount);
  };

  const formatPrice = (price) =>
    price.amount === 0 ? '' : `${formatCurrency(price.amount)}${price.interval === 'annual' ? ' /yr' : ' /mo'}`;

  const confirmChange = async (slug = 'plan-update', yes = noop) => {
    try {
      const details = _.find(changeDetails, ['slug', slug]);
      const title = _.get(details, 'title', '');
      const content = _.get(details, 'content', '');
      const currentPrice = getPriceDetails(currentPriceId);
      const newPrice = getPriceDetails(selectedPriceId);

      const showProration = slug === 'plan-update' && currentPrice?.amount < newPrice?.amount;
      let proratedPrice = 0;
      if (showProration) {
        setMsg({
          class: 'warning',
          title: `${title} Subscription`,
          message: <LoadingSpinner />,
          buttons: [],
          actions: [],
        });

        const { subscriptionData } = userData;
        const subscriptionId = _.get(subscriptionData, 'id', null);
        const upcomingInvoice = await queryGetProration({
          subscriptionId,
          priceId: selectedPriceId,
        });
        const response = _.get(upcomingInvoice, 'data.getProration.invoice', '{}');
        const total = JSON.parse(response).total || 0;
        proratedPrice = total / 100;
      }

      setMsg({
        class: 'warning',
        title: `${title} Subscription`,
        message: (
          <div className="container p-3">
            <div className="align-self-center">
              <DecodeObj objVal={content} />
            </div>
            <br />

            <div className="w-50 p-3 shadow m-auto">
              <div className="row mb-2">
                <div className="w-50 text-right align-middle">Current Subscription:</div>
                <div className="w-50 text-center font-weight-bolder">
                  {' '}
                  {currentPrice.name} <br />
                  {formatPrice(currentPrice)}
                </div>
              </div>

              <br />
              <div className="row mb-2">
                <div className="w-50 text-right align-middle">New Subscription:</div>
                <div className="w-50 text-center font-weight-bolder">
                  {' '}
                  {newPrice.name} <br />
                  {formatPrice(newPrice)}
                </div>
              </div>
            </div>

            <br />
            {showProration && (
              <div>
                {proratedPrice > 0 ? (
                  <>
                    A charge of{' '}
                    <span className="text-success">
                      <b>{formatCurrency(proratedPrice)}</b>
                    </span>{' '}
                    will be processed for this upgrade.
                  </>
                ) : (
                  <>No charge will be made for this change. Any remaining unused subscription time will be used.</>
                )}
              </div>
            )}
          </div>
        ),
        buttons: ['Yes', 'Cancel'],
        actions: [yes],
      });
    } catch (err) {
      onError(err);
    }
  };

  // return priceId from selected product based on annual/monthly toggle
  const getPriceBySelectedInterval = (item) => {
    const interval = isAnnualPlan ? 'annual' : 'monthly';
    const priceList = _.get(item, 'priceList', []);
    const price = _.find(priceList, ['interval', interval]);
    return price;
  };

  const getSelectedPriceId = (item) => {
    const price = getPriceBySelectedInterval(item);
    const priceId = _.get(price, 'id', null);
    return priceId;
  };

  const selectPriceId = (item) => {
    const priceId = getSelectedPriceId(item);
    setSelectedPriceId(priceId);
  };

  const isChecked = (item) => {
    const priceId = getSelectedPriceId(item);
    return priceId === selectedPriceId;
  };

  const getFormattedPrice = (item) => {
    const price = getPriceBySelectedInterval(item);
    const { amount, interval } = price;
    const result = amount ? (
      `$${amount} per ${interval === 'annual' ? 'year' : 'month'}`
    ) : (
      <span className="font-italic text-muted small">&#36;0</span>
    );

    return result;
  };

  useEffect(() => {
    Init();
  }, []);

  return {
    isLoading,
    selectedPriceId,
    currentPriceId,
    products,
    workflow,
    planNote,
    msg,
    showMsg,
    toggle,
    isAnnualPlan,
    toggleAnnual,
    isChecked,
    selectPriceId,
    getPriceDetails,
    getFormattedPrice,
  };
};

const Plans = (props) => {
  const {
    isLoading,
    selectedPriceId,
    currentPriceId,
    products,
    workflow,
    planNote,
    msg,
    showMsg,
    toggle,
    isAnnualPlan,
    toggleAnnual,
    isChecked,
    selectPriceId,
    getPriceDetails,
    getFormattedPrice,
  } = PlansElements();

  return (
    <Form>
      <div className="card-box card-box-child">
        {isLoading ? (
          <div className="text-center">
            <LoadingSpinner />
          </div>
        ) : (
          <>
            <Flex className="plan-list" justifyContent="space-between">
              {products.map((item, i) => (
                <div key={i} className="plan-item">
                  <div className="text-center">
                    <Label check>
                      <Input type="radio" name="plan" checked={isChecked(item)} onChange={() => selectPriceId(item)} />
                      <span className="checkmark" />
                      {item.name}
                    </Label>
                    <p>{getFormattedPrice(item)}</p>
                  </div>
                </div>
              ))}
            </Flex>

            <Flex justifyContent="space-between" className="m-auto">
              {isAnnualPlan ? (
                <p className="f-s m-auto">
                  <a className="font-italic text-muted small" onClick={toggleAnnual}>
                    Looking for<u> Monthly </u>plans?
                  </a>
                </p>
              ) : (
                <p className="f-s m-auto">
                  <a onClick={toggleAnnual}>
                    Save with<u> Annual </u>plans!
                  </a>
                </p>
              )}
            </Flex>

            {products.length > 0 && (
              <div className="action-holder">
                <CheckoutButton
                  selectedPrice={getPriceDetails(selectedPriceId)}
                  currentPriceId={currentPriceId}
                  workflow={workflow}
                />
              </div>
            )}

            <Flex className="plan-list" justifyContent="space-between">
              {products.map((item, i) => (
                <div key={i} className="plan-desc-list">
                  <DecodeObj objVal={item.featureList} />
                </div>
              ))}
            </Flex>
          </>
        )}
      </div>
      <div className="plan-note">
        <div dangerouslySetInnerHTML={{ __html: planNote }} className="t-note" />
      </div>

      <MessageModal
        title={msg.title}
        color={msg.class}
        modal={showMsg} // current status
        setModal={toggle} // action to change Boolean
        buttons={msg.buttons}
        actions={msg.actions}
        buttonComponents={msg.buttonComponents}
      >
        <div className="text-center">{msg.message ? <>{msg.message}</> : ''}</div>
      </MessageModal>
    </Form>
  );
};

export default Plans;
