import React, { useContext } from 'react';
import { Media } from 'reactstrap';
import PlaceholderImg from 'assets/images/precursor_pro.png';
import { GlobalStateContext } from 'context/GlobalContextProvider';
import ChartJSGraph, { UNITS, defaultConfig, mapDataset, scaleLog10, filterOutliers } from './ChartJSGraph';
import sortBy from 'lodash/sortBy';
import { format } from 'd3-format';

const FlawCalibrationGraph = ({ index, graphKey, graph, height, width, interactive = false }) => {
  const { disabled, datasets, labels, measurementUnits } = graph;
  const { options } = useContext(GlobalStateContext);
  const config = defaultConfig('line', height, width);
  config.data = {
    datasets: datasets
      .map((e, i) => mapDataset(e, i))
      .map((e) => ({
        ...e,
        data: e.data?.map((el) => Math.log10(el)) ?? [],
      })),
    labels: sortBy(labels?.map((e) => Math.log10(e)) ?? []),
  };
  const max = Math.max(...config.data.datasets[0].data);
  const { mat } = graph;
  const scale = scaleLog10({ interactive });
  const yAxesScaleConfig = {
    ticks: {
      callback: (value) => (interactive ? format('.3e')(value) : ' '),
      beginAtZero: true,
      min: 0,
    },
    scaleLabel: {
      display: interactive,
      labelString: 'log10(Fatigue Life ( cycles ))',
    },
  };
  const xAxesScaleConfig = {
    ticks: {
      callback: (value) => (interactive ? format('.3e')(value) : ' '),
      min: mat?.C0[0] ?? 0,
      max: Number(options.sizeEOL),
    },
    scaleLabel: {
      display: interactive,
      labelString: `log10(Precursor flaw size ( ${UNITS[measurementUnits].size} ))`,
    },
  };
  if (interactive) {
    config.options = {
      ...config.options,
      legend: { display: false },
      animation: false,
      hover: { animationDuration: 0 },
      responsiveAnimationDuration: 0,
      scales: {
        xAxes: [xAxesScaleConfig],
        yAxes: [yAxesScaleConfig],
      },
    };
  } else {
    config.options = {
      ...config.options,
      legend: { display: false },
      events: [],
      scales: {
        xAxes: [xAxesScaleConfig],
        yAxes: [yAxesScaleConfig],
      },
    };
  }

  return (
    <>
      {!disabled ? (
        <ChartJSGraph options={config} canvasKey={graphKey} data-test="flaw-calibration-graph-component" />
      ) : (
        <Media object src={PlaceholderImg} width="100%;" alt="You do not have permission." />
      )}
    </>
  );
};

export default FlawCalibrationGraph;
