import React, { useState, useEffect } from 'react';
import { graphql, useStaticQuery, navigate } from 'gatsby';
import _ from 'lodash';
import User from '../../utils/auth';
import Layout from '../layout/Layout';
import CardHeading from '../layout/CardHeading';

const Terms = () => {
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const data = useStaticQuery(graphql`
    query TermsConditionQuery {
      wpPage(id: { eq: "cG9zdDo2Mw==" }) {
        title
        content
      }
    }
  `);
  const termsData = data.wpPage;
  const [heading, setHeading] = useState({
    title: `${termsData.title}`,
    desc: `${termsData.content}`,
    btnName: 'Close',
    btnPath: null,
  });

  useEffect(() => {
    async function Init() {
      const isLoggedIn = await User.isLoggedIn();
      const btnPath = isLoggedIn ? '/app/settings' : '/create-account';

      setHeading({ ...heading, btnPath });
      setUserLoggedIn(isLoggedIn);
    }

    Init();
  }, []);

  return (
    <Layout data-test="terms-component" hideIcons={!userLoggedIn}>
      <div className="terms">
        <CardHeading heading={heading} />
      </div>
    </Layout>
  );
};

export default Terms;
