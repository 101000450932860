import React, { useEffect } from "react";
import Layout from "../components/layout/Layout";
import CardHeading from "../components/layout/CardHeading";

import "../styles/main.scss";
import "bootstrap/dist/css/bootstrap.min.css";

const heading = {
  title: "Page not found",
  desc: "Oops! The page you are looking for is not found",
};

const NotFoundPage = () => {
  return (
    <Layout data-test="404-component">
      <div className="error-message">
        <CardHeading heading={heading} />
        ABCDEFG
      </div>
    </Layout>
  );
};

export default NotFoundPage;
