import React from 'react';
import LoggerUtils from 'utils/logger';

export const LogContext = React.createContext();

class LogContextProvider extends React.Component {
  state = {};
  render() {
    return <LogContext.Provider value={{
        ...this.state,
        ...LoggerUtils,
    }}>
      {this.props.children}
    </LogContext.Provider>
  }
}

export default LogContextProvider;