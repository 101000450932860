import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, FormGroup, Form, Table } from 'reactstrap';
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import _ from 'lodash';
import DeleteSVG from '../../assets/svg/button.delete.inline.svg';
import SearchSVG from '../../assets/svg/search.inline.svg';
import { MessageModal } from './Modals';
import LoadingSpinner from '../LoadingSpinner';
import { useImperativeQuery } from '../../hooks/useQuery';
import LoggerUtil from 'utils/logger';

const noop = (data) => data;
const defaultMsgValues = { visible: false, title: '', content: '', button: ['Close'] };
const defaultQueries = { list: '', delete: '' };

export const SearchElements = ({
  sim,
  doSim,
  title,
  permission = true,
  defaultMsg = defaultMsgValues,
  queries = defaultQueries,
  onSelectHandler = noop,
  onQueryHandler = noop,
  onDeleteHandler = noop,
}) => {
  const query = Object.keys(queries.list)[0];
  const mutateDelete = Object.keys(queries.delete)[0];
  const listItems = gql(queries.list[query]);
  const deleteItem = gql(queries.delete[mutateDelete]);
  const queryListItems = useImperativeQuery(listItems, { onError });
  const [mutateDeleteItem, { loading: loadingMutateDeleteItem }] = useMutation(deleteItem, { onError });

  useEffect(() => {
    if (isLoading || loadingMutateDeleteItem) {
      setIsLoading(true);
    }
  }, [isLoading, loadingMutateDeleteItem]);

  const [msg, setMsg] = useState(defaultMsg);
  const [value, setValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [list, setList] = useState([]);
  const showMessage = (title, content, button = ['Close'], actions = []) =>
    setMsg({
      visible: true,
      title,
      content,
      button,
      actions,
    });
  const closeMessage = () => setMsg({ visible: false, title: '', content: '' });

  const onSearchBtnClick = () => {
    doSim(true);
    onQuery();
  };
  const onQuery = async () => {
    try {
      setIsLoading(true);
      let items = [];

      if (permission) {
        const results = await queryListItems();
        const raw = _.get(results, `data[${query}].items`, []);
        items = _.map(raw, (item) => {
          const actions = { delete: true };
          return { ...item, actions };
        });
      }

      items = onQueryHandler(items);
      setList(items);
    } catch (e) {
      onError(e);
    } finally {
      setIsLoading(false);
    }
  };

  const onSelect = (item) => (e) => {
    try {
      e.preventDefault();

      // Remove unnecessary props
      // updatedAt and createdAt are from dynamodb
      // actions is for display
      item = _.omit(item, 'updatedAt', 'createdAt', 'actions');

      onSelectHandler(item);
      closeMessage();
      doSim(false);
    } catch (err) {
      onError(err);
    }
  };

  const confirmDelete = (item) => () =>
    showMessage(
      <>
        Delete <b>{item.name}</b>
      </>,
      'Permanently delete this item?',
      ['Yes', 'No'],
      [
        () => {
          onDelete(item);
        },
      ]
    );

  const onDelete = async (item) => {
    try {
      const proceedDelete = onDeleteHandler(item);
      if (proceedDelete) {
        closeMessage();
        await mutateDeleteItem({ variables: { input: { id: item.id } } });
        await onQuery();
      }
    } catch (e) {
      onError(e);
    }
  };

  const onSearch = (e) => setValue(e.target.value);

  const onSubmit = (e) => e.preventDefault();

  const onClose = () => {
    setValue('');
    doSim(false);
  };

  return {
    value,
    sim,
    title,
    list,
    isLoading,
    msg,
    closeMessage,
    confirmDelete,
    onSelect,
    onSubmit,
    onSearch,
    onSearchBtnClick,
    onClose,
  };

  function onError(error) {
    const message =
      error.message || 'An error occured! Please try again or contact the support if the problem persists.';
    LoggerUtil.error(error);
    setMsg({
      visible: true,
      title: "There's something wrong...",
      content: <div>{message}</div>,
      button: ['Close'],
      class: 'danger',
    });
  }
};

const Search = (props) => {
  const {
    value,
    sim,
    title,
    list,
    isLoading,
    msg,
    closeMessage,
    confirmDelete,
    onSelect,
    onSubmit,
    onSearch,
    onSearchBtnClick,
    onClose,
  } = SearchElements(props);

  return (
    <div data-test="search-component">
      <button
        data-test="search-component-button"
        type="button"
        id="searchMaterials"
        onClick={onSearchBtnClick}
        onKeyDown={onSearchBtnClick}
        className="btn-action"
      >
        <SearchSVG />
      </button>

      <Modal isOpen={sim} className="modal-wrap">
        <ModalHeader>
          <span data-test="search-component-header" className="text-primary h4">
            {title}
          </span>
        </ModalHeader>

        <ModalBody className="text-center">
          {!isLoading && (
            <Form onSubmit={onSubmit}>
              <FormGroup>
                <Input
                  id="search"
                  className="form-search"
                  type="text"
                  name="search"
                  value={value}
                  placeholder="Search..."
                  onChange={onSearch}
                />
              </FormGroup>
              <Table className="modal-table" hover borderless>
                <tbody>
                  {list
                    .filter((item) => item.name.toLowerCase().includes(value.toLowerCase()))
                    .map((item, i) => (
                      <tr key={i}>
                        <td className="item-name">
                          <span onClick={onSelect(item)}>{item.name}</span>
                        </td>
                        <td className="item-action">
                          {item.actions.delete && (
                            <button type="button" onClick={confirmDelete(item)}>
                              <DeleteSVG />
                            </button>
                          )}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </Form>
          )}
          {isLoading && <LoadingSpinner />}
        </ModalBody>

        <ModalFooter>
          <Button data-test="search-component-button-footer" color="primary" onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <MessageModal
        title={msg.title}
        modal={msg.visible}
        setModal={closeMessage}
        buttons={msg.button}
        color={msg.class}
        actions={msg.actions}
      >
        {msg.content}
      </MessageModal>
    </div>
  );
};

export default Search;
