import React from 'react';
import { createSelector } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

const dialogSelector = createSelector(
  (state) => state.app.dialog,
  (e) => e
);
function ModalContainer() {
  const dialog = useSelector(dialogSelector);
  let DialogComponent = null;
  if (dialog && dialog.component) {
    ({ component: DialogComponent } = dialog);
    return <DialogComponent {...dialog.props} />;
  }
  return null;
}

export default ModalContainer;
