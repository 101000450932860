import React from 'react';
import { Media } from 'reactstrap';
import Plot from 'react-plotly.js';
import PlaceholderImg from '../../assets/images/haigh_pro.png';

class PlotlyGraph extends React.Component {
  constructor(props) {
    super();
    this.props = props;
  }

  render() {
    const g = this.props.graph;
    const { width } = this.props;
    const { height } = this.props;
    const { disabled } = g;
    const propsLayout = this.props.layout || this.props.graph.layout;
    const { xaxis, yaxis, margin, colorbarTitle } = propsLayout;
    const { interactive } = this.props;

    const data = [
      {
        x: g.datasets.x,
        y: g.datasets.y,
        z: g.datasets.z,
        type: g.type,
        connectgaps: true,
        zsmooth: 'best',
        colorscale: 'Jet',
        reversescale: true,
      },
    ];

    data[0].colorbar = {
      title: colorbarTitle,
      titleside: 'top',
      tickmode: 'array',
      thicknessmode: 'pixels',
      thickness: 30,
      tickvals: [...g.labels.keys()].map((e) => e + 1),
      ticktext: [...g.labels.values()],
    };

    const config = { displayModeBar: false, displaylogo: false };

    const layout = {
      xaxis: {
        tickmode: 'linear',
        tick0: -1,
        dtick: 0.25,
        showticklabels: false,
        fixedrange: !interactive,
        visible: interactive,
        ...xaxis,
      },
      yaxis: {
        tickmode: 'linear',
        tick0: -1,
        dtick: 0.25,
        showticklabels: false,
        fixedrange: !interactive,
        visible: interactive,
        ...yaxis,
      },
      width,
      height,
      responsive: true,
      showlegend: false,
      transition: {
        duration: 1000,
        easing: 'linear',
      },
      margin,
      hovermode: interactive,
    };

    return (
      <>
        {!disabled ? (
          <Plot data-test="plotly-graph-component" data={data} config={config} layout={layout} />
        ) : (
          <Media
            data-test="plotly-graph-component-placeholder"
            object
            src={PlaceholderImg}
            width="100%;"
            alt="You do not have permission."
          />
        )}
      </>
    );
  }
}

export default PlotlyGraph;
