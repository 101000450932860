import React from 'react';

function NextLineText(props) {
  const { text } = props;
  return (
    <div style={{ textAlign: 'left' }}>
      {text.split('\n').map((e) => (
        <p>{e}</p>
      ))}
    </div>
  );
}

export default NextLineText;
