/* eslint-disable no-template-curly-in-string */
import ordinal from 'ordinal';
import * as yup from 'yup';
import _ from 'lodash';

export const numberRegex = /^([+-]?)([0-9]*)([.]?)([0-9]*)(([eE]([+-]?)([0-9]*))?)$/;

export const modeOfControlRegex = /^(([+-]?)([0|1])|([+-]?)(1\.)(0*)|([+-]?)(0)(.)([0-9]{1,}))$/;

export const isNumber = (key) => numberRegex.test(key);

export const isModeOfControl = (key) => modeOfControlRegex.test(key);

export const ifNumber = (value, def) => (isNumber(value) ? value : def);

export const handleNumber = (value, onNumber) => {
  let num = value;
  if (value.indexOf('-') > -1) {
    num = `-${value.replace('-', '')}`;
  }

  if (value.indexOf('+') > -1) {
    num = value.replace('-', '').replace('+', '');
  }

  if (isNumber(num)) {
    if (onNumber) {
      onNumber(num);
    }

    return num;
  }

  return null;
};

// Materials and Loadcases
const composeValidators =
  (...validators) =>
  (value, context) => {
    const def = {
      value,
      context,
      errors: [],
    };

    const result = validators.reduce((y, f) => f(y), def);

    if (result.errors.length) {
      return context.createError({
        message: `${context.type}: ${result.errors.join(', ')}`,
      });
    }

    return result;
  };

const isArrayMin = (limit) => (props) => {
  const { value, errors } = props;

  if (value?.length < limit) {
    errors.push(`requires minimum of ${limit}`);
  }

  return props;
};

const isArrayMax = (limit) => (props) => {
  const { value, errors } = props;

  if (value?.length > limit) {
    errors.push(`exceeds maximum of ${limit}`);
  }

  return props;
};

const hasRightFieldLength = () => (props) => {
  const { value, context, errors } = props;
  const data = context?.parent || context?.options?.context?.data;
  const tempFieldLength = data?.temp?.length;

  if (tempFieldLength && !(value?.length === 1 || value?.length === 2 || value?.length === tempFieldLength)) {
    errors.push(`Field length must be 1, 2 or the same number of Reference Temperature(${tempFieldLength})`);
  }

  return props;
};

const matchRegex = (regex) => (props) => {
  regex ||= numberRegex;
  const { value, errors } = props;

  if (_.isArray(value)) {
    const err = [];

    value.forEach((e, idx) => {
      if (!regex.test(e)) {
        const num = ordinal(idx + 1);
        err.push(`${num} field has invalid value of ${e}`);
      }
    });

    if (err.length) {
      errors.push(err.join(', '));
    }
  } else if (!regex.test(value)) {
    errors.push('field is invalid');
  }

  return props;
};

const disallowBlank = (props) => {
  const { value, errors } = props;

  if (_.isArray(value)) {
    const err = [];

    value.forEach((e, idx) => {
      if (e === '') {
        const num = ordinal(idx + 1);
        err.push(`${num} field has blank value`);
      }
    });

    if (err.length) {
      errors.push(err.join(', '));
    }
  } else if (value === '') {
    errors.push('field is invalid');
  }

  return props;
};

export const MATERIAL_FIELDS_DEFAULT = {
  name: '',
  units: '',
  temp: [''],
  E: [''],
  Tc: [''],
  T0: [''],
  C0: [''],
  F: [''],
  rc: [''],
  crystal: [''],
};

export const LOADINGS_FIELDS_DEFAULT = {
  id: 0,
  name: 'Load Case',
  mode: '1CNESI',
  peakStrain: '',
  minStrain: '',
  temp: '',
  control: 0,
};

export const MATERIALS_SCHEMA = yup.object().shape({
  name: yup.string().min(1, 'Name should not be blank'),
  units: yup.string().oneOf(['si-mm', 'si-m', 'imperial'], 'Mode of Deformation: invalid value'),
  temp: yup.array().test({
    name: 'Reference Temperature',
    test: composeValidators(isArrayMin(1), isArrayMax(5), matchRegex()),
  }),
  E: yup.array().test({
    name: "Young's Modulus",
    test: composeValidators(isArrayMin(1), hasRightFieldLength(), matchRegex()),
  }),
  Tc: yup.array().test({
    name: 'Critical Tearing Energy',
    test: composeValidators(isArrayMin(1), hasRightFieldLength(), matchRegex(), disallowBlank),
  }),
  T0: yup.array().test({
    name: 'Intrinsic Strength',
    test: composeValidators(isArrayMin(1), hasRightFieldLength(), matchRegex(), disallowBlank),
  }),
  C0: yup.array().test({
    name: 'Precursor Size',
    test: composeValidators(isArrayMin(1), hasRightFieldLength(), matchRegex()),
  }),
  F: yup.array().test({
    name: 'FCG Slope',
    test: composeValidators(isArrayMin(1), hasRightFieldLength(), matchRegex()),
  }),
  rc: yup.array().test({
    name: 'FCG Constant',
    test: composeValidators(isArrayMin(1), hasRightFieldLength(), matchRegex()),
  }),
  crystal: yup.array().test({
    name: 'Crystallization Strength',
    test: composeValidators(isArrayMin(1), hasRightFieldLength(), matchRegex()),
  }),
});

export const LOADINGS_SCHEMA = yup.object().shape({
  loadCase: yup.string().min(1, 'Name should not be blank'),
  mode: yup.string().oneOf(['1CNESI', '1CNEPL', '1CNEEQ', '1CNESS'], 'Mode of Deformation: invalid value'),
  peakStrain: yup
    .string()
    .required('Peak Nom Eng Strain required')
    .test({
      name: 'Peak Nom Eng Strain',
      test: composeValidators(matchRegex()),
    }),
  minStrain: yup
    .string()
    .required('Min Nom Eng Strain required')
    .test({
      name: 'Min Nom Eng Strain',
      test: composeValidators(matchRegex()),
    }),
  temp: yup
    .string()
    .required('Reference Temperature required')
    .test({
      name: 'Reference Temperature',
      test: composeValidators(matchRegex()),
    }),
  control: yup
    .number()
    .min(-1, 'Mode of Control should not be lower than ${min}')
    .max(1, 'Mode of Control should not be more than ${max}'),
});
