import React from 'react';
import _ from 'lodash';
import Layout from '../layout/Layout';
import ResultsComponent from '../forms/ResultsComponent';

const SimulationResults = (props) => {
  const { tab, uri } = props;
  const params = uri.split('/');
  const type = _.get(params, '[4]', null);
  const order = _.get(params, '[5]', null);
  return (
    <Layout data-test="simulation-results-component">
      <ResultsComponent tab={tab} type={type} order={order} />
    </Layout>
  );
};

export default SimulationResults;
