import React, { useEffect } from 'react';
import { Button, Form, FormGroup, Input } from 'reactstrap';
import { graphql, useStaticQuery } from 'gatsby';
import { navigate } from '@reach/router';
import User from '../../utils/auth';

import Layout from '../layout/Layout';
import CardHeading from '../layout/CardHeading';

export const checkUserIfLoggedIn = async () => {
  const userIsLoggedIn = await User.isLoggedIn();
  if (!userIsLoggedIn) {
    navigate('/app/contact-us');
  }
};

const ContactUs = () => {
  const data = useStaticQuery(graphql`
    query ContactUsQuery {
      wpPage(id: { eq: "cG9zdDo4NzA=" }) {
        title
        content
      }
    }
  `);

  const contactData = data.wpPage;
  const heading = {
    title: `${contactData.title}`,
    desc: `${contactData.content}`,
  };

  useEffect(() => {
    checkUserIfLoggedIn();
  }, []);

  return (
    <Layout data-test="contact-us-component">
      <CardHeading heading={heading} />
      <Form>
        <FormGroup>
          <Input type="text" name="name" id="name" placeholder="Name" />
        </FormGroup>
        <FormGroup>
          <Input type="email" name="contactEmail" id="contactEmail" placeholder="Email" />
        </FormGroup>
        <FormGroup>
          <Input type="select" name="select" id="select" placeholder="Select">
            <option>Select</option>
            <option>1</option>
            <option>2</option>
            <option>3</option>
            <option>4</option>
            <option>5</option>
          </Input>
          {/* <Arrow /> */}
        </FormGroup>
        <FormGroup>
          <Input type="textarea" name="message" id="message" placeholder="Message" />
        </FormGroup>
        <Button block color="primary">
          Send Message
        </Button>
      </Form>
    </Layout>
  );
};

export default ContactUs;
