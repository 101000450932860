import React, { useState } from 'react';
import { FormGroup, Input } from 'reactstrap';
import { isNumber } from '../../lib/valdiators';

const formLimit = 5;

export const OneField = ({ idx, value, onChange, handleRemoveclick }) => {
  const showInput = onChange ? 'd-show' : 'd-none';
  const showMinus = handleRemoveclick !== null;
  const chg = onChange || (() => {});

  return (
    <div data-test="one-field-component" style={{ width: '100%' }} className={showInput}>
      {showMinus ? (
        <button
          data-test="one-field-component-button"
          className="icon-minus"
          type="button"
          onClick={() => handleRemoveclick(idx)}
        />
      ) : (
        ''
      )}

      <Input
        data-test="one-field-component-input"
        type="text"
        className="specs"
        value={value || ''}
        id={idx}
        onChange={chg}
      />
    </div>
  );
};

export const ContentInputElement = ({ name, index, getData, setData, defaultCurrentInputs = null }) => {
  const values = getData;
  const setValues = setData;
  const [currentInputs, setCurrentInputs] = useState(defaultCurrentInputs);
  /* -------------------------------- remove form -------------------------------- */
  const handleRemoveclick = async (idx) => {
    if (idx >= 0 && idx < values.length) {
      const v = [...values];
      v.splice(idx, 1);
      setValues(v);
    }
  };

  /* -------------------------------- Add form -------------------------------- */
  const handleAddClick = () => {
    if (values.length < formLimit) {
      const v = [...values];
      v.push(null);
      setValues(v);
    }
  };

  const handleBtnAdd = async (e, defaultHandleAddClick = null) => {
    e.persist();
    defaultHandleAddClick ||= handleAddClick;

    await defaultHandleAddClick();
    const el = e.target;
    if (values.length < formLimit - 1) {
      const { children } = el.closest('div');
      setCurrentInputs(children);
      const nextInput = children[values.length].children[1];
      nextInput.focus();
    } else {
      currentInputs[4].children[1].focus();
    }
  };

  /* ------------------------- Get value of form input ------------------------ */
  const onValueChange = async (e, pos, defaultHandleAddClick = null, defaultFocusOnNextInput = null) => {
    e.persist();
    defaultHandleAddClick ||= handleAddClick;
    defaultFocusOnNextInput ||= focusOnNextInput;
    const num = e.target.value;

    if (num.indexOf(',') > -1) {
      await defaultHandleAddClick();
      if (pos < indicies.length - 1) {
        defaultFocusOnNextInput(e);
      }
    } else if (isNumber(num) || num === '') {
      const v = [...values];
      v[pos] = num;
      setValues(v);
    }
  };

  const focusOnNextInput = (e) => {
    const el = e.target;
    const nextInput = el.closest('div').nextSibling.children[1];
    nextInput.focus();
  };

  const onChangeFor = (idx, defaultOnValueChange = null) => (e) => {
    defaultOnValueChange ||= onValueChange;

    defaultOnValueChange(e, idx);
  };

  const indicies = [0, 1, 2, 3, 4];

  return {
    name,
    index,
    indicies,
    values,
    onChangeFor,
    onValueChange,
    focusOnNextInput,
    handleAddClick,
    handleBtnAdd,
    handleRemoveclick,
  };
};

const ContentInput = (props) => {
  const { name, index, indicies, values, onChangeFor, handleBtnAdd, handleRemoveclick } = ContentInputElement(props);

  return (
    <FormGroup data-test="content-input-component" key={`${name}-group-${index}`}>
      {indicies.map((idx) => (
        <OneField
          data-test={`content-input-component-one-field-${idx}`}
          key={idx}
          idx={idx}
          value={values === null || values[idx] === null ? '' : values[idx]}
          onChange={values && idx < values.length ? onChangeFor(idx) : null}
          handleRemoveclick={values && values.length > 1 ? handleRemoveclick : null}
        />
      ))}

      {values.length < formLimit ? (
        <button
          data-test="content-input-component-button"
          className="icon-plus"
          type="button"
          onClick={(e) => handleBtnAdd(e)}
        />
      ) : (
        ''
      )}
    </FormGroup>
  );
};

export default ContentInput;
