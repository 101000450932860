import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'gatsby';
import _ from 'lodash';
import { GlobalStateContext } from '../../context/GlobalContextProvider';
import CardHeading from '../layout/CardHeading';
import Tab from '../layout/Tab';
import GraphView from './GraphView';
import nextImg from '../../assets/svg/next.svg';
import prevImg from '../../assets/svg/prev.svg';

const TAB_TITLES = [
  { text: 'Materials Behavior', path: 'materials' },
  { text: 'History Plots', path: 'history' },
  { text: 'Life Results', path: 'life' },
];

export const ResultsComponentElements = ({
  tab = 'materials',
  type = null,
  order = null,
  defaultWindowWidth = window.innerWidth,
  defaultInnerHeight = window.innerHeight,
}) => {
  const { simResults, resultsTab, setResultsTab, loadingsData } = useContext(GlobalStateContext);
  const [windowWidth, setWindowWidth] = useState(defaultWindowWidth);
  const [windowHeight, setWindowHeight] = useState(defaultInnerHeight);
  const tabIndex = getTabIndex(tab);
  const newSimResults = reduceSimResults(simResults);
  const graphs = simResults[resultsTab];
  type = type || _.get(graphs, '[0].key', null);

  useEffect(() => {
    if (tabIndex !== -1) {
      setResultsTab(tabIndex);
    }
  }, [tabIndex]);

  // Change the table title with tab content length
  const tabTitles = TAB_TITLES.map((item, index) => ({
    to: `/app/results/${item.path}`,
    content: (
      <>
        <div>{item.text}</div>
        <div style={{ fontSize: '70%', marginBottom: '4px' }}>({simResults[index].length} plots)</div>
      </>
    ),
  }));

  const getTabLink = (move, tabType, tabName) => {
    const currentType = graphs.find((g) => g.key === tabType);
    const currentTypeIndex = graphs.indexOf(currentType);
    const nextType = graphs[currentTypeIndex + move];
    const nextTypeKey = _.get(nextType, 'key', null);
    return nextType ? `/app/results/${tabName}/${nextTypeKey}` : null;
  };

  const Next = () => {
    if (graphs.length > 1) {
      const to = getTabLink(1, type, tab);
      return (
        <>
          {to && (
            <Link to={to} disabled={!to} className="float-right p-0" color="white">
              <img className="m-0" src={nextImg} alt="Next graph set" />
            </Link>
          )}
        </>
      );
    }
    return '';
  };

  const Prev = () => {
    if (graphs.length > 1) {
      const to = getTabLink(-1, type, tab);

      return (
        <>
          {to && (
            <Link to={to} disabled={!to} className="float-left p-0" color="white">
              <img className="m-0" src={prevImg} alt="Previous graph set" />
            </Link>
          )}
        </>
      );
    }
    return '';
  };

  const graph = graphs.find((g) => g.key === type) || {
    name: 'No simulation results available',
    items: [],
  };
  graph.name = getGraphName(graph.name);
  const wrap = (a) => (a ? (Array.isArray(a) ? a : [a]) : []);
  const items = wrap(graph.items);
  const multi = items.length > 1;
  const resizeWindow = () => {
    setWindowWidth(window.outerWidth);
    setWindowHeight(window.outerHeight);
  };

  useEffect(() => {
    resizeWindow();
    window.addEventListener('resize', resizeWindow);
    return () => window.removeEventListener('resize', resizeWindow);
  }, []);

  return {
    windowWidth,
    windowHeight,
    tabTitles,
    resultsTab,
    tab,
    setResultsTab,
    newSimResults,
    graph,
    wrap,
    items,
    multi,
    Next,
    Prev,
    order,
    type,
    getTabLink,
  };

  function reduceSimResults(results) {
    return results.reduce((a, tabData, tabIdx) => {
      const graphItems = tabData.map((data) => {
        const { key, name } = data;
        const tabName = _.get(TAB_TITLES, `${tabIdx}.path`, null);

        return data.items.map(({ type: graphType, material, loadCase, use, simulationId, ...item }, index) => ({
          ...item,
          key,
          use,
          material,
          loadCase,
          name: getGraphName(name),
          type: graphType,
          tab: tabName,
          isZoomed: true,
          order: index,
          id: [tab, simulationId, key, use, graphType, material, loadCase].join('-'),
        }));
      });

      return [...a, ..._.flatMap(graphItems)];
    }, []);
  }

  function getGraphName(name) {
    return name === 'STRAIN-LIFE MAP' ? 'Haigh' : name;
  }

  function getTabIndex(tabText) {
    const tabObj = TAB_TITLES.find((e) => e.path === tabText);
    return TAB_TITLES.indexOf(tabObj);
  }
};

const ResultsComponent = (props) => {
  const {
    windowWidth,
    windowHeight,
    tabTitles,
    resultsTab,
    tab,
    setResultsTab,
    newSimResults,
    graph,
    items,
    multi,
    Next,
    Prev,
    order,
    type,
    getTabLink,
  } = ResultsComponentElements(props);
  const SIZES = [
    [1200, 1040], // 1200 is break point
    [992, 800],
    [768, 700], // 768 breakpoint
    [576, 400],
    [0, 375],
  ];

  const refSize = Math.min(windowWidth, windowHeight - 150);
  const [breakpoint, baseSize] = SIZES.find((r) => refSize > r[0]);

  const size = baseSize - 60;
  const thumb = multi ? Math.trunc(size / 2.1) : size;

  return (
    <div data-test="results-component">
      <CardHeading heading={{ title: 'Simulation Results' }} />
      <Tab titles={tabTitles} state={[resultsTab, setResultsTab]}>
        <></>
        <></>
        <></>
      </Tab>

      <Next />
      <Prev />
      <GraphView
        graphs={newSimResults}
        graph={graph}
        items={items}
        thumb={thumb}
        size={size}
        order={order}
        type={type}
        tab={tab}
        getTabLink={getTabLink}
      />
    </div>
  );
};

export default ResultsComponent;
