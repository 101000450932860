import React, { useContext } from 'react';
import { FormGroup, Input, Button } from 'reactstrap';
import { navigate } from 'gatsby';
import flowRight from 'lodash/flowRight';
import withDialog from 'hoc/withDialog';
import { hideDialog, showDialog, updateDialogProps } from 'redux/app/reducer';
import { useDispatch } from 'react-redux';
import { GlobalStateContext } from '../../context/GlobalContextProvider';
import { simulateHfi } from '../../utils/simulate';
import User from '../../utils/auth';

const HfiDialog = flowRight(withDialog())(ModalContent);

export const HfiModalElements = () => {
  const { setSimResults } = useContext(GlobalStateContext);
  const dispatch = useDispatch();

  return {
    onSimulate,
    onToggle,
  };

  async function onSimulate(data) {
    try {
      const graphs = await simulateHfi(data.hfi);
      setSimResults(graphs);
      if (graphs) navigate('/app/results/materials');
      dispatch(hideDialog());
    } catch (error) {
      console.error('error: ', error);
      dispatch(updateDialogProps({ isErrorVisible: true }));
      setTimeout(() => {
        dispatch(updateDialogProps({ isErrorVisible: false }));
      }, 3000);
    }
  }

  function onToggle() {
    dispatch(
      showDialog({
        component: HfiDialog,
        props: {
          onValid: onSimulate,
          title: 'Custom HFi',
        },
      })
    );
  }
};
function ModalContent(props) {
  const { isErrorVisible } = props;
  const { formState, formHandlers } = props;
  const { fields } = formState;
  const { onElementChange } = formHandlers;
  return (
    <>
      {isErrorVisible ? (
        <div className="text-center">
          <span>Invalid HFi</span>
        </div>
      ) : (
        <FormGroup>
          <Input id="hfi" value={fields.hfi} onChange={onElementChange} rows={20} type="textarea" name="text" />
        </FormGroup>
      )}
    </>
  );
}

const HfiModal = () => {
  const { onToggle } = HfiModalElements();
  if (User.checkPermission('CUSTOM_HFI')) {
    return (
      <>
        <Button className="btn-info" style={{ marginLeft: '15px' }} onClick={onToggle}>
          Custom HFi
        </Button>
      </>
    );
  }
  return null;
};

export default HfiModal;
