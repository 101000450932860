import { format } from 'd3-format';

export default function convertMaterial(material, to) {
  to = to.toLowerCase();
  if (!material || material?.units === to) {
    return material;
  }
  const keys = [
    'precursorSize',
    'youngsModulus',
    'intrinsicStrength',
    'criticalTearingEnergy',
    'refTemp',
    'fcgRateLawConstant',
  ];
  const from = material.units;
  return keys.reduce(
    (acc, key) => {
      let functionName = key;
      if (key === 'intrinsicStrength') {
        functionName = 'criticalTearingEnergy';
      } else if (key === 'fcgRateLawConstant') {
        functionName = 'precursorSize';
      }

      return {
        ...acc,
        [key]: acc[key].map((v) => mapper(v, from, to, functionName)),
      };
    },
    {
      ...material,
      units: to,
    }
  );
}
export function convertOptions(option, to) {
  to = to.toLowerCase();
  if (!option || option?.units === to) {
    return option;
  }
  const keys = ['optionTmax', 'optionTmin', 'sizeEOL', 'bulkModulus'];
  const from = option.units;
  return keys.reduce(
    (acc, key) => {
      let functionName = key;
      if (key === 'optionTmax' || key === 'optionTmin') {
        functionName = 'criticalTearingEnergy';
      } else if (key === 'sizeEOL') {
        functionName = 'precursorSize';
      } else if (key === 'bulkModulus') {
        functionName = 'youngsModulus';
      }

      return {
        ...acc,
        [key]: mapper(acc[key], from, to, functionName),
      };
    },
    {
      ...option,
      units: to,
    }
  );
}

const converter = {
  youngsModulus({ value, from, to }) {
    const arr = [from, to];
    let multiplier;
    if (arr.includes('si-m') && arr.includes('si-mm')) {
      // si-m & si-mm
      multiplier = from === 'si-m' ? 1e-6 : 1e6;
    } else if (arr.includes('si-mm') && arr.includes('imperial')) {
      // si-mm & imperial
      multiplier = from === 'si-mm' ? 145.0377 : 1 / 145.0377;
    } else {
      // si-m & imperial
      multiplier = from === 'si-m' ? 0.000145038 : 1 / 0.000145038;
    }
    return multiplier * value;
  },
  refTemp({ value, from, to }) {
    const arr = [from, to];
    if (arr.includes('si-m') && arr.includes('si-mm')) {
      return value;
    }
    return from === 'imperial' ? degFtoDegC(value) : degCtoDegF(value);
  },
  criticalTearingEnergy({ value, from, to }) {
    const arr = [from, to];
    let multiplier;
    if (arr.includes('si-m') && arr.includes('si-mm')) {
      // si-m & si-mm
      multiplier = from === 'si-m' ? 1 / 1e3 : 1e3;
    } else if (arr.includes('si-mm') && arr.includes('imperial')) {
      // si-mm & imperial
      multiplier = from === 'si-mm' ? 5.71047 : 1 / 5.71047;
    } else {
      // si-m & imperial
      multiplier = from === 'si-m' ? 5.710147e-3 : 1 / 5.710147e-3;
    }
    return multiplier * value;
  },
  precursorSize({ value, from, to }) {
    const arr = [from, to];
    let multiplier;
    if (arr.includes('si-m') && arr.includes('si-mm')) {
      // si-m & si-mm
      multiplier = from === 'si-m' ? 1e3 : 1 / 1e3;
    } else if (arr.includes('si-mm') && arr.includes('imperial')) {
      // si-mm & imperial
      multiplier = from === 'si-mm' ? 0.03937 : 1 / 0.03937;
    } else {
      // si-m & imperial
      multiplier = from === 'si-m' ? 39.37 : 1 / 39.37;
    }
    return multiplier * value;
  },
};

export function isNumeric(str) {
  return !Number.isNaN(str) && !Number.isNaN(parseFloat(str));
}

export function degFtoDegC(value) {
  return ((value - 32) * 5) / 9;
}

export function degCtoDegF(value) {
  return 1.8 * value + 32;
}

function mapper(value, from, to, functionName) {
  const converterFn = converter[functionName];
  const result = isNumeric(value) && converterFn ? converterFn({ from, to, value: Number(value) }) : value;
  const [, mantissa] = `${result}`.split('.');
  if (mantissa?.length > 3) {
    return format('.2e')(result);
  }
  return result;
}
