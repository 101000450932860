import React, { useState } from 'react';
import ReactPlotly from 'react-plotly.js';
import { FormGroup, InputGroup, Input, Label } from 'reactstrap';
import { StaticImage } from 'gatsby-plugin-image';

export const CheckboxField = ({ checked, onChange, label }) => (
  <FormGroup check inline data-test="checkbox-field-component">
    <InputGroup>
      <Label check className="text-nowrap float-left m-1">
        <Input type="checkbox" name="options" checked={checked} onChange={onChange} />
        {label}
      </Label>
    </InputGroup>
  </FormGroup>
);

export const DamageSphereElements = ({
  graph,
  interactive,
  wireframe = false,
  showCrack = false,
  showArrow = false,
  showFace = false,
}) => {
  const { datasets } = graph;
  const { x, y, z, x2, z2, y2, life: lifeProps, crackPoints, minLines, crackFaces } = datasets;
  const life = React.useMemo(() => lifeProps.map((e) => Math.log10(e)), [lifeProps]);
  const toggle = (setFn) => (event) => setFn(event.target.checked);
  const [isCrackShown, setIsCrackShown] = useState(showCrack);
  const [isArrowShown, setIsArrowShown] = useState(showArrow);
  const checkboxFields = [
    {
      label: 'Crack plane',
      checked: isCrackShown,
      onChange: toggle(setIsCrackShown),
    },
    {
      label: 'Arrows',
      checked: isArrowShown,
      onChange: toggle(setIsArrowShown),
    },
  ];
  const crackMesh = React.useMemo(
    () =>
      isCrackShown
        ? crackPoints.map((point) => ({
            ...point,
            opacity: 1,
            type: 'mesh3d',
            hoverinfo: 'skip',
            color: '#000000',
          }))
        : [],
    [isCrackShown]
  );
  const arrowMesh = React.useMemo(() =>
    isArrowShown
      ? minLines
          .map((e) => ({
            ...e,
            mode: 'lines',
            showlegend: false,
            showscale: false,
            marker: {
              showscale: false,
              showlegend: false,
            },
            line: {
              color: 'rgb(0,0,0)',
              width: 5,
            },
            type: 'scatter3d',
            hoverinfo: 'skip',
          }))
          .concat(
            minLines.map((e) => ({
              ...e,
              mode: 'markers',
              showlegend: false,
              showscale: false,
              marker: {
                showscale: false,
                showlegend: false,
                size: 5,
                symbol: ['', 'diamond'],
                color: 'rgb(0,0,0)',
              },
              type: 'scatter3d',
              hoverinfo: 'skip',
            }))
          )
      : []
  );
  const data = React.useMemo(() => {
    const sphereMesh = [
      {
        x,
        y,
        z,
        intensity: life,
        text: life,
        colorbar: {
          title: 'log10(Life)',
          // exponentformat: 'e',
        },
        showlegend: false,
        hovertemplate: `
          x:%{x} <br>
          y:%{y} <br>
          z:%{z} <br>
          log10(life):%{text}
          <extra></extra>
        `,
        showscale: interactive,
        type: 'mesh3d',
        colorscale: [
          [0, 'rgb(255, 0, 0)'],
          [0.5, 'rgb(0, 255, 0)'],
          [1, 'rgb(0, 0, 255)'],
        ],
      },
      {
        x: x2,
        y: y2,
        z: z2,
        intensity: life,
        text: life,
        colorbar: {
          // exponentformat: 'e',
        },
        hovertemplate: `
          x:%{x} <br>
          y:%{y} <br>
          z:%{z} <br>
          log10(life):%{text}
          <extra></extra>
        `,
        type: 'mesh3d',
        showlegend: false,
        showscale: false,
        colorscale: [
          [0, 'rgb(255, 0, 0)'],
          [0.5, 'rgb(0, 255, 0)'],
          [1, 'rgb(0, 0, 255)'],
        ],
      },
    ];
    return arrowMesh.concat(crackMesh).concat(sphereMesh);
  }, [arrowMesh, crackMesh]);
  return {
    checkboxFields,
    data,
  };
};

const DamageSphere = (props) => {
  const { width, interactive, disabled } = props;
  const { data, checkboxFields } = DamageSphereElements(props);
  if (disabled) {
    return <StaticImage src="../../assets/images/damagesphere_pro.png" alt="You do not have permission" />;
  }

  return (
    <>
      <ReactPlotly
        data={data}
        config={{ displayModeBar: false, displaylogo: false }}
        layout={{
          dragmode: interactive,
          width,
          height: width * 0.9,
          xaxis: {
            type: 'log',
            autorange: true,
          },
          yaxis: {
            type: 'log',
            autorange: true,
          },
        }}
      />
      {interactive && (
        <>
          {checkboxFields.map((field, i) => (
            <span key={i} data-test="damage-sphere-component-checkbox">
              <CheckboxField {...field} />
            </span>
          ))}
        </>
      )}
    </>
  );
};

export default DamageSphere;
