import React, { useState, useContext } from 'react';
import { Flex } from 'rebass';
import { Container, Row, Col, FormGroup, Input } from 'reactstrap';
import _ from 'lodash';
import convertMaterial from 'utils/converter';
import { MessageModal } from '../modals/Modals';
import { GlobalStateContext, defaultState } from '../../context/GlobalContextProvider';
import ContentInput from '../controls/ContentInput';
import Search from '../modals/Search';
import Save from '../modals/Save';
import Clear from '../controls/Clear';
import User from '../../utils/auth';
import { transformMaterial } from '../../utils/simulate';
import { checkMaterialUnit, transformRawMaterial } from '../../utils/materials';
import { listMaterials } from '../../graphql/queries';
import { createMaterial, updateMaterial, deleteMaterial } from '../../graphql/mutations';
import SYSTEM_MATERIALS from '../../utils/materials-system';
import { localStorageSet, storeMaterials } from '../../utils/local-storage';

const UNIT_LIST = ['SI-mm', 'SI-m', 'Imperial'];
const DEFAULT_MATERIAL = defaultState.materials2;
const defaultMsgValues = {
  visible: false,
  title: '',
  content: '',
  button: ['Close'],
};

export const DataField = ({ name, index, mat, setMat }) => {
  const keyName = `${name}-${index}`;
  return (
    <div data-test="data-field-component" className="input-list" key={keyName}>
      <Flex>
        <ContentInput
          data-test="data-field-component-content-input"
          name={name}
          id={keyName}
          index={index}
          getData={mat[name]}
          setData={(v) => {
            setMat({ ...mat, [name]: v });
          }}
        />
      </Flex>
    </div>
  );
};

export const SingleMaterialElements = ({ index }) => {
  const { selectedUnit, changeUnit, materialsData, setMaterialsData } = useContext(GlobalStateContext);
  const [msg, setMsg] = useState(defaultMsgValues);
  const showMessage = (title, content, button = ['Close'], actions = []) =>
    setMsg({ visible: true, title, content, button, actions });
  const closeMessage = () => setMsg({ visible: false, title: '', content: '' });
  const [sim, doSim] = useState(false);
  const permission = User.checkPermission('MATERIAL_LIB');
  const material = materialsData[index];

  const handleMismatch = (data, unit) => () => {
    const mat = convertMaterial(data, unit.toLowerCase());
    setMat(mat);
    closeMessage();
  };

  const properUnits = (u) => UNIT_LIST.find((e) => e.toLowerCase() === u.toLowerCase());

  const setMat = (mat) => {
    if (mat.units !== material.units) {
      changeUnit(properUnits(mat.units));
    }

    // Update global ref
    updateMaterialsData(mat);
  };

  const searchMenu = {
    sim,
    doSim,
    onSelectHandler,
    onQueryHandler,
    title: 'Material Search',
    permission,
    queries: {
      list: { listMaterials },
      delete: { deleteMaterial },
    },
  };

  const saveMenu = {
    onSaveHandler,
    permission,
    item: material,
    uniqueField: 'name',
    queries: {
      list: { listMaterials },
      create: { createMaterial },
      update: { updateMaterial },
    },
  };

  const clearMenu = {
    onClearHandler,
  };

  return {
    msg,
    closeMessage,
    sim,
    doSim,
    permission,
    mat: material,
    // TODO
    setMat,
    index,
    selectedUnit,
    searchMenu,
    saveMenu,
    clearMenu,
  };

  function updateMaterialsData(data) {
    setMaterialsData((prevMaterial) => {
      if (index === 0) {
        localStorageSet(`material-${index}`, data);
      }

      return prevMaterial.map((e, i) => {
        if (i === index) {
          return data;
        }
        return e;
      });
    });
  }

  // Save
  function onSaveHandler(item) {
    const isSystemMaterial = item.owner === 'system';

    if (isSystemMaterial) {
      throw new Error('System materials may not be modified');
    }

    return transformMaterial(item, selectedUnit);
  }

  // Search
  function onSelectHandler(data) {
    const matches = checkMaterialUnit(selectedUnit, data.units);
    const mat = transformRawMaterial(data);

    if (matches) {
      setMat(mat);
    } else {
      const newUnit = properUnits(data.units);
      const currentUnit = properUnits(selectedUnit);
      showMessage(
        'Unit Mismatch',
        `Selected material uses '${newUnit}' unit which differs from the current unit '${currentUnit}'. Please choose the unit to use:`,
        [newUnit, currentUnit],
        [handleMismatch(mat, newUnit), handleMismatch(mat, currentUnit)]
      );
    }
  }

  function onQueryHandler(data) {
    SYSTEM_MATERIALS.forEach((e) => (e.actions = { delete: false }));
    data.unshift(...SYSTEM_MATERIALS);

    return data;
  }

  // Clear
  function onClearHandler() {
    updateMaterialsData(DEFAULT_MATERIAL);
    storeMaterials(materialsData.map((e, i) => (i === index ? DEFAULT_MATERIAL : e)));
  }
};

export const SingleMaterial = (props) => {
  const { msg, closeMessage, mat, setMat, index, searchMenu, saveMenu, clearMenu } = SingleMaterialElements(props);

  return (
    <div data-test="single-material-component">
      <div className="btn-wrapper">
        <Container>
          <Row>
            <Col>
              <Search {...searchMenu} />
            </Col>
            <Col>
              <Save {...saveMenu} />
            </Col>
            <Col>
              <Clear data-test="single-material-component-clear" {...clearMenu} />
            </Col>
          </Row>
        </Container>
      </div>

      <div className="input-list">
        <FormGroup>
          <Input
            data-test="single-material-component-input"
            type="text"
            name="materialName"
            id={`materialName-${index}`}
            value={mat.name}
            onChange={(e) => {
              mat.owner = null;
              setMat({ ...mat, name: e.currentTarget.value });
            }}
          />
        </FormGroup>
      </div>

      <DataField name="refTemp" index={index} mat={mat} setMat={setMat} />
      <DataField name="youngsModulus" index={index} mat={mat} setMat={setMat} />
      <DataField name="criticalTearingEnergy" index={index} mat={mat} setMat={setMat} />
      <DataField name="intrinsicStrength" index={index} mat={mat} setMat={setMat} />
      <DataField name="precursorSize" index={index} mat={mat} setMat={setMat} />
      <DataField name="fcgRateLawSlope" index={index} mat={mat} setMat={setMat} />
      <DataField name="fcgRateLawConstant" index={index} mat={mat} setMat={setMat} />
      <DataField name="crystallizationStrength" index={index} mat={mat} setMat={setMat} />

      <MessageModal
        title={msg.title}
        modal={msg.visible}
        setModal={closeMessage}
        buttons={msg.button}
        color={msg.class}
        actions={msg.actions}
      >
        {msg.content}
      </MessageModal>
    </div>
  );
};

export default SingleMaterial;
