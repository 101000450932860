import React from 'react';

import Layout from '../layout/Layout';
import CardHeading from '../layout/CardHeading';
import Plans from '../forms/Plans';

const heading = {
  title: 'Change Your Plan',
};

const ChangePlan = () => (
  <Layout data-test="change-your-plan-component">
    <div className="choose-plan">
      <CardHeading heading={heading} />
      <Plans action="update-subscription" />
    </div>
  </Layout>
);

export default ChangePlan;
