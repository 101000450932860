const unitMeasurements = {
  specs_reftemp: { siM: 'degC', siMM: 'degC', imperial: 'degF' },
  specs_e: { siM: 'Pa', siMM: 'MPa', imperial: 'psi' },
  specs_tc: {
    siM: 'J/m<sup>2</sup>',
    siMM: 'kJ/m<sup>2</sup>',
    imperial: 'inlbf/in<sup>2</sup>',
  },
  specs_tg: {
    siM: 'J/m<sup>2</sup>',
    siMM: 'kJ/m<sup>2</sup>',
    imperial: 'inlbf/in<sup>2</sup>',
  },
  specs_cp: { siM: 'm', siMM: 'mm', imperial: 'in' },
  specs_f: { siM: '', siMM: '', imperial: '' },
  specs_rc: { siM: 'm/cyc', siMM: 'mm/cyc', imperial: 'in/cyc' },
  specs_crys: { siM: '', siMM: '', imperial: '' },
  option_size_eol: { siM: 'm', siMM: 'mm', imperial: 'in' },
  option_bulk_modulus: { siM: 'Pa', siMM: 'MPa', imperial: 'psi' },
};

export default unitMeasurements;
