import React, { useState, useEffect } from "react";
import { navigate } from "gatsby";
import LoadingSpinner from "../LoadingSpinner";
import User from "../../utils/auth";

const withAuth =
  ({ authChecker = User.isLoggedIn }) =>
  (WrappedComponent) => {
    function AuthProtected(props) {
      const [auth, setAuth] = useState(false);
      useEffect(() => {
        async function checkAuth() {
          const result = await authChecker();
          setAuth(result);
          if (!result) {
            navigate("/app/signin");
          }
        }
        checkAuth();
      }, []);
      return auth ? <WrappedComponent {...props} /> : <LoadingSpinner />;
    }
    return AuthProtected;
  };

export default withAuth;
