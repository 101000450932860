import { createPoints } from './damagesphere';

const COLORS = ['red', 'green', 'blue', 'orange'];

export const GRAPH_PARAMS = {
  // "Strain-Life Curve"
  // "Mode of Control Plot"

  // **** Group 0 ******

  // haigh : "Haigh Diagram"
  // calibration : "Precursor Size Calibration"
  // growth : "Fatigue Crack Growth Rate Curve"
  // stress-strain : "Stress-Strain Curve"
  'stress-strain': { group: 0, order: 0, aspectRatio: 1.0 },
  hydrostatic: {
    group: 0,
    order: 1,
    aspectRatio: 1.0,
    name: 'Hydrostatic Stress-Strain Curve',
  },
  calibration: { group: 0, order: 2 },
  growth: { group: 0, order: 3 },
  haigh: { group: 0, order: 4 },

  // **** Group 1 ******

  // ced : "Cracking Energy Density History
  // sed : "Strain Energy Density (SED) History"
  // ne : "Strain History Plot"
  // stress : "Stress History Plot
  ced: { group: 1 },
  sed: { group: 1 },
  ne: { group: 1 },
  stress: { group: 1 },

  // **** Group 3 ******

  // life : "Fatigue Life Results"
  // sphere : "Damage Sphere"
  life: { group: 2, combine: true, name: 'Life' },
  sphere: { group: 2 },
  'damage-sphere': {
    group: 2,
    name: 'Damage Sphere',
    transform: (item) => ({ ...item, datasets: createPoints(item.datasets) }),
  },
};
const graphResults = [{}, {}, {}];

export const postGraphs = (graphLabel, receivedGraphs, graphParams = null, defaultGraphResults = null, loads = []) => {
  graphParams ||= GRAPH_PARAMS;
  defaultGraphResults ||= graphResults;

  receivedGraphs.forEach((g) => {
    g.graphs.forEach((sg, i) => {
      const key = g.key === 'stress-strain' ? sg.use : g.key;
      if (g.key === 'calibration') {
        g.mode = loads[i]?.mode || '';
      }

      const params = graphParams[key];
      if (params) {
        // make sure we have a group
        let group = defaultGraphResults[params.group][key];
        if (!group) {
          group = {
            key,
            name: params.name || g.name,
            order: params.order,
            items: [],
          };
          defaultGraphResults[params.group][key] = group;
        }

        if (params.combine) {
          if (!g.loadCase) return;
          if (group.items.length === 0)
            group.items.push({
              type: 'bar',
              datasets: [],
              labels: [],
              aspectRatio: 1.0,
              simulationId: [],
              ...params,
            });
          const item = group.items[0];
          item.simulationId.push(g.simulationId);
          sg.datasets.forEach((ds) => {
            const lbl = `${g.material}/${g.loadCase}`;
            item.datasets.push({
              ...ds,
              color: COLORS[item.datasets.length],
              material: g.material,
              key: lbl,
            });
          });
        } else {
          if (g.key === 'calibration' && group.items.map((e) => e.material).includes(g.material)) {
            return;
          }
          group.items.push({
            ...sg,
            aspectRatio: 1.0,
            ...params,
            material: g.material,
            mat: g.mat,
            loadCase: g.loadCase,
            mode: g.mode,
            measurementUnits: g.measurementUnits,
            simulationId: [g.simulationId],
            ...(params.transform ? params.transform(sg) : {}),
          });
        }
        group.items.sort((a, b) =>
          a.material < b.material
            ? -1
            : a.material > b.material
            ? 1
            : a.loadCase < b.loadCase
            ? -1
            : a.loadCase > b.loadCase
            ? 1
            : a.use < b.use
            ? -1
            : a.use > b.use
            ? 1
            : 0
        );
      }
    });
  });
};
