import React, { useContext } from 'react';
import get from 'lodash/get';
import { Flex } from 'rebass';
import PlotlyGraph from './PlotlyGraph';
import ChartUtilsGraph from './ChartUtilsGraph';
import DamageSphere from './DamageSphere';
import StressStrainGraph from './StressStrainGraph';
import HydrostaticGraph from './HydrostaticGraph';
import CEDGraph from './CEDGraph';
import SedHistoryGraph from './SedHistoryGraph';
import PrincipleNEGraph from './PrincipleNEGraph';
import PrincipleStressGraph from './PrincipleStressGraph';
import FlawCalibrationGraph from './FlawCalibrationGraph';
import FatigueCrackRateGraph from './FatigueCrackRateGraph';
import HaighDiagramGraph from './HaighDiagramGraph';
import { GlobalStateContext } from '../../context/GlobalContextProvider';
import LifeBarGraph from './LifeBarGraph';
import PreviewModal from '../modals/PreviewModal';

export const makeGraph = (graph, graphKey, idx, width, height, interactive = false) => {
  if (isNaN(height) || isNaN(width)) {
    return '';
  }

  const props = { graph, graphKey, width, height, interactive, index: idx };

  switch (graph.use) {
    case 'stress-strain':
      return <StressStrainGraph {...props} />;
    case 'hydrostatic':
      return <HydrostaticGraph {...props} />;
    case 'ced':
      return <CEDGraph {...props} />;
    case 'sed':
      return <SedHistoryGraph {...props} />;
    case `ne - ${graph.historyNo}`:
      return <PrincipleNEGraph {...props} />;
    case `stress - ${graph.historyNo}`:
      return <PrincipleStressGraph {...props} />;
    case 'calibration':
      return <FlawCalibrationGraph {...props} />;
    case 'fcg-rate':
      return <FatigueCrackRateGraph {...props} />;
    case 'haigh':
      return <HaighDiagramGraph {...props} />;
    default:
  }

  switch (graph.type) {
    case 'line':
      return <ChartUtilsGraph {...props} />;

    case 'bar':
      return <LifeBarGraph {...props} />;

    case 'sphere':
      return <DamageSphere {...props} showArrow={false} />;

    case 'contour':
      return <PlotlyGraph {...props} key={idx} />;

    default:
      return '';
  }
};

export const ZoomGraphElements = ({ gr, graphKey, idx, title, onClickHandler = () => {} }) => {
  const { previews } = useContext(GlobalStateContext);
  const aspectRatio = gr.aspectRatio || 1.0;

  const height = gr.width * aspectRatio;
  const graphTag = `${graphKey}-${idx}`;
  return {
    gr,
    graphTag,
    aspectRatio,
    height,
    idx,
    title,
    hfo: gr.simulationId.map((e) => get(previews, `${e}.0.hfo`)).filter(Boolean),
    hfi: gr.simulationId.map((e) => get(previews, `${e}.0.hfi`)).filter(Boolean),
    hfm: gr.simulationId.map((e) => get(previews, `${e}.0.hfm`)).filter(Boolean),
    onClickHandler,
  };
};

const Graph = (props) => {
  const { gr, height, idx, graphTag, title, onClickHandler, hfo, hfi, hfm } = ZoomGraphElements(props);

  return (
    <div
      data-test="zoom-graph-component"
      className="p-0 mb-3 shadow"
      style={{
        display: 'inline-block',
        marginLeft: '2px',
        marginRight: '2px',
        outline: 'none',
        clear: 'both',
        width: `${gr.width}px`,
        height: `${height}px`,
      }}
      key={idx}
      role="button"
      tabIndex="-100"
    >
      <h3>{title}</h3>
      <div aria-hidden="true" onClick={() => onClickHandler(gr)}>
        {makeGraph(gr, graphTag, idx, gr.width, height - 20)}
        <Flex justifyContent="space-between">
          <PreviewModal key={`${graphTag}-hfo`} label="Show HFO" title="HFO" permission="SHOW_HFO" display={hfo} />
          <PreviewModal key={`${graphTag}-hfi`} label="Show HFi" title="HFi" permission="SHOW_HFI" display={hfi} />
          <PreviewModal key={`${graphTag}-hfm`} label="Show HFM" title="HFM" permission="SHOW_HFM" display={hfm} />
        </Flex>
      </div>
    </div>
  );
};

export default Graph;
