import Delaunator from 'delaunator';
import * as THREE from 'three';
import uniqWith from 'lodash/uniqWith';
import { Point, Triangle } from './geometry';

function getGeometryData(geometry) {
  const acc1 = geometry.attributes.position.array.reduce(
    (acc, el, i) => {
      switch (i % 3) {
        case 0:
          acc.x.push(el);
          break;
        case 1:
          acc.y.push(el);
          break;
        case 2:
          acc.z.push(el);
          break;
        default:
      }
      return acc;
    },
    { x: [], y: [], z: [] }
  );
  const acc2 = geometry.index.array.reduce(
    (acc, el, i) => {
      switch (i % 3) {
        case 0:
          acc.i.push(el);
          break;
        case 1:
          acc.j.push(el);
          break;
        case 2:
          acc.k.push(el);
          break;
        default:
      }
      return acc;
    },
    { i: [], j: [], k: [] }
  );
  return {
    ...acc1,
    ...acc2,
  };
}

const crackEquator = (point) => {
  const innerRadius = 1;
  const outerRadius = 1.01;
  const radiansStart = 0;
  const radiansEnd = 2 * Math.PI;
  const radius = innerRadius;

  const path = new THREE.Curve();
  path.getPoint = function (t) {
    const segment = (radiansStart - radiansEnd) * t;
    return new THREE.Vector3(radius * Math.cos(segment), radius * Math.sin(segment), 0);
  };
  const ptDir = new THREE.Vector3().fromArray(point);
  const geometry = new THREE.TubeGeometry(path, 256, outerRadius - innerRadius, 16, true);
  geometry.rotateY(ptDir.angleTo(new THREE.Vector3(0, 1, 0)));
  return getGeometryData(geometry);
};

const crackFace = (point) => {
  const ptDir = new THREE.Vector3().fromArray(point);
  const geometry = new THREE.CylinderGeometry(1.01, 1.01, 0.009, 64);
  let xRot = ptDir.angleTo(new THREE.Vector3(1, 0, 0));
  xRot = xRot > Math.PI / 2 ? xRot - Math.PI : xRot;
  geometry.rotateX(xRot);
  const zRot = ptDir.angleTo(new THREE.Vector3(0, 0, 1));
  geometry.rotateZ(zRot);
  const yRot = ptDir.angleTo(new THREE.Vector3(0, 1, 0));
  geometry.rotateY(yRot);
  return getGeometryData(geometry);
};

const createPoints = (ds) => {
  const points = ds.filter((e) => e.length === 5).map((p) => new Point({ x: p[1], y: p[2], z: p[3], life: p[4] }));
  const indexDelaunay = Delaunator.from(points.map((p) => [p.x, p.y]));
  const pIndex = [];
  indexDelaunay.triangles.forEach((triangle) => {
    pIndex.push(triangle);
  });
  const faces = pIndex
    .reduce((acc, p, i) => {
      const off = i % 3;
      const idx = (i - off) / 3;
      if (off === 0) acc.push([p]);
      else acc[idx].push(p);
      return acc;
    }, [])
    .map((face) => {
      const vertices = face.map((idx) => points[idx]);
      return new Triangle({ vertices });
    })
    .map((t) => t.split64());
  const x = [];
  const y = [];
  const z = [];
  const life = [];
  faces.forEach((triangles) => {
    triangles.forEach((triangle) => {
      triangle.vertices.forEach((point) => {
        x.push(point.x);
        y.push(point.y);
        z.push(point.z);
        life.push(point.life);
      });
    });
  });
  const x2 = x.map((e) => e * -1);
  const y2 = y.map((e) => e * -1);
  const z2 = z.map((e) => e * -1);
  const min = Math.min(...life);
  const minIndex = life.map((e, i) => (e === min ? i : null)).filter(Boolean);
  const minPoints = uniqWith(
    [...minIndex.map((e) => [x[e], y[e], z[e]]), ...minIndex.map((e) => [x2[e], y2[e], z2[e]])],
    (a, b) => a[0] === b[0] && a[1] === b[1] && a[2] === b[2]
  );
  const crackPoints = minPoints.map((point) => crackEquator(point));
  const minLines = minPoints.map((p) => ({
    x: [p[0], p[0] > 0 ? p[0] + 0.1 : p[0] - 0.1],
    y: [p[1], p[1]],
    z: [p[2], p[2]],
  }));
  const crackFaces = minPoints.map((point) => crackFace(point));
  return {
    x,
    y,
    z,
    x2,
    z2,
    y2,
    minIndex,
    life,
    crackPoints,
    minLines,
    crackFaces,
  };
};

export { createPoints };
