import React from 'react';
import { Flex } from 'rebass';
import { Link } from 'gatsby';
import { Form, Input, Label, FormGroup, Button } from 'reactstrap';

import { navigate } from '@reach/router';
import Layout from '../layout/Layout';
import CardHeading from '../layout/CardHeading';
import User from '../../utils/auth';
import { localStorageGet } from '../../utils/local-storage';
import LoggerUtils, { LOGTYPE } from '../../utils/logger';
import { MessageModal } from '../modals/Modals';

const heading = {
  title: 'Close Account',
  desc: 'We are sorry to see you go.',
};

class CloseAccount extends React.Component {
  constructor(props = {}) {
    super(props);

    this.state = {
      rememberPass: false,
      // local_user_id: localStorageGet('_userId'),
      sim: false,
      isLoading: false,
      modal_message: {
        class: 'warning',
        title: 'Close Account',
        message: (
          <div>
            Are you sure you want to close you're account?
            <br />
          </div>
        ),
        devMsg: '',
        buttons: ['Yes', 'Cancel'],
        actions: [async () => this.confirmSignout()],
      },
    };

    // test purposes
    this.setState = props.setState || this.setState;
    this.confirmSignout = props.confirmSignout || this.confirmSignout;
    this.onCancelSubscription = props.onCancelSubscription || this.onCancelSubscription;
  }

  /* eslint-disable */
  async confirmSignout() {
    this.setState({
      isLoading: true,
    });
    await this.onCancelSubscription();
  }
  /* eslint-enable */

  onCancelSubscription = async () => {
    try {
      this.setState({ isLoading: true });
      const userData = await User.getData();
      await User.closeAccount();
      LoggerUtils.log(LOGTYPE.INFO, `Close Account for ${userData}`);
    } catch (e) {
      LoggerUtils.error(e.message, e);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  closeAccount = async () => {
    this.toggle();
  };

  onRememberChanged = () =>
    this.setState({
      rememberPass: !this.state.rememberPass, // eslint-disable-line
    });

  toggle = () =>
    this.setState({
      sim: !this.state.sim, // eslint-disable-line
    });

  render() {
    return (
      <Layout data-test="close-account-component">
        <div className="close-account">
          <CardHeading heading={heading} />
          <Form>
            <FormGroup check className="form-checkbox">
              <Label check>
                <Input
                  data-test="close-account-component-checkbox"
                  type="checkbox"
                  onChange={this.onRememberChanged}
                  value={this.state.rememberPass}
                />{' '}
                <span className="checkmark" />I understand that clicking the button below will permanently close my
                account and that this action cannot be undone.
              </Label>
            </FormGroup>
            <Button
              data-test="close-account-component-confirm"
              color="danger"
              onClick={this.closeAccount}
              disabled={!this.state.rememberPass || this.state.isLoading}
              block
            >
              Confirm Cancel
            </Button>
            <Flex justifyContent="center">
              <Link to="/app/settings" className="text-center link-heading">
                Return to Settings
              </Link>
            </Flex>
          </Form>
        </div>
        <MessageModal
          title={this.state.modal_message.title}
          color={this.state.modal_message.class}
          modal={this.state.sim} // current status
          setModal={this.toggle} // action to change Boolean
          buttons={this.state.modal_message.buttons}
          actions={this.state.modal_message.actions}
        >
          <div className="text-center">
            {this.state.modal_message.message}
            <div style={{ width: '20px', height: '20px' }} />
          </div>
        </MessageModal>
      </Layout>
    );
  }
}

export default CloseAccount;
