import React, { useEffect } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { navigate } from '@reach/router';
import User from '../../utils/auth';

import Layout from '../layout/Layout';
import CardHeading from '../layout/CardHeading';

export const checkUserIfLoggedIn = async () => {
  const userIsLoggedIn = await User.isLoggedIn();
  if (!userIsLoggedIn) {
    navigate('/');
  }
};

const Trainings = () => {
  const data = useStaticQuery(graphql`
    query TrainingContent {
      wpPage(id: { eq: "cG9zdDo1OQ==" }) {
        title
        content
      }
    }
  `);

  const trainingData = data.wpPage;

  const heading = {
    title: `${trainingData.title}`,
    desc: `${trainingData.content}`,
  };

  useEffect(() => {
    checkUserIfLoggedIn();
  }, []);

  return (
    <Layout data-test="trainings-component">
      <div className="trainings">
        <CardHeading heading={heading} />
      </div>
    </Layout>
  );
};

export default Trainings;
