import _, { omit } from 'lodash';
import { v4 as uuid } from 'uuid';
import gql from 'graphql-tag';
import * as Promise from 'bluebird';
import * as yup from 'yup';
import { postGraphs } from './simulate-helper';
import User from './auth';
import { MATERIALS_SCHEMA, MATERIAL_FIELDS_DEFAULT, LOADINGS_SCHEMA, LOADINGS_FIELDS_DEFAULT } from '../lib/valdiators';
import { client } from '../apollo/client';
import { createSimulation, simulateHfi as simulateHfiMutation } from '../graphql/mutations';
import { numberArrayToStringArray, stringToNumber } from './materials';
import LoggerUtils from './logger';

const NUMBER_OPTIONS_KEY = [
  'optionEmin',
  'optionEmax',
  'optionVmax',
  'optionVmin',
  'optionTmax',
  'optionTmin',
  'optionRmax',
  'optionRmin',
  'strainampmin',
  'strainampmax',
  'meanstrainmin',
  'meanstrainmax',
  'bulkModulus',
  'sizeEOL',
];

export const canSimulate = (selectedUnit, materials, loadcases) => {
  /**
    materials and loadcases:
    { name: '', type: 'Material', data: {}, errors: [], warnings: [] }
  */
  let res = {
    ok: false,
    warn: false,
    materials: [],
    loadcases: [],
  };

  res = materials
    .filter((e) => {
      if (!e.name.trim()) {
        return false;
      }
      return true;
    })
    .reduce((a, m, idx) => {
      const rawMaterial = transformMaterial(m, selectedUnit);
      const material = checkMsgs({
        data: rawMaterial,
        optionalFields: ['crystal'],
        objectSchema: MATERIALS_SCHEMA,
        defaultValues: MATERIAL_FIELDS_DEFAULT,
      });

      material.name = rawMaterial.name;
      material.type = `Material ${idx + 1}`;
      _.set(a, `materials[${idx}]`, material);

      return a;
    }, res);

  res = loadcases
    .filter((e) => {
      if (!e.loadCase.trim()) {
        return false;
      }
      return true;
    })
    .reduce((a, l, idx) => {
      const rawLoading = transformLoading(l);
      const loading = checkMsgs({
        data: rawLoading,
        optionalFields: ['control'],
        objectSchema: LOADINGS_SCHEMA,
        defaultValues: LOADINGS_FIELDS_DEFAULT,
      });

      loading.name = rawLoading?.loadCase;
      loading.type = `Load Case ${idx + 1}`;
      _.set(a, `loadcases[${idx}]`, loading);

      return a;
    }, res);

  if (res.materials.length === 0) {
    throw new Error('At least one named material is required for simulation');
  }
  if (res.loadcases.length === 0) {
    res.loadcases[0] = {
      warnings: [],
      errors: ['Load Case name is required'],
      type: 'Load Case 1',
    };
  }

  res.warn = [...res.materials, ...res.loadcases].some((e) => e.errors.length || e.warnings.length);
  res.ok = materials.length && [...res.materials].some((e) => e.errors.length === 0);

  return res;
};

const checkMsgs = ({ data, objectSchema, defaultValues, optionalFields = [] }) => {
  const errors = [];
  const warnings = [];

  try {
    // On error - override invalid optional fields with default values and add optional warning
    optionalFields.forEach((field) => {
      try {
        yup.reach(objectSchema, field).validateSync(data[field], { abortEarly: false, context: { data } });
      } catch (err) {
        warnings.push(...err.errors);
        data[field] = defaultValues[field];
      }
    });

    objectSchema.validateSync(data, { abortEarly: false });
  } catch (err) {
    errors.push(...err.errors);
  }

  return { data, errors, warnings };
};

export const transformMaterial = (mat, selectedUnit) => ({
  name: mat.name?.trim() ?? '',
  units: selectedUnit?.toLowerCase() ?? '',
  temp: mat.refTemp.filter(Boolean),
  E: mat.youngsModulus.filter(Boolean),
  Tc: mat.criticalTearingEnergy.filter(Boolean),
  T0: mat.intrinsicStrength.filter(Boolean),
  C0: mat.precursorSize.filter(Boolean),
  F: mat.fcgRateLawSlope.filter(Boolean),
  rc: mat.fcgRateLawConstant.filter(Boolean),
  crystal: mat.crystallizationStrength.filter(Boolean),
});

export const transformLoading = (loading) => ({
  loadCase: loading.loadCase?.trim() ?? '',
  minStrain: loading.minNomEngStrain,
  control: loading.modeOfControl,
  mode: loading.modeOfDeformation,
  peakStrain: loading.peakNomEngStrain,
  temp: loading.temp,
});

export const checkLoadingsPermissions = (loadcase, selectedUnit) => {
  if (!User.checkPermission('TEMP_CTRL')) {
    loadcase.temp = selectedUnit === 'Imperial' ? '100' : '20';
  }

  // Mode of Deformation if user has no permission default to Simple Tension
  if (!User.checkPermission('DEFORM_PLANAR') && loadcase.modeOfDeformation === '1CNEPL') {
    loadcase.modeOfDeformation = '1CNESI';
  }

  if (!User.checkPermission('DEFORM_BIAX') && loadcase.modeOfDeformation === '1CNEEQ') {
    loadcase.modeOfDeformation = '1CNESI';
  }

  if (!User.checkPermission('DEFORM_SHEAR') && loadcase.modeOfDeformation === '1CNESS') {
    loadcase.modeOfDeformation = '1CNESI';
  }

  return loadcase;
};

// Finalize loadcase to be sent
export const reduceLoadings = (loadcases, selectedUnit, defaultCheckLoadingsPermissions = null) => {
  defaultCheckLoadingsPermissions ||= checkLoadingsPermissions;

  // TODO modify backend to accept value from commented code
  const parseEngStrain = (value) => (value !== '' ? parseFloat(value) / 100 : 0);
  // TODO uncomment when changes were made on Endurica Cl
  // const parseEngStrain = (value) => _.toString(value !== '' ? parseFloat(value) / 100 : 0);

  loadcases = loadcases.reduce((a, loadcase) => {
    if (loadcase) {
      loadcase = defaultCheckLoadingsPermissions(loadcase, selectedUnit);
      loadcase.minStrain = parseEngStrain(loadcase.minStrain);
      loadcase.peakStrain = parseEngStrain(loadcase.peakStrain);
      loadcase.control = parseFloat(loadcase.control);

      // TODO uncomment when changes were made on Endurica Cl
      loadcase.temp = _.toNumber(loadcase.temp);

      a.push(loadcase);
    }
    return a;
  }, []);

  return loadcases;
};

export const checkOptionsPermissions = (options) => {
  if (!User.checkPermission('PLOT_PRE')) delete options.precursorSizeCalibration;
  if (!User.checkPermission('PLOT_DSPHERE')) delete options.damageSphere;
  if (!User.checkPermission('PLOT_HAIGH')) delete options.haighDiagram;
  options.cedHistory = User.checkPermission('PLOT_CED');
  return options;
};

export const disableGraphByPermission = (graphs) => {
  let items;

  // Materials Behavior
  if (!User.checkPermission('PLOT_HAIGH')) {
    items = _.get(graphs, '[0].haigh.items', []);
    items.forEach((i) => (i.disabled = true));
  }

  if (!User.checkPermission('PLOT_PRE')) {
    items = _.get(graphs, '[0].calibration.items', []);
    items.forEach((i) => (i.disabled = true));
  }

  // History Plots
  if (!User.checkPermission('PLOT_CED')) {
    items = _.get(graphs, '[1].ced.items', []);
    items.forEach((i) => (i.disabled = true));
  }

  // Life Results
  if (!User.checkPermission('PLOT_DSPHERE')) {
    items = _.get(graphs, '[2].damage-sphere.items', []);
    items.forEach((i) => (i.disabled = true));
  }

  return graphs;
};

export const calculateResult = async (
  selectedUnit,
  materials,
  loadcases,
  options = {},
  reduceLoadingsHandler = null,
  simulateLoadingHandler = null,
  simulateMaterialHandler = null
) => {
  reduceLoadingsHandler ||= reduceLoadings;
  simulateLoadingHandler ||= simulateLoading;
  simulateMaterialHandler ||= simulateMaterial;
  const previews = [];
  try {
    let graphResults = [{}, {}, {}];
    options = checkOptionsPermissions(options);
    const mats = materials;
    const loads = reduceLoadingsHandler(loadcases, selectedUnit);
    const pairs = _.flatMap(mats.map((mat) => loads.map((load) => [mat, load])));
    await Promise.mapSeries(mats, (mat, i) =>
      simulateMaterialHandler(mat, loads, {
        ...options,
        loadTemp: loads[i]?.temp,
      }).then((g) => {
        previews.push(g.preview);
        return postGraphs(
          `#M${mat.name}`,
          g.graphs.map((e) => ({ ...e, mat })),
          null,
          graphResults,
          loads
        );
      })
    );
    await Promise.mapSeries(pairs, async ([mat, load]) =>
      simulateLoadingHandler(mat, load, options).then((g) => {
        previews.push(g.preview);
        return postGraphs(
          `#L${load.id}-${mat.name}`,
          g.graphs.map((e) => ({ ...e, mat })),
          null,
          graphResults
        );
      })
    );
    graphResults = disableGraphByPermission(graphResults);
    const results = graphResults.map((r) => Object.values(r).sort((a, b) => a.order - b.order));
    return { graphs: results, previews: _.groupBy(previews, 'simulationId') };
  } catch (e) {
    LoggerUtils.error(e.message, e);
    throw e;
  }
};

export const simulateMaterial = async (material, loadcases, options) => {
  const req = {
    label: `EnduricaCompanion-${material.name}`.replace(/\s/g, '-'),
    materials: [{ ...material, name: 'MAT', crystal: material.crystal }].map(numberArrayToStringArray),
    loadcases: loadcases.map((e, index) => ({ ...e, id: index })),
    options: {
      ...stringToNumber(options, NUMBER_OPTIONS_KEY),
      ..._.pick(options, 'showHfo', 'showHfi', 'showHfm'),
      temp: _.toNumber(material.temp[0]),
      verifyMaterials: true,
      stressStrain: true,
      damageSphere: false,
      calculateHistory: false,
      calculateLife: false,
      loadTemp: options.loadTemp,
    },
    measurementUnits: material.units,
  };
  const simulationId = uuid();
  const result = await client.mutate({
    mutation: gql(createSimulation),
    variables: {
      input: req,
    },
  });
  const { graphs, hfo, hfi, hfm } = result.data.createSimulation;
  return {
    preview: {
      hfo,
      hfi,
      hfm,
      simulationId,
    },
    graphs: JSON.parse(graphs).map((m) => ({
      ...m,
      material: material.name,
      measurementUnits: material.units,
      simulationId,
    })),
  };
};

export const simulateLoading = async (material, loadcases, options) => {
  const req = {
    label: `EnduricaCompanion-${material.name}-${loadcases.id}`.replace(/\s/g, '-'),
    materials: [{ ...material, name: 'MAT' }].map(numberArrayToStringArray),
    loadcases: [{ ...loadcases, id: 10 }],
    options: {
      ...stringToNumber(options, NUMBER_OPTIONS_KEY),
      verifyMaterials: false,
      calculateHistory: true,
      calculateLife: true,
      ..._.pick(options, 'showHfo', 'showHfi', 'showHfm'),
      temp: loadcases.temp,
      fcgr: false,
      precursorSizeCalibration: false,
    },
    measurementUnits: material.units,
  };
  const simulationId = uuid();
  const result = await client.mutate({
    mutation: gql(createSimulation),
    variables: {
      input: req,
    },
  });

  const { graphs, hfi, hfo, hfm } = result.data.createSimulation;
  return {
    preview: {
      simulationId,
      hfi,
      hfo,
      hfm,
    },
    graphs: JSON.parse(graphs).map((m) => ({
      ...m,
      simulationId,
      material: material.name,
      loadCase: loadcases.loadCase,
      mode: loadcases.mode,
      measurementUnits: material.units,
    })),
  };
};

export const simulateHfi = async (hfi) => {
  try {
    const apiResponse = await client.mutate({
      mutation: gql(simulateHfiMutation),
      variables: {
        hfi,
      },
    });
    const { materials, loadings, options } = JSON.parse(apiResponse.data.simulateHfi.parsed);
    const loads = loadings.map((e) => omit(e, 'material'));
    let graphResults = [{}, {}, {}];
    const mats = materials;
    const pairs = _.flatMap(mats.map((mat) => loads.map((load) => [mat, load])));
    await Promise.mapSeries(mats, (mat, i) =>
      simulateMaterial(mat, loads, {
        ...options,
        loadTemp: loads[i]?.temp,
      }).then((g) =>
        postGraphs(
          `#M${mat.name}`,
          g.graphs.map((e) => ({ ...e, mat })),
          null,
          graphResults,
          loads
        )
      )
    );
    await Promise.mapSeries(pairs, async ([mat, load]) =>
      simulateLoading(mat, load, options).then((g) =>
        postGraphs(
          `#L${load.id}-${mat.name}`,
          g.graphs.map((e) => ({ ...e, mat })),
          null,
          graphResults
        )
      )
    );
    graphResults = disableGraphByPermission(graphResults);
    const results = graphResults.map((r) => Object.values(r).sort((a, b) => a.order - b.order));
    return results;
  } catch (e) {
    LoggerUtils.error(e.message, e);
    throw e;
  }
};

export const parseMode = (hfi = '') => {
  let [mode] = hfi.match(/BULK_MODULUS=(.* MPa|.* GPa|.* psi)/g) ?? [];
  if (mode) {
    mode = mode.toLowerCase();
    if (mode.includes('mpa')) {
      return 'si-mm';
    }
    if (mode.includes('gpa')) {
      return 'si-m';
    }
    return 'imperial';
  }
  return null;
};

export const generateHfi = async (selectedUnit, materials, loadcases, options) => {
  const loads = reduceLoadings(loadcases, selectedUnit);
  const body = {
    label: 'EnduricaCompanion Generate HFi',
    materials: materials.map(numberArrayToStringArray),
    loadcases: loads.map((e) => ({ ...e, id: 10 })),
    options: {
      ...stringToNumber(options, NUMBER_OPTIONS_KEY),
      onlyHfi: true,
      verifyMaterials: true,
      calculateHistory: true,
      calculateLife: true,
      criticalPlaneSearch: true,
      temp: _.toNumber(materials[0].temp[0]),
    },
    measurementUnits: selectedUnit,
  };
  const response = await client.mutate({
    mutation: gql(createSimulation),
    variables: {
      input: body,
    },
  });
  return response.data.createSimulation;
};
