import React, { useState } from 'react';
import { Button } from 'reactstrap';

function PasteClipboardElements(props) {
  const { text } = props;
  const [isCopied, setIsCopied] = useState(false);
  return {
    isCopied,
    onCopy,
  };

  async function onCopy() {
    await navigator.clipboard.writeText(text);
    setIsCopied(true);
  }
}

function PasteClipboard(props) {
  const { isCopied, onCopy } = PasteClipboardElements(props);
  let display = 'Copy';
  if (isCopied) {
    display = 'Copied to clipboard!';
  }
  return (
    <Button disabled={isCopied} className="link-button" color="primary" onClick={onCopy}>
      {display}
    </Button>
  );
}

export default PasteClipboard;
