import React, { useEffect } from 'react';
import Chart from 'chart.js';
import Color from 'chartjs-color';
import { format } from 'd3-format';

/*
const chartColors = {
  red: '#ff6384',
  orange: '#ff9f40',
  yellow: '#ffcd56',
  green: '#4bc0c0',
  blue: '#36a200',
  purple: '#9966ff',
  grey: '#c9cbcf'
};
*/

const COLORS = [
  '#ff6384',
  '#36a200',
  '#4bc0c0',
  '#4dc9f6',
  '#f67019',
  '#f53794',
  '#537bc4',
  '#acc236',
  '#166a8f',
  '#00a950',
  '#58595b',
  '#8549ba',
];

const UNITS = {
  'si-mm': {
    pressure: 'MPa',
    energy: 'kJ/m^2',
    growthRate: 'mm/cyc',
    energyDensity: 'mJ/mm^3',
    size: 'mm',
  },
  'si-m': {
    pressure: 'Pa',
    energy: 'J/m^2',
    growthRate: 'm/cyc',
    energyDensity: 'J/m^3',
    size: 'm',
  },
  imperial: {
    pressure: 'psi',
    energy: 'in*lbf/in^2',
    growthRate: 'in/cyc',
    energyDensity: 'inlbf / in^3',
    size: 'in',
  },
};

const TENSION = {
  ST: 'Simple Tension',
  PT: 'Planar Tension',
  EB: 'Equibiaxial Tension',
};

const makeScale = (fmt, interact = true, stacked = true) => ({
  stacked,
  ticks: {
    callback: (value /* , index, values */) => (interact ? format(fmt)(value) : ''),
  },
});

export function scaleLog10({ fmt = '.3e', interactive = true }) {
  return {
    ticks: {
      callback: (value) => (interactive ? format(fmt)(Math.log10(Number(value))) : ' '),
    },
  };
}

const transparentize = (color, opacity) =>
  Color(color)
    .alpha(opacity === undefined ? 0.5 : 1 - opacity)
    .rgbString();

const defaultConfig = (type, height, width, interactive) => ({
  type,
  height,
  width,
  maintainAspectRatio: false,
  options: {
    legend: { display: interactive },
    animation: false,
    hover: { animationDuration: 0 },
    responsiveAnimationDuration: 0,
  },
});

const mapDataset = (e, i) => {
  const pntStyle = e.pointStyle ? e.pointStyle : 'line';
  const pntSize = Array.isArray(pntStyle) ? 5 : 1;
  const color = /* e.color || */ COLORS[i];
  const d = {
    backgroundColor: transparentize(color),
    borderColor: transparentize(color),
    data: e.values,
    label: e.key,
    fill: '',
    pointStyle: pntStyle,
    pointRadius: pntSize,
    pointBackgroundColor: e.pointBackgroundColor || '#0000ff',
  };
  return d;
};

const ChartJSGraph = ({ options, canvasKey }) => {
  useEffect(() => {
    new Chart(canvasKey, { ...options }); // eslint-disable-line
  }, [canvasKey, options]);

  return (
    <canvas
      id={canvasKey}
      className="chartjs-render-monitor"
      height={options.height}
      width={options.width}
      style={{ height: `${options.height}px`, width: `${options.width}px` }}
    />
  );
};

export function filterOutliers(arr) {
  if (Array.isArray(arr)) return calc(arr);

  let o = null;
  const k = 'string' == typeof arr && arr;

  return function (v, i, a) {
    if (!o) o = calc(a, k);
    v = k ? v[k] : v;
    return !~o.indexOf(v);
  };
}

/**
 * Calculate the outliers
 *
 * @param {Array} arr
 * @param {String} key (optional)
 * @return {Array} outliers
 */

function calc(arr, key) {
  arr = arr.slice(0);

  if (key)
    arr = arr.map(function (v) {
      return v[key];
    });

  arr = arr.sort(function (a, b) {
    return a - b;
  });

  const len = arr.length;
  const middle = median(arr);
  const range = iqr(arr);
  const outliers = [];

  for (let i = 0; i < len; i++) {
    Math.abs(arr[i] - middle) > range && outliers.push(arr[i]);
  }

  return outliers;
}

/**
 * Find the median
 *
 * @param {Array} arr
 * @return {Number}
 */

function median(arr) {
  const len = arr.length;
  const half = ~~(len / 2);

  return len % 2 ? arr[half] : (arr[half - 1] + arr[half]) / 2;
}

/**
 * Find the range
 *
 * @param {Array} arr
 * @return {Number}
 */

function iqr(arr) {
  const len = arr.length;
  const q1 = median(arr.slice(0, ~~(len / 2)));
  const q3 = median(arr.slice(Math.ceil(len / 2)));
  const g = 1.5;

  return (q3 - q1) * g;
}

export default ChartJSGraph;

export { COLORS, UNITS, TENSION, makeScale, transparentize, defaultConfig, mapDataset };
