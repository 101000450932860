import { format } from 'd3-format';
import React from 'react';
import ChartJSGraph, { defaultConfig, scaleLog10 } from './ChartJSGraph';

const mapDataset = (e) => {
  const { color } = e;
  const d = {
    backgroundColor: color,
    borderColor: color,
    data: e.values,
    label: e.key,
    fill: '',
    pointStyle: 'line',
    pointRadius: 1,
    pointBackgroundColor: e.pointBackgroundColor || '#0000ff',
  };
  return d;
};

const LifeBarGraph = ({ index, graphKey, graph, height, width, interactive = false }) => {
  const config = defaultConfig('bar', height, width, interactive);
  config.data = {
    datasets: graph.datasets.map((e) => mapDataset(e)),
    labels: [''],
  };
  config.options.tooltips = {
    callbacks: {
      label(context) {
        const data = graph.datasets[context.datasetIndex];
        return `${data.key}: ${format('.3e')(context.value)}`;
      },
    },
  };

  config.options.scales = {
    yAxes: [
      {
        type: 'logarithmic',
        scaleLabel: {
          display: interactive,
          labelString: 'Fatigue Life',
        },
      },
    ],
  };
  return <ChartJSGraph options={config} canvasKey={graphKey} data-test="life-bar-graph-component" />;
};

export default LifeBarGraph;
