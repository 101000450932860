import React from 'react';
import PlotlyGraph from './PlotlyGraph';

const HaighDiagramGraph = ({ index, graphKey, graph, height, width, interactive = false }) => {
  const layout = {
    xaxis: {
      title: 'Mean strain',
      visible: interactive,
      showticklabels: interactive,
    },
    yaxis: {
      title: 'Strain amplitude',
      visible: interactive,
      showticklabels: interactive,
    },
    margin: interactive
      ? 0
      : {
          l: 10,
          r: 4,
          t: 4,
          b: 10,
        },
    colorbarTitle: 'cycles',
  };

  return (
    <PlotlyGraph
      graph={graph}
      index={index}
      graphKey={graphKey}
      key={index}
      width={width}
      height={height}
      interactive={interactive}
      layout={layout}
      data-test="haigh-diagram-graph-component"
    />
  );
};

export default HaighDiagramGraph;
