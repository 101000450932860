import React, { useEffect } from 'react';
import { Router } from '@reach/router';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import appConfig from '../appConfig';

import ModalContainer from '../components/modals/ModalContainer';
import NotFoundPage from './404';

import GlobalContextProvider from '../context/GlobalContextProvider';
import LogContextProvider from '../context/LogContextProvider';
import PrivateRoute from '../components/nav/PrivateRoute';

import ChangeYourPlan from '../components/pages/change-your-plan';
import CloseAccount from '../components/pages/close-account';
import Settings from '../components/pages/settings';
import SimulationResults from '../components/pages/results';
import SpecificationMaterials from '../components/pages/materials';
import Support from '../components/pages/support';
import Training from '../components/pages/trainings';
import ContactUs from '../components/pages/contact-us';
import AboutUs from '../components/pages/about-us';
import TermsAndCondition from '../components/pages/terms';
import SignIn from '../components/pages/signin';
import Signout from '../components/pages/signout';

Sentry.init({
  dsn: appConfig.sentryKey,
  integrations: [new Integrations.BrowserTracing()],
  environment: appConfig.env,
  tracesSampleRate: 0.75,
});

Sentry.setTag('build', appConfig.buildId);

const App = () => {
  return (
    <GlobalContextProvider data-test="app-component">
      <LogContextProvider>
        <Router>
          <SignIn path="app/signin" />
          <ContactUs path="app/contact-us" />
          <TermsAndCondition path="app/terms" />

          <PrivateRoute path="app/change-your-plan" component={ChangeYourPlan} />
          <PrivateRoute path="app/close-account" component={CloseAccount} />
          <PrivateRoute path="app/settings" component={Settings} />
          <PrivateRoute path="app/support" component={Support} />
          <PrivateRoute path="app/training" component={Training} />
          <PrivateRoute path="app/sign-out" component={Signout} />
          <PrivateRoute path="app/about-us" component={AboutUs} />

          <PrivateRoute path="app/specs" component={SpecificationMaterials} />
          <PrivateRoute path="app/specs/:tab" component={SpecificationMaterials} />

          <PrivateRoute path="app/results/:tab" component={SimulationResults} />
          <PrivateRoute path="app/results/:tab/:key" component={SimulationResults} />
          <PrivateRoute path="app/results/:tab/:key/:material" component={SimulationResults} />
          <PrivateRoute path="app/results/:tab/:key/:material/:loadcase" component={SimulationResults} />
          <NotFoundPage default />
        </Router>
        <ModalContainer />
      </LogContextProvider>
    </GlobalContextProvider>
  );
};

export default App;
