import React from 'react';
import { Button } from 'reactstrap';
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import _ from 'lodash';
import { createBillingPortal } from '../../graphql/mutations';
import User from '../../utils/auth';

const CREATE_BILLING_PORTAL = gql(createBillingPortal);

const ManageSubscriptionButton = ({ text = 'Manage Subscription' }) => {
  const [mutateCreateBillingPortal, { loading }] = useMutation(CREATE_BILLING_PORTAL);

  const redirectToBilling = async () => {
    const input = {
      returnUrl: window.location.href,
    };
    const response = await mutateCreateBillingPortal({ variables: { input } });
    const session = _.get(response, 'data.createBillingPortal.session', '{}');
    const { url } = JSON.parse(session);
    window.location.href = url;
  };

  return (
    <Button
      data-test="manage-subscription-button-component"
      onClick={redirectToBilling}
      className="link-button"
      color="primary"
      disabled={loading}
    >
      {!loading ? text : 'Please wait...'}
    </Button>
  );
};

export default ManageSubscriptionButton;
