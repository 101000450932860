import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import { Button, Row } from 'reactstrap';
import { Flex } from 'rebass';
import _ from 'lodash';
import moment from 'moment';
import LoadingSpinner from '../LoadingSpinner';

import Layout from '../layout/Layout';
import CardHeading from '../layout/CardHeading';

import User from '../../utils/auth';
import { MessageModal } from '../modals/Modals';
import ManageSubscriptionButton from '../controls/ManageSubscriptionButton';

const DEFAULT_MSG = {
  visible: false,
  title: '',
  content: '',
  button: ['Close'],
  color: '',
};

const DEFAULT_PLAN = {
  email: '',
  planName: '',
  price: '',
  currentPrice: '',
  endDate: '',
  startDate: '',
};

const SettingsElements = ({ defaultMsg = DEFAULT_MSG, defaultPlan = DEFAULT_PLAN }) => {
  const [msg, setMsg] = useState(defaultMsg);
  const [plan, setPlan] = useState(defaultPlan);
  const [isLoading, setIsLoading] = useState(true);

  const showStatus = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const status = urlParams.get('status');

    if (status === 'success') {
      setMsg({
        visible: true,
        title: 'Success',
        content: <div>Congratulations! You can now enjoy the pro features.</div>,
      });
    } else if (status === 'cancel') {
      setMsg({
        visible: true,
        title: 'Canceled',
        content: <div>Transaction cancelled</div>,
      });
    } else if (status === 'downgrade') {
      setMsg({
        visible: true,
        title: 'Success',
        content: (
          <div>Subscription successfully cancelled! Pro features will inaccessible once subscription expires.</div>
        ),
      });
    }
  };

  const Init = async () => {
    showStatus();
    // remove query string on load
    const newUrl = window.location.href.split('?')[0];
    window.history.pushState({ path: newUrl }, '', newUrl);
    const user = await User.getData(true);
    const { email } = user;
    const planName = _.get(user, 'planData.name', 'Free');
    const price = _.get(user, 'subscriptionData.plan.amount', null);
    const currentPrice = price ? price / 100 : 'N/A';
    const canceled = _.get(user, 'subscriptionData.cancel_at_period_end', false);
    let startDate = _.get(user, 'subscriptionData.current_period_start', null);
    let endDate = _.get(user, 'subscriptionData.current_period_end', null);
    startDate = startDate && moment.unix(startDate).format('MMM DD, YYYY');
    endDate = endDate && moment.unix(endDate).format('MMM DD, YYYY');

    const plan = {
      email,
      planName,
      price,
      currentPrice,
      canceled,
      endDate,
      startDate,
    };
    setPlan(plan);
    setIsLoading(false);
  };

  const closeMessage = () =>
    setMsg({
      visible: false,
      title: '',
      content: '',
    });

  useEffect(() => {
    Init();
  }, []);

  return {
    isLoading,
    plan,
    msg,
    closeMessage,
    Init,
  };
};

const Settings = (props) => {
  const { isLoading, plan, msg, closeMessage } = SettingsElements(props);
  const { planName, email, canceled, endDate, startDate, currentPrice } = plan;
  const isPlanFree = planName === 'Free';

  return (
    <Layout data-test="settings-component">
      <div className="settings">
        <CardHeading heading="Settings" />

        <div className="content-holder">
          <p className="t-text-bold">You are currently signed in as:</p>
          <div className="shadow p-4 w-90 text-center">
            {isLoading ? (
              <LoadingSpinner />
            ) : (
              <>
                <a href="" className="link-email">
                  {email || ''}
                </a>
                <br />
                <Button color="primary" className="button-link link-btn">
                  <Link to="/app/sign-out">Sign out</Link>
                </Button>
                <Flex justifyContent="center">
                  <Link to="/recover-password" className="link-heading">
                    Reset Password
                  </Link>
                </Flex>
              </>
            )}
          </div>
        </div>

        <div className="summary-holder">
          <p className="t-text-bold">Your current subscription:</p>
          {endDate && (
            <div className="small text-center">
              <i>
                from {startDate} to {endDate}
              </i>
            </div>
          )}
          <div className="shadow p-4 w-90 text-center">
            {isLoading ? (
              <LoadingSpinner />
            ) : (
              <>
                <div className="heading-holder text-center">
                  <div className="t-heading m-auto">{planName}</div>
                </div>
                {!endDate ? (
                  ''
                ) : !canceled ? (
                  <>
                    <div className="text-primary invoice-holder small text-center">Renews {endDate} for</div>
                    <p className="t-price">
                      {currentPrice ? `$${currentPrice}` : <span className="font-italic text-muted small">&#36;0</span>}
                    </p>
                  </>
                ) : (
                  <div className="text-primary invoice-holder small text-center">Cancels at {endDate}</div>
                )}
                <Flex alignItems="center" flexDirection="column">
                  <Row lg={12}>
                    <Button color="primary" className="button-link link-btn">
                      <Link to="/app/change-your-plan">Change Plan</Link>
                    </Button>
                  </Row>

                  {!isPlanFree && (
                    <Row lg={12}>
                      <ManageSubscriptionButton />
                    </Row>
                  )}
                </Flex>
              </>
            )}
          </div>
        </div>

        <div className="content-holder">
          <Link to="/app/terms" className="link-heading">
            Terms of Service
          </Link>
          <br />
          <Button color="primary" className="button-link link-btn">
            <Link to="/app/close-account">Close my account</Link>
          </Button>
        </div>
      </div>
      <MessageModal
        title={msg.title}
        modal={msg.visible}
        setModal={closeMessage}
        buttons={msg.button}
        color={msg.color}
        actions={msg.actions}
      >
        {msg.content && (
          <div className="text-center">
            <>{msg.content}</>
          </div>
        )}
      </MessageModal>
    </Layout>
  );
};

export default Settings;
