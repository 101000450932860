import React, { useContext, useEffect } from 'react';
import { Form, Button } from 'reactstrap';
import { Flex } from 'rebass';
import CardHeading from '../layout/CardHeading';
import { GlobalStateContext } from '../../context/GlobalContextProvider';
import { getLastUsedOptions } from '../../utils/local-storage';
import OneCheckList from '../controls/OneCheckList';
import withAuth from '../nav/withAuth';
import HfiModal from '../modals/HfiModal';

const resetButtons = [
  {
    key: 'stressStrain',
    label: 'Stress-Strain',
  },
  {
    key: 'fcgr',
    label: 'FCGR Limits',
  },
  {
    key: 'haighDiagram',
    label: 'Haigh Diagram',
  },
];
const OptionsForm = ({ doSim }) => {
  const { options, setOptions, resetOptions } = useContext(GlobalStateContext);

  useEffect(() => {
    getLastUsedOptions(setOptions, options);
  }, [setOptions]);

  return (
    <Form data-test="options-form-component" className="specs-for non-btn">
      <Flex className="content-wrapper" justifyContent="space-between">
        <CardHeading heading="Options" />
        <div className="w-100">
          <OneCheckList options={options} setOptions={setOptions} />
        </div>
      </Flex>
      <Flex justifyContent="flex-end" className="w-100">
        {resetButtons
          .filter((e) => options[e.key])
          .map((e) => (
            <Button
              key={e.key}
              data-test={`options-form-component-button-reset-${e.key}`}
              className="btn-info"
              onClick={() => resetOptions(e.key)}
            >
              {`Reset ${e.label}`}
            </Button>
          ))}
        <Button data-test="options-form-component-button" className="btn-success" onClick={() => doSim(true)}>
          Calculate
        </Button>
        <HfiModal />
      </Flex>
    </Form>
  );
};

export { OptionsForm };
export default withAuth({})(OptionsForm);
