import React, { useContext, useEffect, useState } from 'react';
import {
  Container,
  Row,
  Col,
  FormGroup,
  Input,
  Media,
  Form,
  Button,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap';

import { Flex } from 'rebass';
import _ from 'lodash';
import { isNumber, isModeOfControl } from '../../lib/valdiators';
import RangeInput from '../controls/RangeInputField';
import { GlobalStateContext, defaultState } from '../../context/GlobalContextProvider';
import { getLastUsedLoadCase, storeLoadCase } from '../../utils/local-storage';
import GreyLabel from '../controls/GreyLabel';
import Search from '../modals/Search';
import Save from '../modals/Save';
import Clear from '../controls/Clear';
import User from '../../utils/auth';
import FieldProImg from '../../assets/images/field_pro.png';
import LoadingsProImg from '../../assets/images/loadings_pro.png';
import ModeOfDeformationOptions from './ModeOfDeformationOptions';
import { listLoadcases } from '../../graphql/queries';
import { createLoadcase, updateLoadcase, deleteLoadcase } from '../../graphql/mutations';
import withAuth from '../nav/withAuth';

export const ModeOfControlInput = ({ attr, get, set, disabled }) => {
  const [invalid, setInvalid] = useState(false);
  // For Display
  const [value, setValue] = useState(0);
  const modeOfControl = _.get(get, 'modeOfControl', null);

  const updateValue = (v) => {
    setValue(v);
    set(v, attr);
  };

  useEffect(() => {
    if (modeOfControl === null) return;
    // Override modeOfControl field when it is disabled.
    if (disabled) {
      updateValue('0');
    } else {
      updateValue(modeOfControl);
    }
  }, [disabled, modeOfControl]);

  const onChange = (event) => {
    const value = _.get(event, 'currentTarget.value', null);

    if (isModeOfControl(value)) {
      set(value, attr);
      setInvalid(false);
    } else {
      setInvalid(true);
    }

    setValue(value);
  };

  return (
    <Flex data-test="mode-input-component">
      <FormGroup>
        <InputGroup>
          <Input
            data-test="mode-input-component-input"
            className="specs"
            invalid={invalid}
            onChange={onChange}
            disabled={disabled}
            value={value}
          />
        </InputGroup>
      </FormGroup>
    </Flex>
  );
};

export const WrappedInput = ({ children, attr, idx, get, set, postLabel, type = 'text', ...props }) => {
  const { hidden, disabled, noPermission } = props;

  return (
    <Flex data-test="wrapped-input-component">
      <FormGroup>
        <InputGroup>
          <Input
            data-test="wrapped-input-component-input"
            className="specs"
            type={type}
            id={`${attr}-${idx}`}
            onChange={set(attr)}
            hidden={hidden}
            disabled={disabled || noPermission}
            value={get[attr]}
          >
            {children}
          </Input>

          {postLabel && (
            <InputGroupAddon data-test="wrapped-input-component-input-group" addonType="append">
              <InputGroupText>{postLabel}</InputGroupText>
            </InputGroupAddon>
          )}

          {noPermission && (
            <span className="icons">
              <Media
                data-test="wrapped-input-component-placeholder"
                object
                src={FieldProImg}
                alt="You do not have permission."
              />
            </span>
          )}
        </InputGroup>
      </FormGroup>
    </Flex>
  );
};

export const LoadColumnsElements = ({ index, isStrainCalcOn, setControl = () => {} }) => {
  const { defaultLoadingsRefTemp, loadingsData, setLoadingsData } = useContext(GlobalStateContext);
  const [sim, doSim] = useState(false);

  const setValue = (v, attr) => {
    const loadcase = { ...loadingsData[index], [attr]: v };
    updateGlobalLoadings(loadcase);
  };

  const setNumber = (attr) => (e) => {
    const v = e.currentTarget.value;

    if (isNumber(v)) {
      setValue(v, attr);
    }
  };

  const setText = (attr) => (e) => {
    const v = e.currentTarget.value;
    setValue(v, attr);
  };

  const searchMenu = {
    sim,
    doSim,
    title: 'Loading Search',
    queries: {
      list: { listLoadcases },
      delete: { deleteLoadcase },
    },
    onSelectHandler,
    onQueryHandler,
  };

  const saveMenu = {
    item: loadingsData[index],
    uniqueField: 'loadCase',
    queries: {
      list: { listLoadcases },
      create: { createLoadcase },
      update: { updateLoadcase },
    },
    onSaveHandler,
    permission: User.checkPermission('SAVE_LOAD'),
  };

  const clearMenu = {
    onClearHandler,
  };

  // Fields
  const loadCaseField = {
    attr: 'loadCase',
    idx: index,
    get: loadingsData[index],
    set: setText,
  };

  const modeOfDeformationField = {
    attr: 'modeOfDeformation',
    idx: index,
    get: loadingsData[index],
    set: setText,
    type: 'select',
  };

  const peakNomEngStrainField = {
    attr: 'peakNomEngStrain',
    idx: index,
    get: loadingsData[index],
    set: setNumber,
    postLabel: '%',
    disabled: index === 1 && isStrainCalcOn,
  };

  const minNomEngStrainField = {
    attr: 'minNomEngStrain',
    idx: index,
    get: loadingsData[index],
    set: setNumber,
    postLabel: '%',
    disabled: index === 1 && isStrainCalcOn,
  };

  const tempField = {
    attr: 'temp',
    idx: index,
    noPermission: !User.checkPermission('TEMP_CTRL'),
    get: loadingsData[index],
    set: setNumber,
  };

  const modeOfControlField = {
    attr: 'modeOfControl',
    set: setControl,
    get: loadingsData[index],
    disabled: !isStrainCalcOn,
  };

  return {
    index,
    searchMenu,
    saveMenu,
    clearMenu,
    loadCaseField,
    modeOfDeformationField,
    peakNomEngStrainField,
    minNomEngStrainField,
    tempField,
    modeOfControlField,
  };

  function updateGlobalLoadings(loadcase) {
    setLoadingsData((prevLoadcases) => {
      const loadcases = [...prevLoadcases];
      loadcases[index] = loadcase;

      // storeLoadCase(loadcases);
      return loadcases;
    });
  }

  function updateAllLoadings(loadcase) {
    // setLoadings(loadcase);
    updateGlobalLoadings(loadcase);
  }

  // Search
  function onSelectHandler(data) {
    updateAllLoadings(data);

    // Manually set of ModeOfControl
    setControl(data.modeOfControl);
  }

  function onQueryHandler(data) {
    return data.map((e) => ({ ...e, name: e.loadCase }));
  }

  // Save
  function onSaveHandler(item) {
    delete item.name;
    item = Object.entries(item).reduce((a, [key, value]) => ({ ...a, [key]: _.toString(value) }), {});

    return item;
  }

  // Clear
  function onClearHandler() {
    const loadcase = [...loadingsData];
    const newValue = {
      ...defaultState.loadings[index],
      loadCase: '',
      minNomEngStrain: '',
      peakNomEngStrain: '',
      temp: defaultLoadingsRefTemp,
    };
    loadcase[index] = newValue;
    storeLoadCase(loadcase);
    setLoadingsData(loadcase);
  }
};

export const LoadColumns = (props) => {
  const {
    index,
    searchMenu,
    saveMenu,
    clearMenu,
    loadCaseField,
    modeOfDeformationField,
    peakNomEngStrainField,
    minNomEngStrainField,
    tempField,
    modeOfControlField,
  } = LoadColumnsElements(props);

  return (
    <div data-test="load-columns-component">
      <div className="btn-wrapper">
        <Container>
          <Row>
            <Col>
              <Search {...searchMenu} />
            </Col>
            <Col>
              <Save {...saveMenu} />
            </Col>
            <Col>
              <Clear {...clearMenu} />
            </Col>
          </Row>
        </Container>
      </div>
      <div data-test="load-columns-component-input-list" className="input-list">
        <WrappedInput {...loadCaseField} />
        <WrappedInput {...modeOfDeformationField}>
          <ModeOfDeformationOptions />
        </WrappedInput>
        <WrappedInput {...peakNomEngStrainField} />
        <WrappedInput {...minNomEngStrainField} />
        <WrappedInput {...tempField} />

        {index === 0 ? (
          <div className="input-list">
            <ModeOfControlInput data-test="loading-form-component-input-1" {...modeOfControlField} />
          </div>
        ) : (
          <div>
            <div className="text-muted font-italic" style={{ height: '15px', fontSize: '8px' }}>
              <div className="float-left w-25">Stress</div>
              <div className="float-left w-50 text-center">Energy</div>
              <div className="float-right w-25 text-right">Strain</div>
            </div>
            <RangeInput data-test="loading-form-component-input-2" {...modeOfControlField} min={-1} max={1} />
          </div>
        )}
      </div>
    </div>
  );
};

export const LoadingsFormElements = ({ doSim }) => {
  const { loadingsData, setLoadingsData, setSelectedSpecType, materialsData } = useContext(GlobalStateContext);
  const [isStrainCalcOn, setIsStrainCalcOn] = useState(false);
  const [material1, material2] = materialsData;
  const loadColumns1 = {
    index: 0,
    isStrainCalcOn,
    setControl,
    defaultLoadings: getDefaultLoadings(0),
  };
  const loadColumns2 = {
    index: 1,
    isStrainCalcOn,
    setControl,
    defaultLoadings: getDefaultLoadings(1),
  };
  const loading2Permission = User.checkPermission('LOAD_2');
  let loadings = [loadColumns1, loadColumns2];
  if (!loading2Permission) {
    loadings = [loadings[0]];
  }

  // initialize default loadings
  useEffect(() => {
    const cachedLoadings = getLastUsedLoadCase();
    if (cachedLoadings.length) {
      setLoadingsData((prevLoadings) => {
        return prevLoadings.map((e, index) => {
          if (cachedLoadings[index]) {
            return cachedLoadings[index];
          }
          return e;
        });
      });
    }
  }, []);

  useEffect(() => {
    const validMaterialForm1 = material1 && material1 !== defaultState.materials2;
    const validMaterialForm2 = material2 && material1 !== defaultState.materials2;

    const E1 = _.get(material1, 'youngsModulus[0]', '');
    const E2 = _.get(material2, 'youngsModulus[0]', '');
    const hasValidYoungsModulus = [E1, E2].every(isValidYoungsModulus);

    const newLoadcases = [...loadingsData];
    // If Materials are valid for Strain Calculation supply empty Loadcases
    if (validMaterialForm1 && validMaterialForm2 && hasValidYoungsModulus && loading2Permission) {
      let refreshLoadingsData = false;

      if (!newLoadcases[0]) {
        newLoadcases[0] = { ...defaultState.loadings[0] };
        refreshLoadingsData = true;
      }

      if (!newLoadcases[1]) {
        newLoadcases[1] = { ...defaultState.loadings[1] };
        refreshLoadingsData = true;
      }

      if (refreshLoadingsData) {
        setLoadingsData(newLoadcases);
      }
    }

    const modeOfDeformation1 = _.get(newLoadcases, '[0].modeOfDeformation', null);
    const modeOfDeformation2 = _.get(newLoadcases, '[1].modeOfDeformation', null);
    const hasSameModeOfDeformation = modeOfDeformation1 === modeOfDeformation2;

    const strainCalcStatus = !!(
      validMaterialForm1 &&
      validMaterialForm2 &&
      hasSameModeOfDeformation &&
      hasValidYoungsModulus
    );

    setIsStrainCalcOn(strainCalcStatus);
  }, [loadingsData, material1, material2]);

  const peakNomEngStrain1 = _.get(loadingsData, '[0].peakNomEngStrain', null);
  const minNomEngStrain1 = _.get(loadingsData, '[0].minNomEngStrain', null);
  const modeOfControl1 = _.get(loadingsData, '[0].modeOfControl', null);
  const modeOfControl2 = _.get(loadingsData, '[1].modeOfControl', null);
  const youngsModulus1 = _.get(material1, 'youngsModulus[0]', '');
  const youngsModulus2 = _.get(material2, 'youngsModulus[0]', '');
  useEffect(() => {
    if (
      isStrainCalcOn &&
      peakNomEngStrain1 !== null &&
      minNomEngStrain1 !== null &&
      modeOfControl1 !== null &&
      modeOfControl2 !== null &&
      youngsModulus1 !== '' &&
      youngsModulus2 !== ''
    ) {
      const data = calcEmaxEmin([...loadingsData], youngsModulus1, youngsModulus2);
      setLoadingsData(data);
    }
  }, [
    peakNomEngStrain1,
    minNomEngStrain1,
    modeOfControl1,
    modeOfControl2,
    youngsModulus1,
    youngsModulus2,
    isStrainCalcOn,
  ]);

  const round = (a, d) => {
    const mult = Math.pow(10, d);
    return Math.round(a * mult) / mult;
  };

  const strainCalc = (eVal, E2, E1, X) => eVal * Math.pow(E2 / E1, (X - 1) / 2);

  const calcEmaxEmin = (rawLoadingsData, E1, E2) => {
    if (!isStrainCalcOn || !rawLoadingsData.length) return rawLoadingsData;

    rawLoadingsData[0] ||= defaultState.loadings[0];
    rawLoadingsData[1] ||= defaultState.loadings[1];

    const emax1 = rawLoadingsData[0].peakNomEngStrain;
    const emin1 = rawLoadingsData[0].minNomEngStrain;
    const defaultMode = parseFloat(rawLoadingsData[1].modeOfControl);
    let calculatedPeakNomEngStrain = '';
    let calculatedMinNomEngStrain = '';

    if ([E1, E2].every(isValidYoungsModulus) && [emax1, emin1].every(isValidNomEngStrain)) {
      calculatedPeakNomEngStrain = round(strainCalc(emax1, E2, E1, defaultMode), 3);
      calculatedMinNomEngStrain = round(strainCalc(emin1, E2, E1, defaultMode), 3);
    }

    rawLoadingsData[1].peakNomEngStrain = _.toString(calculatedPeakNomEngStrain);
    rawLoadingsData[1].minNomEngStrain = _.toString(calculatedMinNomEngStrain);

    return rawLoadingsData;
  };

  const btnClicked = () => {
    doSim(true);
    setSelectedSpecType('Loadings');
  };

  const buttonField = {
    className: 'btn-success',
    onClick: btnClicked,
  };

  return {
    loading2Permission,
    loadings,
    buttonField,
  };

  function setControl(v) {
    if (isModeOfControl(v)) {
      setLoadingsData((prevData) => prevData.map((l) => ({ ...l, modeOfControl: _.toString(v) })));
    }
  }

  function getDefaultLoadings(index) {
    const lastUsedLoadCase = getLastUsedLoadCase();
    const loadcase = _.get(lastUsedLoadCase, `[${index}]`, null);
    return loadcase || defaultState.loadings[index];
  }

  // Check if fields are valid for calculation.
  function isValidNomEngStrain(v) {
    const parsed = parseFloat(v);
    return v !== '' && Number.isFinite(parsed);
  }

  function isValidYoungsModulus(v) {
    const parsed = parseFloat(v);
    return parsed !== 0 && Number.isFinite(parsed);
  }
};

const LoadingsForm = (props) => {
  const { loading2Permission, loadings, buttonField } = LoadingsFormElements(props);

  return (
    <Form data-test="loading-form-component" className="specs-for has-btn">
      <Flex className="content-wrapper" justifyContent="space-between">
        <div className="content-holder">
          <GreyLabel slug="">Loading Case Name</GreyLabel>
          <GreyLabel slug="specs-mode">Mode of Deformation</GreyLabel>
          <GreyLabel slug="specs-peak">Peak Nom Eng Strain</GreyLabel>
          <GreyLabel slug="specs-min">Min Nom Eng Strain</GreyLabel>
          <GreyLabel slug="specs-reftemp">Reference Temperature</GreyLabel>
          <GreyLabel slug="specs-control">Mode of Control</GreyLabel>
        </div>

        {loadings.map((loading, i) => (
          <div key={i} className="content-holder">
            <LoadColumns data-test={`loading-form-component-load-columns-${i + 1}`} {...loading} />
          </div>
        ))}

        {loading2Permission ? (
          ''
        ) : (
          <div className="content-holder">
            <Media
              data-test="loading-form-component-placeholder"
              object
              src={LoadingsProImg}
              width="100%;"
              alt="You do not have permission."
            />
          </div>
        )}
      </Flex>
      <Flex justifyContent="flex-end" className="w-100 mt-5">
        <Button data-test="loading-form-component-button" {...buttonField}>
          Calculate
        </Button>
      </Flex>
    </Form>
  );
};

export { LoadingsForm };
export default withAuth({})(LoadingsForm);
