import React from 'react';
import ChartJSGraph, { UNITS, defaultConfig, mapDataset, scaleLog10 } from './ChartJSGraph';
import sortBy from 'lodash/sortBy';

const FatigueCrackRateGraph = ({ graphKey, graph, height, width, interactive = false }) => {
  const config = defaultConfig('line', height, width, interactive);
  config.data = {
    datasets: sortBy(
      graph.datasets.map((e, i) => mapDataset(e, i)),
      'label'
    ),
    labels: sortBy(graph.labels),
  };
  const scale = scaleLog10({ interactive });
  const yAxesScaleConfig = {
    type: 'logarithmic',
    ticks: {
      ...scale.ticks,
    },
    scaleLabel: {
      display: interactive,
      labelString: `log10(Fatigue crack growth rate ( ${UNITS[graph.measurementUnits].growthRate} ))`,
    },
  };
  const xAxesScaleConfig = {
    ticks: {
      ...scale.ticks,
    },
    scaleLabel: {
      display: interactive,
      labelString: `log10(Tearing Energy ( ${UNITS[graph.measurementUnits].energy} ))`,
    },
  };
  if (interactive) {
    config.options.scales = {
      xAxes: [xAxesScaleConfig],
      yAxes: [yAxesScaleConfig],
    };
  } else {
    config.options.events = [];
    config.options.scales = {
      xAxes: [xAxesScaleConfig],
      yAxes: [yAxesScaleConfig],
    };
  }

  return <ChartJSGraph options={config} canvasKey={graphKey} data-test="fatigue-crack-rate-graph-component" />;
};

export default FatigueCrackRateGraph;
