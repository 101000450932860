const SYSTEM_MATERIALS = [
  {
    name: 'NR gum',
    owner: 'system',
    units: 'si-mm',
    temp: ['20'],
    E: ['1.95'],
    Tc: ['10'],
    T0: ['0.04'],
    C0: ['0.025'],
    F: ['2'],
    rc: ['5.00E-03'],
    crystal: ['3.7']
  },
  {
    name: 'SBR gum',
    owner: 'system',
    units: 'si-mm',
    temp: ['20'],
    E: ['1.56'],
    Tc: ['2.5'],
    T0: ['0.06'],
    C0: ['0.055'],
    F: ['4'],
    rc: ['5.00E-02'],
    crystal: ['0']
  }
];

export default SYSTEM_MATERIALS;
