import React from 'react';
import { Flex } from 'rebass';
import { navigate } from 'gatsby';
import { Button } from 'reactstrap';

import Layout from '../layout/Layout';
import CardHeading from '../layout/CardHeading';
import User from '../../utils/auth';

const heading = {
  title: 'Sign Out',
  desc: 'Are you sure you want to sign out?',
};

const SignOut = () => {
  const handleSignOutBtn = async () => {
    await User.signout();
    navigate('/');
  };

  const handleCancelBtn = () => {
    navigate('/app/settings');
  };

  return (
    <Layout data-test="signout-component">
      <div className="sign-out">
        <CardHeading heading={heading} />
        <div className="button-holder">
          <Flex justifyContent="center">
            <Button
              data-test="signout-component-signoutbtn"
              color="primary"
              onClick={handleSignOutBtn}
              className="m-right-1"
            >
              Yes
            </Button>
            <Button data-test="signout-component-cancelbtn" color="danger" onClick={handleCancelBtn}>
              Cancel
            </Button>
          </Flex>
        </div>
      </div>
    </Layout>
  );
};

export default SignOut;
