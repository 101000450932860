import React, { useState, useEffect } from "react";
import { navigate } from "gatsby";
import User from "../../utils/auth";
import Permissions from "../../utils/permissions";

const noop = () => {};
export const checkUserIfLoggedIn = async (setAuth = noop) => {
  const userIsLoggedIn = await User.isLoggedIn();

  if (userIsLoggedIn) {
    await Permissions.Init();

    setAuth(userIsLoggedIn);
  } else {
    navigate("/app/signin");
  }
  return userIsLoggedIn;
};

const PrivateRoute = ({
  component: Component,
  location,
  authConf,
  ...rest
}) => {
  authConf = authConf || false;
  const [auth, setAuth] = useState(authConf);

  useEffect(() => {
    checkUserIfLoggedIn(setAuth);
  });

  return auth ? <Component {...rest} /> : <></>;
};

export default PrivateRoute;
