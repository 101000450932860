import React, { useContext, useEffect } from 'react';
import { Form, Button, Media } from 'reactstrap';
import { Flex } from 'rebass';
import _ from 'lodash';
import { GlobalStateContext } from '../../context/GlobalContextProvider';
import GreyLabel from '../controls/GreyLabel';
import SingleMaterial from './SingleMaterial';
import User from '../../utils/auth';
import PlaceholderImg from '../../assets/images/material_pro.png';
import withAuth from '../nav/withAuth';
import { getLastUsedMaterials } from '../../utils/local-storage';
import LoggerUtils from '../../utils/logger';

const UNIT_LIST = ['SI-mm', 'SI-m', 'Imperial'];

export const MaterialsFormElements = ({ changeUnit, doSim, unitList = null }) => {
  unitList ||= UNIT_LIST;

  const { selectedUnit, setMaterialsData, materialsData } = useContext(GlobalStateContext);

  // initialize default materials
  useEffect(() => {
    const cachedMaterials = getLastUsedMaterials();
    if (cachedMaterials.length) {
      setMaterialsData((prevMaterials) => {
        return prevMaterials.map((e, index) => {
          if (cachedMaterials[index]?.name) {
            return cachedMaterials[index];
          }
          return e;
        });
      });
    }
  }, []);

  const material1 = {
    index: 0,
  };
  const material2 = {
    index: 1,
  };
  const material2Permission = User.checkPermission('MATERIAL_2');
  let materials = [material1, material2];
  if (!material2Permission) {
    materials = [materials[0]];
  }

  const unitSelectedClass = (unit) =>
    (unit || '').toLowerCase() === (selectedUnit || '').toLowerCase() ? 'btn-info-selected' : 'btn-info';

  const handleCalculateClick = async () => {
    try {
      doSim(true);
      LoggerUtils.info('Simulation Generated');
    } catch (error) {
      LoggerUtils.error(error);
    }
  };

  return {
    changeUnit,
    doSim,
    unitList,
    unitSelectedClass,
    material2Permission,
    handleCalculateClick,
    selectedUnit,
    materials,
  };
};

const MaterialsForm = (props) => {
  const {
    changeUnit,
    unitList,
    unitSelectedClass,
    material2Permission,
    handleCalculateClick,
    selectedUnit,
    materials,
  } = MaterialsFormElements(props);

  return (
    <Form data-test="materials-form-component" className="specs-for has-btn">
      <Flex className="content-wrapper" justifyContent="space-between">
        <div className="content-holder">
          <GreyLabel slug="specs-material">Material Name</GreyLabel>
          <GreyLabel slug="specs-reftemp" selectedUnit={selectedUnit}>
            Reference Temp
          </GreyLabel>
          <GreyLabel slug="specs-e" selectedUnit={selectedUnit}>
            Young's Modulus <b>E</b>
          </GreyLabel>

          <GreyLabel slug="specs-tc" selectedUnit={selectedUnit}>
            <div
              style={{
                display: 'inline-block',
                float: 'left',
                margin: 0,
                padding: 0,
                height: '100%',
                clear: 'none',
                fontSize: '12px',
                lineHeight: '12px',
                textAlign: 'left',
              }}
            >
              Critical
              <br />
              Tearing
              <br />
              Energy
            </div>
            <div
              style={{ display: 'inline-block', height: '100%', clear: 'none', fontSize: '16px', padding: '8px 4px' }}
            >
              <b>
                T<sub>c</sub>
              </b>
            </div>
          </GreyLabel>

          <GreyLabel slug="specs-tg" selectedUnit={selectedUnit}>
            <div
              style={{
                display: 'inline-block',
                float: 'left',
                margin: 0,
                padding: '4px 0px',
                height: '100%',
                clear: 'none',
                fontSize: '12px',
                lineHeight: '12px',
                textAlign: 'left',
              }}
            >
              Intrinsic
              <br />
              Strength
            </div>
            <div
              style={{ display: 'inline-block', height: '100%', clear: 'none', fontSize: '16px', padding: '8px 0px' }}
            >
              <b>
                T<sub>0</sub>
              </b>
            </div>
          </GreyLabel>

          <GreyLabel slug="specs-cp" selectedUnit={selectedUnit}>
            Precursor Size{' '}
            <b>
              C<sub>0</sub>
            </b>
          </GreyLabel>

          <GreyLabel slug="specs-f">
            <div
              style={{
                display: 'inline-block',
                float: 'left',
                fontSize: '12px',
                lineHeight: '12px',
                textAlign: 'left',
                clear: 'none',
                width: '35px',
              }}
            >
              FCG Rate Law
            </div>
            <div style={{ display: 'inline-block', textAlign: 'center', width: '35px', clear: 'none' }}>
              Slope <b>F</b>
            </div>
          </GreyLabel>

          <GreyLabel slug="specs-rc" selectedUnit={selectedUnit}>
            <div
              style={{
                display: 'inline-block',
                float: 'left',
                fontSize: '12px',
                lineHeight: '12px',
                textAlign: 'left',
                clear: 'none',
                width: '35px',
              }}
            >
              FCG Rate Law
            </div>
            <div style={{ display: 'inline-block', textAlign: 'center', width: '30px', clear: 'none' }}>
              <span style={{ fontSize: '9px' }}>Constant</span>{' '}
              <b>
                r<sub>c</sub>
              </b>
            </div>
          </GreyLabel>

          <GreyLabel slug="specs-crys">
            <span style={{ fontSize: '12px' }}>Crystallization Strength</span>
          </GreyLabel>
        </div>

        {materials.map((material, i) => (
          <div key={i} className="content-holder">
            <SingleMaterial data-test={`materials-form-component-single-material-${i + 1}`} {...material} />
          </div>
        ))}

        {material2Permission ? (
          ''
        ) : (
          <div className="content-holder">
            <Media
              object
              data-test="materials-form-component-placeholder"
              src={PlaceholderImg}
              width="100%;"
              alt="You do not have permission."
            />
          </div>
        )}
      </Flex>
      <Flex justifyContent="space-between" alignContent="flex-start" flexWrap="wrap">
        <div className="action-btn-wrapper">
          {unitList.map((unit, m) => (
            <Button
              key={m}
              data-test={`materials-form-component-button-1-${m}`}
              className={unitSelectedClass(unit)}
              onClick={() => changeUnit(unit)}
            >
              {unit}
            </Button>
          ))}
          <Button
            data-test="materials-form-component-button-2"
            className="btn-success btn-calculate"
            onClick={handleCalculateClick}
          >
            Calculate
          </Button>
        </div>
      </Flex>
    </Form>
  );
};

export { MaterialsForm };
export default withAuth({})(MaterialsForm);
