import React from 'react';

const LoadingSpinner = () => (
  <>
    <h3>Please wait ...</h3>
    <div className="loading-spinner">
      <span className="sr-only">Loading...</span>
    </div>
  </>
);

export default LoadingSpinner;
