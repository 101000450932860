import React, { useEffect } from 'react';
import Chart from 'chart.js';
import Color from 'chartjs-color';
import { format } from 'd3-format';

const COLORS = ['#4dc9f6', '#f67019', '#f53794', '#537bc4', '#acc236', '#166a8f', '#00a950', '#58595b', '#8549ba'];

export const transparentize = (color, opacity) =>
  Color(color)
    .alpha(opacity === undefined ? 0.5 : 1 - opacity)
    .rgbString();

export const makeAxes = (type, fmt, zoom) => {
  if (type === 'log') {
    return [
      {
        type: 'logarithmic',
        ticks: {
          callback: (value) => (zoom ? format(fmt || '~e')(value) : ''),
        },
      },
    ];
  }

  if (fmt) {
    return [
      {
        stacked: true,
        ticks: {
          callback: (value) => (zoom ? format(fmt)(value) : ''),
        },
      },
    ];
  }

  return [];
};

export const makeGraph = (
  canvasKey,
  graph,
  height,
  width,
  interactive = false,
  defaultTransparentize = null,
  defaultMakeAxes = null
) => {
  defaultTransparentize ||= transparentize;
  defaultMakeAxes ||= makeAxes;
  const res = {
    type: graph.type,
    height,
    width,
    maintainAspectRatio: false,
    data: {
      labels: graph.labels,
    },
    options: {
      legend: { display: interactive },
      animation: false,
      hover: { animationDuration: 0 },
      responsiveAnimationDuration: 0,
    },
  };

  if (!interactive) {
    res.options.events = [];
  }

  res.data.datasets = graph.datasets.map((e, i) => {
    const points = e.pointStyle || 'line';
    const pntStyle = points;
    const pntSize = Array.isArray(points) ? 5 : 1;
    const color = e.color || COLORS[i];
    const pntColor = e.pointBackgroundColor || '#0000ff';
    const d = {
      backgroundColor: defaultTransparentize(color),
      borderColor: defaultTransparentize(color),
      data: e.values,
      label: e.key,
      fill: graph.fill || '',
      pointStyle: pntStyle,
      pointRadius: pntSize,
      pointBackgroundColor: pntColor,
    };
    return d;
  });

  const ax = defaultMakeAxes(graph.xType || 'linear', graph.xFmt, interactive);
  const ay = defaultMakeAxes(graph.yType || 'linear', graph.yFmt, interactive);

  res.options.scales = {
    xAxes: ax,
    yAxes: ay,
  };

  return new Chart(canvasKey, { ...res });
};

const ChartUtilsGraph = ({ index, graphKey, graph, height, width, interactive = false, defaultMakeGraph = null }) => {
  const idx = Number(index) || 0;
  const canvas = graphKey + idx;

  defaultMakeGraph ||= makeGraph;
  useEffect(() => {
    defaultMakeGraph(canvas, graph, height, width, interactive);
  }, [canvas, graph, height, width, interactive]);

  return (
    <canvas
      data-test="chart-utils-graph-component"
      id={canvas}
      className="chartjs-render-monitor"
      height={height}
      width={width}
      style={{ height: `${height}px`, width: `${width}px` }}
    />
  );
};

export default ChartUtilsGraph;
