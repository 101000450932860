import React, { useContext, useState, useEffect } from 'react';
import { navigate } from 'gatsby';

import Layout from '../layout/Layout';
import CardHeading from '../layout/CardHeading';
import Tab from '../layout/Tab';
import SimulationModal from '../modals/SimulationModal';
import MaterialsForm from '../forms/MaterialsForm';
import OptionsForm from '../forms/OptionsForm';

import { GlobalStateContext } from '../../context/GlobalContextProvider';
import LoadingsForm from '../forms/LoadingsForm';

const navPaths = ['materials', 'loadings', 'options'];

const SpecificationMaterials = ({ tab = null }) => {
  if (!tab) {
    navigate('/app/specs/materials');
  }

  const currentTabIndex = navPaths.indexOf(tab);
  const { changeUnit, specsTab, setSpecTab } = useContext(GlobalStateContext);
  const [sim, doSim] = useState(false);
  const titles = [
    {
      to: '/app/specs/materials',
      content: <div style={{ padding: '0.5em 0' }}>Materials</div>,
    },
    {
      to: '/app/specs/loadings',
      content: <div style={{ padding: '0.5em 0' }}>Loadings</div>,
    },
    {
      to: '/app/specs/options',
      content: <div style={{ padding: '0.5em 0' }}>Options</div>,
    },
  ];

  useEffect(() => {
    if (currentTabIndex !== -1) {
      setSpecTab(currentTabIndex);
    }
  }, [setSpecTab, currentTabIndex]);

  return (
    <Layout fixed data-test="specifications-materials-component">
      <div className="specifications-materials">
        <CardHeading heading={{ title: 'Specifications' }} />
        <Tab state={[specsTab, setSpecTab]} titles={titles}>
          <MaterialsForm changeUnit={changeUnit} doSim={doSim} />
          <LoadingsForm sim={sim} doSim={doSim} />
          <OptionsForm sim={sim} doSim={doSim} />
        </Tab>
        <SimulationModal visible={sim} setVisible={doSim} />
      </div>
    </Layout>
  );
};

export default SpecificationMaterials;
