import React, { useState, useEffect } from 'react';
import 'react-input-range/lib/css/index.css';
import InputRange from 'react-input-range';
import { Flex } from 'rebass';
import _ from 'lodash';

const RangeInput = (props) => {
  const { attr, hidden, disabled, set, get, step = 0.01, min = Number.MIN_VALUE, max = Number.MAX_VALUE } = props;
  const fieldValue = _.get(get, `${attr}`, null);
  const [value, setValue] = useState(0);

  const updateValue = (v) => {
    setValue(v);
    set(v, attr);
  };

  useEffect(() => {
    if (fieldValue === null) return;

    if (disabled) {
      updateValue('0');
    } else {
      updateValue(fieldValue);
    }
  }, [disabled, fieldValue]);

  return (
    <Flex style={{ display: hidden ? 'none' : '' }}>
      <InputRange
        maxValue={max}
        minValue={min}
        step={step}
        value={Number.parseFloat(value)}
        // value={value}
        disabled={disabled}
        onChange={updateValue}
      />
    </Flex>
  );
};

export default RangeInput;
