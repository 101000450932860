import React, { useState } from 'react';
import convertMaterial, { convertOptions, degFtoDegC, degCtoDegF } from '../utils/converter';
import _ from 'lodash';
import { localStorageSet } from '../utils/local-storage';
import { transformRawMaterial } from '../utils/materials';
import SYSTEM_MATERIALS from '../utils/materials-system';

export const GlobalStateContext = React.createContext({});

const bulkModulus = {
  'si-m': 3000e6,
  'si-mm': 3000,
  imperial: 435.1e3,
};
const sizeEOL = {
  'si-m': 1.0e-3,
  'si-mm': 1.0,
  imperial: 0.03937,
};
export const defaultState = {
  specsTab: '0',
  resultsTab: '0',
  selectedUnit: 'SI-mm',
  defaultLoadingsRefTemp: '20', // SI-mm or SI-m equals 20 and Imperial 100
  selectedSpecType: 'Materials',
  modalContent: {
    modalDetails: null,
  },
  modalMtrlSet: 0,
  materials1: transformRawMaterial(SYSTEM_MATERIALS[0]),
  materials2: {
    name: '',
    refTemp: [''],
    youngsModulus: [''],
    criticalTearingEnergy: [''],
    intrinsicStrength: [''],
    precursorSize: [''],
    fcgRateLawSlope: [''],
    fcgRateLawConstant: [''],
    crystallizationStrength: [''],
  },
  loadings: [
    {
      loadCase: '',
      modeOfDeformation: '1CNESI',
      peakNomEngStrain: '',
      minNomEngStrain: '',
      temp: '20',
      modeOfControl: '0',
    },
    {
      loadCase: '',
      modeOfDeformation: '1CNESI',
      peakNomEngStrain: '',
      minNomEngStrain: '',
      temp: '20',
      modeOfControl: '0',
    },
  ],
};

export const stressStrainInitialOptions = {
  optionEmin: '0',
  optionEmax: '0.50',
  optionVmax: '0.05',
  optionVmin: '-0.05',
};
export const fcgrInitialOptions = {
  optionTmax: '100000',
  optionTmin: '1',
  optionRmax: '0.75',
  optionRmin: '0',
};
export const haighDiagramInitialOptions = {
  strainampmin: '0',
  strainampmax: '1',
  meanstrainmin: '-1',
  meanstrainmax: '1',
  mmm: 'ST',
};
const initialOptions = {
  ...stressStrainInitialOptions,
  ...fcgrInitialOptions,
  ...haighDiagramInitialOptions,
};

const GlobalContextProvider = ({ children }) => {
  const [specsTab, setSpecTab] = useState(defaultState.specsTab);
  const [resultsTab, setResultsTab] = useState(defaultState.resultsTab);

  const [selectedUnit, setSelectedUnit] = useState(defaultState.selectedUnit);
  const [selectedSpecType, setSelectedSpecType] = useState(defaultState.selectedSpecType);

  const [modal, setModal] = useState(false);
  const [isModalLoading, setIsModalLoading] = useState(false);
  const [modalContent, setModalContent] = useState(defaultState.modalContent);
  const [modalMtrlSet, setModalMtrlSet] = useState(defaultState.modalMtrlSet);

  const [materialsData, setMaterialsData] = useState([defaultState.materials1, defaultState.materials2]);

  const [loadingsData, setLoadingsData] = useState(defaultState.loadings);

  const [simResults, setSimResults] = useState([[], [], []]);

  const [defaultLoadingsRefTemp, setDefaultLoadingsRefTemp] = useState(defaultState.defaultLoadingsRefTemp);

  const [previews, setPreviews] = useState({});

  function degConvert(from: string, to: string, value: number) {
    if (from.startsWith('si-m') && to.startsWith('si-m')) {
      return value;
    }
    return from === 'imperial' ? degFtoDegC(value) : degCtoDegF(value);
  }

  // Change from SI-mm, Si-m, or Imperial
  const changeUnit = (unit) => {
    setSelectedUnit(unit);
    //const temp = getLoadingRefTemp(unit);
    setLoadingsData((prev) =>
      prev.map((e) => {
        console.log(`changeUnit() ${selectedUnit.toLowerCase()} => ${unit.toLowerCase()} : ${e.temp}`);
        return {
          ...e,
          temp: String(degConvert(selectedUnit.toLowerCase(), unit.toLowerCase(), Number(e.temp))),
        };
      })
    );
    setOptions((prevOptions) => {
      const newOptions = {
        ...prevOptions,
        units: selectedUnit.toLowerCase(),
      };

      return {
        ...prevOptions,
        ...convertOptions(newOptions, unit),
      };
    });

    setMaterialsData((prevMaterials) =>
      prevMaterials.map((mat, i) => {
        if (!mat.units) {
          mat.units = selectedUnit.toLowerCase();
        }

        const converted = convertMaterial(mat, unit);
        if (i === 0) {
          localStorageSet(`material-${i}`, converted);
        }

        return converted;
      })
    );
  };

  const getLoadingRefTemp = (unit) => (unit === 'Imperial' ? '100' : '20');

  // Open or Close modal
  const handleOpenModal = () => {
    setModal(!modal);
  };

  const resetContext = () => {
    setSpecTab(defaultState.specsTab);
    setResultsTab(defaultState.resultsTab);
    setSelectedUnit(defaultState.selectedUnit);
    setSelectedSpecType(defaultState.selectedSpecType);
    setModalContent(defaultState.modalContent);
    setModalMtrlSet(defaultState.modalMtrlSet);
  };

  const [options, setOptions] = useState({
    precursorSizeCalibration: false,
    haighDiagram: false,
    damageSphere: false,
    stressStrain: false,
    fcgr: false,
    showHfo: false,
    showHfi: false,
    onlyHfi: false,
    showHfm: false,
    bulkModulus: bulkModulus[defaultState.selectedUnit.toLowerCase()],
    sizeEOL: sizeEOL[defaultState.selectedUnit.toLowerCase()],
    ...initialOptions,
  });

  const resetOptions = (type) => {
    switch (type) {
      case 'haighDiagram':
        setOptions({
          ...options,
          ...haighDiagramInitialOptions,
        });
        break;
      case 'fcgr':
        setOptions({
          ...options,
          ...getFcgrDefault(),
        });
        break;
      case 'stressStrain':
        setOptions({
          ...options,
          ...stressStrainInitialOptions,
        });
        break;
      default:
        setOptions({
          ...options,
          ...initialOptions,
        });
    }
  };
  return (
    <GlobalStateContext.Provider
      value={{
        specsTab,
        setSpecTab, // specifications tab state
        resultsTab,
        setResultsTab, // results tab state
        options,
        setOptions, // options tab state
        selectedUnit,
        changeUnit, // selected units state

        modal,
        modalContent,
        handleOpenModal,
        loadingsData,
        setLoadingsData,
        materialsData,
        setMaterialsData,
        simResults,
        setSimResults,
        modalMtrlSet,
        setIsModalLoading,
        isModalLoading,
        selectedSpecType,
        setSelectedSpecType,
        resetContext,
        defaultLoadingsRefTemp,
        setDefaultLoadingsRefTemp,
        resetOptions,
        previews,
        setPreviews,
      }}
    >
      {children}
    </GlobalStateContext.Provider>
  );

  function getFcgrDefault() {
    const [materialForm1, materialForm2] = materialsData;
    const criticalTearingEnergy1 = _.get(materialForm1, 'criticalTearingEnergy[0]', null);
    const criticalTearingEnergy2 = _.get(materialForm2, 'criticalTearingEnergy[0]', null);
    const intrinsicStrength1 = _.get(materialForm1, 'intrinsicStrength[0]', null);
    const intrinsicStrength2 = _.get(materialForm2, 'intrinsicStrength[0]', null);

    return {
      ...fcgrInitialOptions,
      optionTmax: Math.max(...[criticalTearingEnergy1, criticalTearingEnergy2].map((e) => Number(e) * 2)),
      optionTmin: Math.min(...[intrinsicStrength1 || intrinsicStrength2].map((e) => Number(e) / 2)),
    };
  }
};

export default GlobalContextProvider;
