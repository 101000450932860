import React from "react"
import ClearSVG from "assets/svg/button.clear.inline.svg"

const noop = () => {};

const Clear = ({ onClearHandler = noop }) => 
  (
    <button data-test="clear-component" type="button" onClick={onClearHandler} className="btn-action">
      <ClearSVG />
    </button>
  )

export default Clear;
