import React, { useState } from 'react';
import classnames from 'classnames';
import { graphql, useStaticQuery } from 'gatsby';
import _ from 'lodash';

import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';

import DecodeObj from '../layout/DecodeObj';
import Layout from '../layout/Layout';
import CardHeading from '../layout/CardHeading';

const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

const Support = () => {
  const data = useStaticQuery(graphql`
    query Faq {
      allWpPage(filter: { id: { eq: "cG9zdDoxNDk=" } }) {
        edges {
          node {
            id
            categories {
              nodes {
                id
                name
                pages {
                  nodes {
                    title
                    content
                    id
                    slug
                  }
                }
              }
            }
          }
        }
      }

      wpPage(id: { eq: "cG9zdDo4NjY=" }) {
        title
        content
      }
    }
  `);

  const dataQuery = data.allWpPage.edges;
  dataQuery[0].node.categories.nodes[0].pages.nodes = _.sortBy(dataQuery[0].node.categories.nodes[0].pages.nodes, [
    'slug',
  ]);

  const headingDataQuery = data.wpPage;
  const [expanded, setExpanded] = useState('cG9zdDoxNDk=');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const heading = {
    title: `${headingDataQuery.title}`,
    desc: `${headingDataQuery.content}`,
  };

  return (
    <Layout data-test="support-component">
      <div className="support">
        <CardHeading heading={heading} />
        <div className="accordion-wrapper">
          {dataQuery.map((item, i) => (
            <React.Fragment key={i}>
              {item.node.categories.nodes.map((catItem, j) => (
                <React.Fragment key={j}>
                  {catItem.pages.nodes.map((questionItem, k) => (
                    <Accordion
                      data-test={`support-component-accordion-${k}`}
                      key={k}
                      square
                      expanded={expanded === questionItem.id}
                      onChange={handleChange(questionItem.id)}
                    >
                      <AccordionSummary aria-controls={questionItem.id} id={questionItem.id}>
                        <Typography>{questionItem.title.substr(3)}</Typography>
                        <span
                          className={classnames('box', {
                            open: expanded === questionItem.id,
                          })}
                        />
                      </AccordionSummary>
                      <AccordionDetails>
                        <DecodeObj objVal={questionItem.content} />
                      </AccordionDetails>
                    </Accordion>
                  ))}
                </React.Fragment>
              ))}
            </React.Fragment>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default Support;
