import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  Media,
} from "reactstrap";
import { Flex } from "rebass";
import _ from "lodash";
import { HelpIcon } from "./HelpIcon";
import FieldProImg from "../../assets/images/field_pro.png";
import GreyLabel from "./GreyLabel";
import { handleNumber } from "../../lib/valdiators";
import ModeOfDeformationOptions from "../forms/ModeOfDeformationOptions";
import User from "../../utils/auth";

export const OneCheckElements = ({
  attr,
  get,
  set,
  permission,
  children,
  slug,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [disabled, setDisabled] = useState(false);

  const handleChange = () => {
    set({ ...get, [attr]: Boolean(!get[attr]) });
  };

  const Init = () => {
    if (!permission) set({ ...get, [attr]: false });
    setDisabled(!permission);
    setIsLoading(false);
  };

  useEffect(() => {
    Init();
  }, [permission]);
  return {
    attr,
    checked: Boolean(get[attr]),
    disabled,
    isLoading,
    handleChange,
    Init,
    children,
    slug,
  };
};

export const OneCheck = (props) => {
  const { adminOnly } = props;
  const { attr, checked, disabled, isLoading, handleChange, children, slug } =
    OneCheckElements(props);
  return (
    <FormGroup data-test="one-check-component" key={attr} check>
      <InputGroup>
        <Label check className="text-nowrap float-left m-1">
          <Input
            disabled={disabled}
            type="checkbox"
            id={`options-${attr}`}
            name="options"
            checked={checked}
            onChange={handleChange}
            required
          />
          {children}
        </Label>
        <InputGroupAddon addonType="append">
          {!isLoading && disabled && !adminOnly && (
            <span className="icons">
              <Media
                data-test="one-check-component-placeholder"
                object
                src={FieldProImg}
                alt="You do not have permission."
              />
            </span>
          )}
          {slug && <HelpIcon slug={slug} />}
        </InputGroupAddon>
      </InputGroup>
    </FormGroup>
  );
};

export const CustomInputField = ({
  title,
  subTitle,
  get,
  setNumber,
  attr,
  type = "text",
  slug,
}) => (
  <Flex
    data-test="custom-input-field-component"
    justifyContent="flex-start"
    className="pl-4"
  >
    <div key="0" className="content-holder">
      <GreyLabel slug={slug || attr}>
        <div
          style={{
            display: "inline-block",
            textAlign: "start",
            fontSize: "12px",
            lineHeight: "12px",
            clear: "none",
            margin: "auto",
          }}
        >
          {title}
        </div>
        <div style={{ fontSize: "10px" }}>{subTitle}</div>
      </GreyLabel>
    </div>
    <FormGroup className="w-100">
      <InputGroup>
        <Input
          id={`${attr}`}
          value={get[attr]}
          onChange={setNumber(attr)}
          className="specs"
          type={type}
          required
        />
      </InputGroup>
    </FormGroup>
  </Flex>
);

export const CustomSelectField = ({ title, subTitle, get, set, attr }) => (
  <Flex
    data-test="custom-select-field-component"
    justifyContent="flex-start"
    className="pl-4"
  >
    <div key="0" className="content-holder">
      <GreyLabel slug={attr}>
        <div
          style={{
            display: "inline-block",
            textAlign: "start",
            fontSize: "12px",
            lineHeight: "12px",
            clear: "none",
            margin: "auto",
          }}
        >
          {title}
        </div>
        <div style={{ fontSize: "10px" }}>{subTitle}</div>
      </GreyLabel>
    </div>
    <FormGroup className="w-100">
      <InputGroup>
        <Input
          type="select"
          name="select"
          id={`exampleSelect-${attr}`}
          className="specs"
          value={get[attr]}
          onChange={set(attr)}
        >
          <ModeOfDeformationOptions />
        </Input>
      </InputGroup>
    </FormGroup>
  </Flex>
);

export const StressStrainModelElements = ({ get, set }) => {
  const setNumber = (attr) => (event) =>
    handleNumber(event.currentTarget.value, set(attr));

  return {
    get,
    set,
    setNumber,
  };
};

export const CustomFields = ({ get, set }) => {
  const setNumber = (attr) => (event) =>
    handleNumber(event.currentTarget.value, set(attr));
  return (
    <Row>
      <Col>
        <CustomInputField
          title="Size EOL"
          get={get}
          setNumber={setNumber}
          attr="sizeEOL"
          slug="option_size_eol"
        />
      </Col>
      <Col>
        <CustomInputField
          title="Bulk Modulus"
          get={get}
          setNumber={setNumber}
          attr="bulkModulus"
          slug="option_bulk_modulus"
        />
      </Col>
    </Row>
  );
};

export const StressStrainModel = (props) => {
  const { get, setNumber } = StressStrainModelElements(props);

  return (
    <Container data-test="stress-strain-model-component" className="container">
      <Row>
        <Col>
          <CustomInputField
            title="Strain"
            subTitle="Minimum"
            get={get}
            setNumber={setNumber}
            attr="optionEmin"
          />
        </Col>
        <Col>
          <CustomInputField
            title="Strain"
            subTitle="Maximum"
            get={get}
            setNumber={setNumber}
            attr="optionEmax"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <CustomInputField
            title="Volume"
            subTitle="Minimum"
            get={get}
            setNumber={setNumber}
            attr="optionVmin"
          />
        </Col>
        <Col>
          <CustomInputField
            title="Volume"
            subTitle="Maximum"
            get={get}
            setNumber={setNumber}
            attr="optionVmax"
          />
        </Col>
      </Row>
    </Container>
  );
};
export const FCGRModelElements = ({ get, set }) => {
  const setNumber = (attr) => (event) =>
    handleNumber(event.currentTarget.value, set(attr));

  return {
    get,
    set,
    setNumber,
  };
};

export const FCGRModel = (props) => {
  const { get, setNumber } = FCGRModelElements(props);

  return (
    <Container data-test="fcgr-model-component" className="container">
      <Row>
        <Col>
          <CustomInputField
            title="R ratio"
            subTitle="Minimum"
            get={get}
            setNumber={setNumber}
            attr="optionRmin"
          />
        </Col>
        <Col>
          <CustomInputField
            title="R ratio"
            subTitle="Maximum"
            get={get}
            setNumber={setNumber}
            attr="optionRmax"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <CustomInputField
            title="Tearing Energy"
            slug="specs_tc"
            subTitle="Minimum"
            get={get}
            setNumber={setNumber}
            attr="optionTmin"
          />
        </Col>
        <Col>
          <CustomInputField
            title="Tearing Energy"
            subTitle="Maximum"
            slug="specs_tc"
            get={get}
            setNumber={setNumber}
            attr="optionTmax"
          />
        </Col>
      </Row>
    </Container>
  );
};

export const HaighModelElements = ({ get, set }) => {
  const setNumber = (attr) => (event) =>
    handleNumber(event.currentTarget.value, set(attr));
  const setText = (attr) => (event) => set(attr)(event.currentTarget.value);

  return {
    get,
    set,
    setNumber,
    setText,
  };
};

export const HaighModel = (props) => {
  const { get, setNumber, setText } = HaighModelElements(props);

  return (
    <Container data-test="haigh-model-component" className="container">
      <Row>
        <Col>
          <CustomInputField
            title="Strain Amplitude"
            subTitle="Minimum"
            get={get}
            setNumber={setNumber}
            attr="strainampmin"
          />
        </Col>
        <Col>
          <CustomInputField
            title="Strain Amplitude"
            subTitle="Maximum"
            get={get}
            setNumber={setNumber}
            attr="strainampmax"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <CustomInputField
            title="Mean Strain"
            subTitle="Minimum"
            get={get}
            setNumber={setNumber}
            attr="meanstrainmin"
          />
        </Col>
        <Col>
          <CustomInputField
            title="Mean Strain"
            subTitle="Maximum"
            get={get}
            setNumber={setNumber}
            attr="meanstrainmax"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <CustomSelectField
            title="Mode of Deformation"
            subTitle=" "
            get={get}
            set={setText}
            attr="mmm"
          />
        </Col>
        <Col />
      </Row>
    </Container>
  );
};

export const OneCheckListElements = ({ options, setOptions }) => {
  const setOptionFor = () => (attr) => (value) => {
    setOptions({ ...options, [attr]: value });
  };

  const precursorSizeCalibrationField = {
    attr: "precursorSizeCalibration",
    permission: _.find(["PLOT_PRE", "PLOT_CTRL"], User.checkPermission),
    slug: "option_precursor",
    get: options,
    set: setOptions,
  };

  const damageSphereField = {
    attr: "damageSphere",
    permission: User.checkPermission("PLOT_DSPHERE"),
    slug: "option_damage_sphere",
    get: options,
    set: setOptions,
  };

  const haighDiagramField = {
    attr: "haighDiagram",
    permission: User.checkPermission("PLOT_HAIGH"),
    slug: "option_haigh",
    get: options,
    set: setOptions,
  };

  const stressStrainField = {
    attr: "stressStrain",
    slug: "option_stress_strain",
    permission: true,
    get: options,
    set: setOptions,
  };

  const fcgrField = {
    attr: "fcgr",
    slug: "option_fcgr",
    permission: true,
    get: options,
    set: setOptions,
  };

  const showHfoField = {
    attr: "showHfo",
    permission: true,
    get: options,
    set: setOptions,
  };

  const showHfiField = {
    attr: "showHfi",
    permission: true,
    get: options,
    set: setOptions,
  };

  const onlyHfiField = {
    attr: "onlyHfi",
    permission: options.showHfi,
    get: options,
    set: setOptions,
  };

  const showHfmField = {
    attr: "showHfm",
    permission: true,
    get: options,
    set: setOptions,
  };

  const bulkModulusField = {
    attr: "bulkModulus",
    permission: true,
    get: options,
    set: setOptions,
  };
  const sizeEOLField = {
    attr: "sizeEOL",
    permission: true,
    get: options,
    set: setOptions,
  };
  return {
    options,
    setOptions,
    setOptionFor,
    bulkModulusField,
    sizeEOLField,
    precursorSizeCalibrationField,
    damageSphereField,
    haighDiagramField,
    stressStrainField,
    fcgrField,
    showHfoField,
    showHfiField,
    showHfmField,
    onlyHfiField,
  };
};

const OneCheckList = (props) => {
  const {
    options,
    setOptionFor,
    precursorSizeCalibrationField,
    damageSphereField,
    haighDiagramField,
    stressStrainField,
    fcgrField,
    showHfoField,
    showHfiField,
    showHfmField,
    onlyHfiField,
  } = OneCheckListElements(props);

  return (
    <Container>
      <Row>
        <Col>
          <OneCheck
            data-test="one-check-list-component-precursor"
            {...precursorSizeCalibrationField}
          >
            Precursor Size Calibration
          </OneCheck>
        </Col>
        <Col>
          <OneCheck
            data-test="one-check-list-component-damage"
            {...damageSphereField}
          >
            Damage Sphere
          </OneCheck>
        </Col>
      </Row>
      <Row>
        <Col>
          <OneCheck
            data-test="one-check-list-component-stress"
            {...stressStrainField}
          >
            Stress-Strain Limits
          </OneCheck>
          {options && options.stressStrain && (
            <StressStrainModel
              data-test="one-check-list-component-stress-model"
              get={options}
              set={setOptionFor()}
            />
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          <OneCheck data-test="one-check-list-component-fcgr" {...fcgrField}>
            FCGR upper/lower levels
          </OneCheck>
          {options && options.fcgr && (
            <FCGRModel
              data-test="one-check-list-component-fcgr-model"
              get={options}
              set={setOptionFor()}
            />
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          <OneCheck
            data-test="one-check-list-component-haigh"
            {...haighDiagramField}
          >
            Haigh Diagram
          </OneCheck>

          {options && haighDiagramField.permission && options.haighDiagram && (
            <HaighModel
              data-test="one-check-list-component-haigh-model"
              get={options}
              set={setOptionFor()}
            />
          )}
        </Col>
      </Row>
      <CustomFields get={options} set={setOptionFor()} />
      {User.getRole() === "ADMIN" && (
        <Row>
          <OneCheck
            key="one-check-list-component-hfo"
            data-test="one-check-list-component-hfo"
            adminOnly
            {...showHfoField}
          >
            Show HFO
          </OneCheck>
          <OneCheck
            key="one-check-list-component-showhfi"
            data-test="one-check-list-component-showhfi"
            adminOnly
            {...showHfiField}
          >
            Show HFi
          </OneCheck>
          <OneCheck
            key="one-check-list-component-onlyhfi"
            data-test="one-check-list-component-onlyhfi"
            adminOnly
            {...onlyHfiField}
          >
            Only HFi
          </OneCheck>
          <OneCheck
            key="one-check-list-component-hfm"
            data-test="one-check-list-component-hfm"
            adminOnly
            {...showHfmField}
          >
            Show HFM
          </OneCheck>
        </Row>
      )}
    </Container>
  );
};

export default OneCheckList;
