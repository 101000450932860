import React, { useEffect, useContext } from 'react';
import { Flex } from 'rebass';
import { Link } from 'gatsby';
import { Button, Form, FormGroup, Input, Label, FormFeedback } from 'reactstrap';
import { navigate } from '@reach/router';
import User from 'utils/auth';
import CardHeading from 'components/layout/CardHeading';
import CardFooter from 'components/layout/CardFooter';
import Error from 'components/Error';
import * as yup from 'yup';
import { getValidationResult, fieldIsInvalid, fieldIsRequired } from 'utils/tools';
import Layout from 'components/layout/Layout';
import { GlobalStateContext } from 'context/GlobalContextProvider';
import useForm from 'hooks/useForm';
import useRequest from 'hooks/useRequest';

const heading = {
  title: 'Sign In',
};

const loginSchema = yup.object({
  email: yup.string().required(fieldIsRequired).email(fieldIsInvalid),
  password: yup.string().required(fieldIsRequired),
});
function validator(data) {
  return getValidationResult(data, loginSchema);
}

const successPage = '/app/specs/materials';
export const checkUserIfLoggedIn = async () => {
  const userIsLoggedIn = await User.isLoggedIn();
  if (userIsLoggedIn) {
    navigate(successPage);
  }
};

export const SignInElements = () => {
  const [{ fields, errors }, { onElementChange, onChange, onValidate }] = useForm({
    initialFields: { email: '', password: '', rememberPassword: false },
    validator,
    onValid,
  });
  const { email, password, rememberPassword } = fields;
  const { resetContext } = useContext(GlobalStateContext);
  const [onLogin, loginState] = useRequest({
    onSuccess,
  });
  useEffect(() => {
    (async function () {
      await checkUserIfLoggedIn();
      const password = (await User.decryptP()) || '';
      onChange('password', password);

      if (password) {
        onChange('rememberPassword', true);
      }
    })();
  }, []);

  const isLoading = loginState.loading;
  return {
    emailField: {
      onChange: onElementChange,
      value: email,
      disabled: isLoading,
    },
    passwordField: {
      onChange: onElementChange,
      value: password,
      disabled: isLoading,
    },
    rememberPassField: {
      onChange: onRememberPasswordChange,
      checked: rememberPassword,
      value: rememberPassword,
      disabled: isLoading,
    },
    loginButton: {
      onClick: onValidate,
      disabled: isLoading,
    },
    error: loginState.error,
    isLoading,
    errors,
  };

  function onValid() {
    onLogin(async () => User.signin(email, password));
  }

  function onSuccess() {
    resetContext();
    if (rememberPassword) {
      User.encryptP(password);
    } else {
      User.removePassword();
    }

    navigate(successPage);
  }

  function onRememberPasswordChange(e) {
    const value = e.target.value === 'true';
    onChange('rememberPassword', !value);
  }
};

const SignIn = () => {
  const { emailField, passwordField, rememberPassField, loginButton, error, isLoading, errors } = SignInElements();

  return (
    <Layout hideIcons data-test="siginin-component">
      <div className="signin">
        <CardHeading heading={heading} />

        <Form>
          {error && <Error errorMessage={error} />}
          <FormGroup>
            <Input
              data-test="siginin-component-email"
              name="email"
              id="email"
              autoComplete="username"
              placeholder="Email"
              invalid={errors.email}
              {...emailField}
            />
            {errors.email && <FormFeedback>{errors.email}</FormFeedback>}
          </FormGroup>
          <FormGroup>
            <Input
              data-test="siginin-component-password"
              type="password"
              name="password"
              id="password"
              autoComplete="current-password"
              placeholder="Password"
              invalid={errors.password}
              {...passwordField}
            />
            {errors.password && <FormFeedback>{errors.password}</FormFeedback>}
          </FormGroup>
          <FormGroup check>
            <Label check>
              <Input
                data-test="siginin-component-remember-pass"
                type="checkbox"
                id="rememberPassword"
                name="rememberPassword"
                {...rememberPassField}
              />{' '}
              Remember password
            </Label>
          </FormGroup>
          <Button block data-test="siginin-component-button" type="submit" color="primary" {...loginButton}>
            {!isLoading ? 'Sign in' : 'Please wait...'}
          </Button>
          <Flex justifyContent="center">
            <Link to="/recover-password" className="link-heading">
              Forgot Password?
            </Link>
          </Flex>
        </Form>
        {/**/}

        <CardFooter>
          <div className="text-center">
            Not Signed up?
            <Link to="/create-account" className="link-heading ml-1">
              Create an Account
            </Link>
          </div>
          <div className="text-center">
            <Link to="/confirm-account" className="link-heading ml-1">
              Just need to confirm your account?
            </Link>
          </div>
        </CardFooter>
      </div>
    </Layout>
  );
};

export default SignIn;
