import React from 'react';
import ChartJSGraph, { UNITS, makeScale, defaultConfig, mapDataset } from './ChartJSGraph';

const SedHistoryGraph = ({ index, graphKey, graph, height, width, interactive = false }) => {
  const config = defaultConfig('line', height, width);
  config.data = {
    datasets: [
      {
        ...mapDataset(graph.datasets[0], 0),
        label: '',
        backgroundColor: 'blue',
        borderColor: 'blue',
        color: 'blue',
      },
    ],
    labels: graph.labels,
  };

  if (interactive) {
    config.options = {
      ...config.options,
      legend: { display: false },
      animation: false,
      hover: { animationDuration: 0 },
      responsiveAnimationDuration: 0,
      scales: {
        xAxes: [
          {
            scaleLabel: {
              display: true,
              labelString: 'Time step',
            },
          },
        ],
        yAxes: [
          {
            scaleLabel: {
              display: true,
              labelString: `Strain Energy Density ( ${UNITS[graph.measurementUnits].energyDensity} )`,
            },
          },
        ],
      },
    };
  } else {
    config.options = {
      ...config.options,
      legend: { display: false },
      events: [],
      scales: {
        xAxes: [makeScale('', interactive)],
        yAxes: [makeScale('', interactive)],
      },
    };
  }

  return <ChartJSGraph options={config} canvasKey={graphKey} data-test="sed-graph-component" />;
};

export default SedHistoryGraph;
