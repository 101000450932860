import React, { useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Question from '../../assets/svg/question-circle.inline.svg';
import { MessageModal } from '../modals/Modals';
import DecodeObj from '../layout/DecodeObj';

export const helpText = {};

/*
const DecodeHtml = ({objVal}) => { 
  const decodeHtml = objVal.replace(/<[^>]+>/g, '');
  return (
    <React.Fragment>
      {decodeHtml}
    </React.Fragment>
  )
}
*/

const getHelpObj = (data, slug) => {
  if (Object.keys(helpText).length === 0) {
    data.helpText.nodes.forEach((n) => n.tags.nodes[0].pages.nodes.forEach((p) => (helpText[p.slug] = p)));
  }
  return helpText[slug];
};

export const HelpIcon = ({ slug = '' }) => {
  const data = useStaticQuery(graphql`
    query HelpTextQuery {
      helpText: allWpPage(filter: { categories: { nodes: { elemMatch: { name: { eq: "Help Text" } } } } }) {
        nodes {
          tags {
            nodes {
              name
              pages {
                nodes {
                  id
                  title
                  content
                  slug
                }
              }
            }
          }
        }
      }

      wpPage(id: { eq: "cG9zdDo4Nzg=" }) {
        title
        content
      }
    }
  `);
  const helpObj = getHelpObj(data, slug);
  const [modal, setModal] = useState(false);

  if (helpObj) {
    const onClick = () => {
      setModal(true);
    };

    return (
      <button
        data-test="help-icon-component"
        type="button"
        onClick={() => onClick()}
        onKeyDown={() => onClick()}
        className="btn-more-info"
      >
        <Question />
        <MessageModal title={helpObj.title} modal={modal} setModal={setModal}>
          {helpObj.content && <DecodeObj objVal={helpObj.content} />}
          {/* <div dangerouslySetInnerHTML={{ __html: helpObj.content }} /> */}
        </MessageModal>
      </button>
    );
  }

  return '';
};

export default HelpIcon;
