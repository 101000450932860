import React, { useContext } from 'react';
import { Flex } from 'rebass';
import { GlobalStateContext } from 'context/GlobalContextProvider';
import { HelpIcon } from './HelpIcon';
import UnitMeasurements from '../../lib/unit-measurements';

const measurementSpecs = (selectedUnit, specsUsage) => {
  if (selectedUnit === 'SI-mm') return specsUsage.siMM;
  if (selectedUnit === 'SI-m') return specsUsage.siM;
  if (selectedUnit === 'Imperial') return specsUsage.imperial;
  return null;
};

export const GreyLabel = ({ slug, children, specsIn = null }) => {
  const { selectedUnit } = useContext(GlobalStateContext);
  const specs = specsIn || UnitMeasurements[slug.replace('-', '_')];
  const specSpan = specs ? (
    <span
      className="t-specs"
      dangerouslySetInnerHTML={{
        __html: measurementSpecs(selectedUnit, specs),
      }}
    />
  ) : (
    ''
  );
  return (
    <Flex
      key={slug}
      flexDirection="row-reverse"
      alignItems="center"
      justifyContent="space-between"
      className="gray-div"
    >
      <div className="gray-specs-usage">
        <HelpIcon slug={slug} />
        {specSpan}
      </div>
      <div className="gray-name">{children}</div>
    </Flex>
  );
};

export default GreyLabel;
