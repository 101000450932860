import React from 'react';
import ChartJSGraph, { makeScale, defaultConfig, mapDataset } from './ChartJSGraph';

const COLORMAP = {
  max: 'blue',
  mid: 'green',
  min: 'red',
};

const PrincipleNEGraph = ({ index, graphKey, graph, height, width, interactive = false }) => {
  const config = defaultConfig('line', height, width);
  config.data = {
    datasets: graph.datasets
      .map((e, i) => mapDataset(e, i))
      .map((ds) => ({
        ...ds,
        backgroundColor: COLORMAP[ds.label],
        borderColor: COLORMAP[ds.label],
        color: COLORMAP[ds.label],
      })),
    labels: graph.labels,
  };

  if (interactive) {
    config.options = {
      ...config.options,
      legend: { display: true },
      animation: false,
      hover: { animationDuration: 0 },
      responsiveAnimationDuration: 0,
      scales: {
        xAxes: [
          {
            scaleLabel: {
              display: true,
              labelString: 'Time step',
            },
          },
        ],
        yAxes: [
          {
            scaleLabel: {
              display: true,
              labelString: 'Engineering Strain',
            },
          },
        ],
      },
    };
  } else {
    config.options = {
      ...config.options,
      legend: { display: false },
      events: [],
      scales: {
        xAxes: [makeScale('', interactive, false)],
        yAxes: [makeScale('', interactive, false)],
      },
    };
  }

  return (
    <ChartJSGraph
      options={config}
      canvasKey={`${graphKey}-${interactive ? 'interactive' : ''}`}
      data-test="principle-ne-graph-component"
    />
  );
};

export default PrincipleNEGraph;
