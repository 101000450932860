import React from 'react';
import { format } from 'd3-format';
import ChartJSGraph, { UNITS, makeScale, defaultConfig, mapDataset } from './ChartJSGraph';

const HydrostaticGraph = (props) => {
  const { graphKey, graph, height, width, interactive = false } = props;

  const config = defaultConfig('line', height, width);
  config.data = {
    datasets: [
      {
        ...mapDataset(graph.datasets[0], 0),
        label: '',
        backgroundColor: 'blue',
        borderColor: 'blue',
        color: 'blue',
      },
    ],
    labels: graph.labels,
  };

  if (interactive) {
    config.options = {
      ...config.options,
      legend: { display: false },
      animation: false,
      hover: { animationDuration: 0 },
      responsiveAnimationDuration: 0,
      scales: {
        xAxes: [
          {
            stacked: true,
            ticks: {
              callback: (value) => format('.2')(value),
            },
            scaleLabel: {
              display: true,
              labelString: 'Volumetric Strain',
            },
          },
        ],
        yAxes: [
          {
            ...makeScale(graph.yFmt),
            scaleLabel: {
              display: true,
              labelString: `Hydrostatic Pressure ( ${UNITS[graph.measurementUnits].pressure} )`,
            },
          },
        ],
      },
    };
  } else {
    config.options = {
      ...config.options,
      legend: { display: false },
      events: [],
      scales: {
        xAxes: [makeScale('', interactive)],
        yAxes: [makeScale('', interactive)],
      },
    };
  }

  return <ChartJSGraph options={config} canvasKey={graphKey} />;
};

export default HydrostaticGraph;
