import React, { useState, useEffect } from 'react';
import { navigate } from 'gatsby';
import { Button } from 'reactstrap';
import _ from 'lodash';
import { HelpIcon } from '../controls/HelpIcon';
import Graph, { makeGraph } from '../plots/Graph';
import { MessageModal } from '../modals/Modals';
import withAuth from '../nav/withAuth';
import nextImg from '../../assets/svg/next.svg';
import prevImg from '../../assets/svg/prev.svg';

const DEFAULT_MSG = { visible: false, title: '', content: '', button: ['Close'], next: null, prev: null };

function GraphView(props) {
  const { graphs, graph, items, thumb, size, tab, type, order, getTabLink } = props;
  const { name: graphName, key } = graph;
  const [msg, setMsg] = useState(DEFAULT_MSG);
  const showMessage = (title, content, next, prev) => setMsg({ ...msg, visible: true, title, content, next, prev });
  const closeMessage = () => setMsg({ ...DEFAULT_MSG });

  useEffect(() => {
    if (type && order !== null && tab !== null) {
      const currentGraph = graphs.find((g) => g.tab === tab && g.key === type && g.order === _.toNumber(order));
      const currentIndex = graphs.indexOf(currentGraph);

      getGraphByIndex(currentIndex);
    }
  }, [type, order, tab]);

  useEffect(() => {
    function onKeyDown(e) {
      if (e.key === 'ArrowLeft') {
        const link = msg.prev || getTabLink(-1, type, tab);
        navigate(link);
      }

      if (e.key === 'ArrowRight') {
        const link = msg.next || getTabLink(1, type, tab);
        navigate(link);
      }
    }

    window.addEventListener('keydown', onKeyDown);
    return () => window.removeEventListener('keydown', onKeyDown);
  }, [msg.visible, msg.next, msg.prev, tab]);

  const Next = ({ to }) => (
    <Button className="p-0 mt-0" color="white" onClick={() => navigate(to)}>
      <img className="m-0" src={nextImg} alt="Next graph set" />
    </Button>
  );

  const Prev = ({ to }) => (
    <Button className="p-0 mt-0" color="white" onClick={() => navigate(to)}>
      <img className="m-0" src={prevImg} alt="Next graph set" />
    </Button>
  );

  const onClickHandler = (gr) => {
    const { type: graphType, material, loadCase, simulationId, use } = gr;

    const id = [tab, simulationId, key, use, graphType, material, loadCase].join('-');
    const currentGraph = graphs.find((g) => g.id === id);
    const link = `/app/results/${tab}/${key}/${currentGraph.order}`;

    if (window.location.pathname === link) {
      const currentIndex = graphs.indexOf(currentGraph);
      getGraphByIndex(currentIndex);
    } else {
      navigate(link);
    }
  };

  return (
    <>
      <div className="h5 text-center">
        {graphName} <HelpIcon slug={graph.key} />
      </div>
      {graph.key ? (
        <div data-test="results-component-graph-text" className="h6 small text-muted text-center">
          (Click on the graph to enlarge)
        </div>
      ) : (
        ''
      )}
      <div className="text-center m-0 p-0">
        {items.map((gr, idx) => (
          <Graph
            key={`${gr.material}-${gr.loadCase}-${idx}`}
            idx={`${gr.material}-${gr.loadCase}-${idx}`}
            title={getTitle(gr)}
            gr={{
              ...gr,
              zoomWidth: size,
              width: thumb,
              aspectRatio: graph.aspectRatio,
            }}
            graphKey={graph.key}
            onClickHandler={onClickHandler}
          />
        ))}
      </div>
      <MessageModal
        data-test="zoom-graph-component-message-modal"
        title={msg.title}
        color={msg.class}
        modal={msg.visible}
        setModal={closeMessage}
        buttons={msg.buttons}
        actions={msg.actions}
        buttonComponents={msg.buttonComponents}
      >
        {!msg.next ? (
          ''
        ) : (
          <div className="d-flex h-100 mt-n3 pr-3" style={{ position: 'absolute', right: 0 }}>
            <Next to={msg.next} />
          </div>
        )}

        {!msg.prev ? (
          ''
        ) : (
          <div className="d-flex h-100 mt-n3 pl-3" style={{ position: 'absolute', left: 0 }}>
            <Prev to={msg.prev} />
          </div>
        )}

        {msg.content}
      </MessageModal>
    </>
  );

  function getGraphLink(gr) {
    return gr ? `/app/results/${gr.tab}/${gr.key}/${gr.order}` : null;
  }

  function getGraphByIndex(index) {
    if (index === -1) return;

    const currentGraph = graphs[index];
    const nextLink = getGraphLink(graphs[index + 1]);
    const prevLink = getGraphLink(graphs[index - 1]);

    const id = _.get(currentGraph, 'id', null);
    const g = makeGraph(currentGraph, id, id, size, size, true);
    const data = (
      <div className="mx-auto" style={{ width: `${size}px`, height: `${size}px` }}>
        {g}
      </div>
    );
    const title = getTitle(currentGraph, true);

    showMessage(title, data, nextLink, prevLink);
  }

  function getTitle(gr, zoom = false) {
    const name = _.get(gr, 'name', '');
    const material = _.get(gr, 'material', '');
    const mode = _.get(gr, 'mode', '');
    let use = _.get(gr, 'use', '');
    use = _.startCase(_.toLower(use));

    const text = [material, use, gr?.use === 'damage' && gr.loadCase].filter(Boolean).join(' - ');
    return (
      <>
        <small data-test="zoom-graph-component-title-small">
          <div data-test="zoom-graph-component-title-name">
            {zoom ? name : ''}
            <br />
          </div>
          {text}
        </small>
      </>
    );
  }
}
export { GraphView };
export default withAuth({})(GraphView);
