import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Spinner } from 'reactstrap';

import pick from 'lodash/pick';

function DefaultDialogTitle(props) {
  const { onCancel, title } = props;
  return (
    <ModalHeader toggle={onCancel}>
      <div className="text-primary h4">{title}</div>
    </ModalHeader>
  );
}

DefaultDialogTitle.propTypes = {
  onCancel: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

function DefaultDialogActions(props) {
  const { onContinue, onCancel, isProcessing, continueLabel, cancelLabel } = props;
  return (
    <ModalFooter>
      <Button disabled={isProcessing} color="secondary" onClick={onCancel}>
        {cancelLabel}
      </Button>
      <Button color="primary" onClick={onContinue}>
        {isProcessing ? <Spinner style={{ width: '0.9rem', height: '0.9rem', marginLeft: '5px' }} /> : continueLabel}
      </Button>
    </ModalFooter>
  );
}

DefaultDialogActions.propTypes = {
  onContinue: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  continueLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
};

DefaultDialogActions.defaultProps = {
  continueLabel: 'Continue',
  cancelLabel: 'Cancel',
};

function DialogLayout(props) {
  const { children, dialogTitleRenderer: DialogTitle, dialogActionsRenderer: DialogActions, className } = props;

  return (
    <Modal isOpen className={className}>
      <DialogTitle {...pick(props, ['onCancel', 'title'])} />
      <ModalBody>{children}</ModalBody>
      <DialogActions {...pick(props, ['onContinue', 'onCancel', 'isProcessing'])} />
    </Modal>
  );
}

DialogLayout.propTypes = {
  dialogTitleRenderer: PropTypes.func,
  dialogActionsRenderer: PropTypes.func,
  className: PropTypes.string,
  dialogClass: '',
  dialogId: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

DialogLayout.defaultProps = {
  dialogTitleRenderer: DefaultDialogTitle,
  dialogActionsRenderer: DefaultDialogActions,
  className: 'modal-wrap',
};

export default DialogLayout;
