import React, { useState, useEffect } from 'react';
import { Media } from 'reactstrap';
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import _ from 'lodash';
import { MessageModal } from './Modals';
import SaveSVG from '../../assets/svg/save.inline.svg';
import PlaceholderImg from '../../assets/images/action_pro.png';
import LoadingSpinner from '../LoadingSpinner';
import { useImperativeQuery } from '../../hooks/useQuery';

const noop = (data) => data;
const defaultQueries = { create: '', list: '', update: '' };
const defaultMsgValues = { visible: false, title: '', content: '', button: ['Close'] };

export const SaveElements = ({
  item,
  permission = true,
  uniqueField = null,
  queries = defaultQueries,
  defaultMsg = defaultMsgValues,
  onSaveHandler = noop,
}) => {
  const query = Object.keys(queries.list)[0];
  const mutateCreate = Object.keys(queries.create)[0];
  const mutateUpdate = Object.keys(queries.update)[0];
  const listItems = gql(queries.list[query]);
  const createItem = gql(queries.create[mutateCreate]);
  const updateItem = gql(queries.update[mutateUpdate]);
  const [onCreateItem, { loading: loadingOnCreateItem }] = useMutation(createItem, { onCompleted, onError });
  const [onUpdateItem, { loading: loadingOnUpdateItem }] = useMutation(updateItem, { onCompleted, onError });
  const onQueryList = useImperativeQuery(listItems, { onError, fetchPolicy: 'network-only' });

  useEffect(() => {
    if (loadingOnCreateItem || loadingOnUpdateItem) {
      showMessage('Loading', <LoadingSpinner />);
    }
  }, [loadingOnCreateItem, loadingOnUpdateItem]);

  const [msg, setMsg] = useState(defaultMsg);
  const closeMessage = () => setMsg({ visible: false, title: '', content: '' });
  const showMessage = (title, content, button = ['Close'], actions = []) =>
    setMsg({
      visible: true,
      title,
      content,
      button,
      actions,
    });

  const confirmSave = async (e, defaultShowMessage = null, defaultOnSave = null) => {
    defaultShowMessage ||= showMessage;
    defaultOnSave ||= onSave;

    try {
      let data = { ...item };
      const uniqueValue = data[uniqueField]?.trim();

      if (uniqueValue === '') {
        throw new Error('Name is required.');
      }

      data = onSaveHandler(data);
      const response = await onQueryList({ filter: { [uniqueField]: { eq: uniqueValue } } });
      const result = _.get(response, `data.${query}.items`, []);

      if (result.length) {
        defaultShowMessage(
          <>
            <b>{uniqueValue}</b> Exists
          </>,
          'Overwrite the previously stored values?',
          ['Yes', 'No'],
          [() => defaultOnSave({ ...result[0], ...data })]
        );
      } else {
        defaultOnSave(data);
      }
    } catch (err) {
      onError(err);
    }
  };

  const onSave = async (data) => {
    // Remove unnecessary props
    // updatedAt and createdAt are from dynamodb
    // actions is for display
    data = _.omit(data, 'updatedAt', 'createdAt', 'actions');

    if (data.id) {
      onUpdateItem({ variables: { input: data } });
    } else {
      onCreateItem({ variables: { input: data } });
    }
  };

  return {
    msg,
    permission,
    confirmSave,
    closeMessage,
  };

  function onCompleted() {
    const name = item[uniqueField];
    showMessage('Success', `${name} is successfully saved`);
  }

  function onError(error) {
    const message =
      error.message || 'An error occured! Please try again or contact the support if the problem persists.';

    setMsg({
      visible: true,
      title: 'Unable to Save',
      content: <div>{message}</div>,
      button: ['Close'],
      class: 'danger',
    });
  }
};

const Save = (props) => {
  const { msg, permission, confirmSave, closeMessage } = SaveElements(props);

  return (
    <>
      {permission ? (
        <button data-test="save-component" type="button" onClick={confirmSave} className="btn-action">
          <SaveSVG />
          <MessageModal
            title={msg.title}
            modal={msg.visible}
            setModal={closeMessage}
            buttons={msg.button}
            actions={msg.actions}
          >
            <div className="text-center">{msg.content}</div>
          </MessageModal>
        </button>
      ) : (
        <Media
          data-test="save-component-placeholder"
          className="btn-action"
          style={{ width: '28px', height: '28px', margin: '0 auto' }}
          object
          src={PlaceholderImg}
          alt="You do not have permission."
        />
      )}
    </>
  );
};

export default Save;
