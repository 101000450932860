import React from 'react';
import { format } from 'd3-format';
import ChartJSGraph, { UNITS, makeScale, defaultConfig, mapDataset } from './ChartJSGraph';

const COLORMAP = {
  ST: 'blue',
  PT: 'green',
  EB: 'red',
};

const StressStrainGraph = ({ graphKey, graph, height, width, interactive = false }) => {
  const config = defaultConfig('line', height, width);
  config.data = {
    datasets: graph.datasets
      .map((e, i) => mapDataset(e, i))
      .map((ds) => ({
        ...ds,
        backgroundColor: COLORMAP[ds.label],
        borderColor: COLORMAP[ds.label],
        color: COLORMAP[ds.label],
      })),
    labels: graph.labels,
  };

  if (interactive) {
    config.options = {
      ...config.options,
      legend: { display: true },
      animation: false,
      hover: { animationDuration: 0 },
      responsiveAnimationDuration: 0,
      scales: {
        xAxes: [
          {
            stacked: true,
            ticks: {
              callback: (value) => format('.2')(value),
            },
            scaleLabel: {
              display: true,
              labelString: 'Engineering Strain',
            },
          },
        ],
        yAxes: [
          {
            ...makeScale(graph.yFmt),
            scaleLabel: {
              display: true,
              labelString: `Engineering Stress ( ${UNITS[graph.measurementUnits].pressure} )`,
            },
          },
        ],
      },
    };
  } else {
    config.options = {
      ...config.options,
      legend: { display: false },
      events: [],
      scales: {
        xAxes: [makeScale('', interactive)],
        yAxes: [makeScale('', interactive)],
      },
    };
  }

  return <ChartJSGraph options={config} canvasKey={graphKey} data-test="stress-strain-graph-component" />;
};

export default StressStrainGraph;
